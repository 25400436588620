/*Google Fonts*/
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,500;0,700;1,600&display=swap');

:root {
  --thm-font: "Heebo", sans-serif; /* Headings */
  --thm-b-font: "Rubik", sans-serif; /* Body font */
  --thm-base: #00ACB1; /* Primary color */
  --thm-base-hover: #00787b; /* Primary hover */
  --thm-base-rgb: 0, 172, 177; /* Primary rgb */
  --thm-base-hue: #d0feff; /* Primary low hue */
  --thm-secondary: #005963; /* Secondary */
  --thm-secondary-hover: #004148; /* Secondary hover */
  --thm-secondary-rgb: 0, 89, 99; /* Secondary rgb */
  --thm-b-text: #686a6f; /* Body text */
  --thm-b-text-rgb: 104, 106, 111; /* Body text rgb */
  --thm-border: #EBF2F3; /* Borders */
  --thm-border-rgb: 225, 225, 225; /* Borders rgb */
}

/* animation */
@keyframes pulseInOut {
  0% {
    opacity: 1;
    transform: scale(.3)
  }

  100% {
    opacity: 0;
    transform: scale(1.7)
  }
}

@keyframes preloader-dual-inner-o {
  0% {
    opacity: 1;
    transform: translate(0 0)
  }

  49.99% {
    opacity: 1;
    transform: translate(80px, 0)
  }

  50% {
    opacity: 0;
    transform: translate(80px, 0)
  }

  100% {
    opacity: 0;
    transform: translate(0, 0)
  }
}

@keyframes preloader-dual-inner {
  0% {
    transform: translate(0, 0)
  }

  50% {
    transform: translate(80px, 0)
  }

  100% {
    transform: translate(0, 0)
  }
}

@-webkit-keyframes cssload-loading-ani1 {
  100% {
    -webkit-transform: translate(39px);
    transform: translate(39px);
    opacity: 0;
  }
}

@keyframes cssload-loading-ani1 {
  100% {
    -webkit-transform: translate(39px);
    transform: translate(39px);
    opacity: 0;
  }
}

@-webkit-keyframes cssload-loading-ani2 {
  100% {
    -webkit-transform: translate(19px);
    transform: translate(19px);
    opacity: 1;
  }
}

@keyframes cssload-loading-ani2 {
  100% {
    -webkit-transform: translate(19px);
    transform: translate(19px);
    opacity: 1;
  }
}

@-webkit-keyframes cssload-loading-ani3 {
  100% {
    -webkit-transform: translate(19px);
    transform: translate(19px);
  }
}

@keyframes cssload-loading-ani3 {
  100% {
    -webkit-transform: translate(19px);
    transform: translate(19px);
  }
}

@keyframes preloader-squares-inner {
  0% {
    transform: scale(1.1500000000000001)
  }

  100% {
    transform: scale(1)
  }
}

@keyframes preloader-pulse-inner-1 {
  0% {
    top: 36px;
    height: 128px
  }

  50% {
    top: 60px;
    height: 80px
  }

  100% {
    top: 60px;
    height: 80px
  }
}

@keyframes preloader-pulse-inner-2 {
  0% {
    top: 41.99999999999999px;
    height: 116.00000000000001px
  }

  50% {
    top: 60px;
    height: 80px
  }

  100% {
    top: 60px;
    height: 80px
  }
}

@keyframes preloader-pulse-inner-3 {
  0% {
    top: 48px;
    height: 104px
  }

  50% {
    top: 60px;
    height: 80px
  }

  100% {
    top: 60px;
    height: 80px
  }
}

@keyframes preloader-gear-inner {
  0% {
    transform: rotate(0deg)
  }

  50% {
    transform: rotate(22.5deg)
  }

  100% {
    transform: rotate(45deg)
  }
}

@keyframes preloader-ripple-inner {
  0% {
    top: 96px;
    left: 96px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 18px;
    left: 18px;
    width: 156px;
    height: 156px;
    opacity: 0;
  }
}

@keyframes preloader-eclipse {
  0% {
    transform: rotate(0deg)
  }

  50% {
    transform: rotate(180deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

@keyframes preloader-spinner-inner {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

/* General */

::selection{
  background-color: var(--thm-base);
  color: #fff;
}

html {
  overflow-x: hidden;
}

body {
  font-family: var(--thm-b-font);
  overflow-x: hidden;
  color: var(--thm-b-text);
  font-size: 16px;
  line-height: 1.8;
}

.fw-400{
  font-weight: 400;
}

.sigma_box {
  padding: 30px;
  background-color: #fff;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
}

.gallery-thumb{
  position: relative;
}

.gallery-thumb::before{
  content: "\f002";
  position: absolute;
  right: 20px;
  top: 30px;
  font-family: "Font Awesome 5 Pro";
  color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: .3s;
}
.gallery-thumb:hover::before{
  top: 20px;
  opacity: 1;
  visibility: visible;
}

.object-cover {
  object-fit: cover;
}

.section {
  position: relative;
  padding: 100px 0;
}

.section.section-padding {
  padding: 100px 0 70px;
}

.section.shape-after::after {
  content: '';
  position: absolute;
  background-color: var(--thm-base);
  opacity: .3;
  left: 20px;
  right: 20px;
  bottom: -20px;
  top: 20px;
  z-index: -2;
}

.section-title {
  position: relative;
  max-width: 640px;
}

.section-title.centered {
  text-align: center;
  margin: 0 auto 50px;
}

.section-title.flex-title{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 50px;
  max-width: 100%;
}
.section-title .title {
  font-weight: 700;
  font-size: 60px;
  line-height: 1;
  position: relative;
}

.section-title .subtitle {
  display: inline-flex;
  color: var(--thm-base);
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 500;
}

.section-title .btn-link {
  margin-top: 20px;
}

.required {
  font-size: 12px;
  line-height: 1;
  color: #f78686;
  font-style: normal;
}

.tr{
  position: absolute;
  top: 0px;
  right: -100px;
}
.bl{
  position: absolute;
  bottom: 0;
  left: 0;
}
.br{
  position: absolute;
  bottom: 0;
  right: 0;
}

img {
  max-width: 100%;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

label {
  margin-bottom: 10px;
  color: var(--thm-b-text);
  font-weight: 500;
}

b, strong {
  font-weight: 500;
}

.sigma_stroke-text {
  font-size: 240px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
  color: transparent;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #fff;
  z-index: 1;
  opacity: 0.2;
  margin: 0;
}

.center-absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.topleft-absolute {
  position: absolute;
  left: -30px;
  top: -30px;
}

.secondary-overlay,
.dark-overlay,
.primary-overlay {
  position: relative;
  z-index: 1;
}

.secondary-overlay::before,
.primary-overlay::before,
.dark-overlay::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .6);
  z-index: -1;
}

.primary-overlay::before {
  background-color: rgba( var(--thm-base-rgb) , .6);
}
.secondary-overlay::before {
  background-color: rgba( var(--thm-secondary-rgb) , .6);
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.mb-30 {
  margin-bottom: 30px;
}

/* Buttons */
button,
input[type=reset],
input[type="button"],
input[type="submit"],
.sigma_btn {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  position: relative;
  background-color: var(--thm-base);
  border: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 17px 30px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  text-transform: uppercase;
  border-radius: 0;
  overflow: hidden;
  z-index: 1;
  box-shadow: 0 3px 24px rgba(0, 0, 0, .1);
}

button i,
input[type=reset] i,
input[type="button"] i,
input[type="submit"] i,
.sigma_btn i {
  margin: 0 0 0 10px;
  color: #fff;
  z-index: 1;
}

.btn-rounded button,
.btn-rounded input[type=reset],
.btn-rounded input[type="button"],
.btn-rounded input[type="submit"],
.btn-rounded .sigma_btn,
.rounded-border input[type="number"],
.rounded-border input[type="date"],
.rounded-border input[type="text"],
.rounded-border input[type="email"],
.rounded-border input[type="password"],
.rounded-border input[type="tel"],
.rounded-border input[type="url"],
.rounded-border input[type="search"],
.rounded-border select,
.rounded-border textarea,
.rounded-border .form-control {
  border-radius: 6px;
}

.btn-pill button,
.btn-pill input[type=reset],
.btn-pill input[type="button"],
.btn-pill input[type="submit"],
.btn-pill .sigma_btn,
.pill-border input[type="number"],
.pill-border input[type="text"],
.pill-border input[type="date"],
.pill-border input[type="email"],
.pill-border input[type="password"],
.pill-border input[type="tel"],
.pill-border input[type="url"],
.pill-border input[type="search"],
.pill-border select,
.pill-border textarea,
.pill-border .form-control {
  border-radius: 25px;
}

.bottom-border input[type="number"],
.bottom-border input[type="text"],
.bottom-border input[type="date"],
.bottom-border input[type="email"],
.bottom-border input[type="password"],
.bottom-border input[type="tel"],
.bottom-border input[type="url"],
.bottom-border input[type="search"],
.bottom-border select,
.bottom-border textarea,
.bottom-border .form-control {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid var(--thm-border);
  padding: 8px 0;
}

button:hover,
input[type=reset]:hover,
input[type="button"]:hover,
input[type="submit"]:hover,
.sigma_btn:hover,
button:focus,
input[type=reset]:focus,
input[type="button"]:focus,
input[type="submit"]:focus,
.sigma_btn:focus {
  color: #fff;
  box-shadow: 0 3px 24px rgba(0, 0, 0, .1);
  outline: none;
}

button.secondary,
input[type=reset].secondary,
input[type="button"].secondary,
input[type="submit"].secondary,
.sigma_btn.secondary {
  background-color: var(--thm-secondary);
}

button.secondary:hover,
input[type=reset].secondary:hover,
input[type="button"].secondary:hover,
input[type="submit"].secondary:hover,
.sigma_btn.secondary:hover,
button.secondary:focus,
input[type=reset].secondary:focus,
input[type="button"].secondary:focus,
input[type="submit"].secondary:focus,
.sigma_btn.secondary:focus {
  color: #fff;
}

button.tertiary,
input[type=reset].tertiary,
input[type="button"].tertiary,
input[type="submit"].tertiary,
.sigma_btn.tertiary {
  background-color: #7553f2;
}

button.tertiary:hover,
input[type=reset].tertiary:hover,
input[type="button"].tertiary:hover,
input[type="submit"].tertiary:hover,
.sigma_btn.tertiary:hover,
button.tertiary:focus,
input[type=reset].tertiary:focus,
input[type="button"].tertiary:focus,
input[type="submit"].tertiary:focus,
.sigma_btn.tertiary:focus {
  color: #fff;
}

button.light,
input[type=reset].light,
input[type="button"].light,
input[type="submit"].light,
.sigma_btn.light {
  background-color: #fff;
  color: var(--thm-secondary);
}

button.light.outline,
input[type=reset].light.outline,
input[type="button"].light.outline,
input[type="submit"].light.outline,
.sigma_btn.light.outline{
  border: 2px solid rgba(255,255,255,.4);
  background-color: transparent;
  color: #fff;
}
button.light.outline i,
input[type=reset].light.outline i,
input[type="button"].light.outline i,
input[type="submit"].light.outline i,
.sigma_btn.light.outline i{
  color: #fff;
}

button.light.outline:hover,
input[type=reset].light.outline:hover,
input[type="button"].light.outline:hover,
input[type="submit"].light.outline:hover,
.sigma_btn.light.outline:hover{
  background-color: #fff;
}
button.light.outline:hover i,
input[type=reset].light.outline:hover i,
input[type="button"].light.outline:hover i,
input[type="submit"].light.outline:hover i,
.sigma_btn.light.outline:hover i{
  color: var(--thm-base-hover);
}

button.light.outline::before,
input[type=reset].light.outline::before,
input[type="button"].light.outline::before,
input[type="submit"].light.outline::before,
.sigma_btn.light.outline::before{
  background-color: #fff;
}

button.light i,
input[type=reset].light i,
input[type="button"].light i,
input[type="submit"].light i,
.sigma_btn.light i {
  color: var(--thm-secondary);
}

button.light:hover,
input[type=reset].light:hover,
input[type="button"].light:hover,
input[type="submit"].light:hover,
.sigma_btn.light:hover,
button.light:focus,
input[type=reset].light:focus,
input[type="button"].light:focus,
input[type="submit"].light:focus,
.sigma_btn.light:focus {
  color: var(--thm-secondary);
}

/* btn-hover-styles */
/* Style 1 */
.btn-style-1 button:before,
.btn-style-1 input[type=reset]:before,
.btn-style-1 input[type="button"]:before,
.btn-style-1 input[type="submit"]:before,
.btn-style-1 .sigma_btn:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: calc(100% - 10px);
  border-radius: 0;
  background-color: var(--thm-base-hover);
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.3s;
}

/* Style 2 */
.btn-style-2 button:before,
.btn-style-2 input[type=reset]:before,
.btn-style-2 input[type="button"]:before,
.btn-style-2 input[type="submit"]:before,
.btn-style-2 .sigma_btn:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
  border-radius: 0;
  background-color: var(--thm-base-hover);
  z-index: -1;
  transition: 0.3s;
}

/* Style 3 */
.btn-style-3 button:before,
.btn-style-3 input[type=reset]:before,
.btn-style-3 input[type="button"]:before,
.btn-style-3 input[type="submit"]:before,
.btn-style-3 .sigma_btn:before {
  content: "";
  position: absolute;
  height: 100%;
  left: -35%;
  top: 0;
  transform: skew(50deg);
  width: 0;
  border-radius: 0;
  background-color: var(--thm-base-hover);
  z-index: -1;
  transition: 0.3s;
}

/* Style 4 */
.btn-style-4 button:before,
.btn-style-4 input[type=reset]:before,
.btn-style-4 input[type="button"]:before,
.btn-style-4 input[type="submit"]:before,
.btn-style-4 .sigma_btn:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 0;
  border-radius: 0;
  background-color: var(--thm-base-hover);
  z-index: -1;
  transition: 0.3s;
}

/* Style 5 */
.btn-style-5 button:before,
.btn-style-5 input[type=reset]:before,
.btn-style-5 input[type="button"]:before,
.btn-style-5 input[type="submit"]:before,
.btn-style-5 .sigma_btn:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  height: 0;
  width: 0;
  border-radius: 0;
  background-color: var(--thm-base-hover);
  z-index: -1;
  transition: 0.3s;
}

/* Style 6 */
.btn-style-6 button:before,
.btn-style-6 input[type=reset]:before,
.btn-style-6 input[type="button"]:before,
.btn-style-6 input[type="submit"]:before,
.btn-style-6 .sigma_btn:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  right: -120px;
  bottom: -50px;
  border-radius: 0;
  background-color: var(--thm-base-hover);
  z-index: -1;
  transition: 0.3s;
}

/* Style 7 */
.btn-style-7 button:before,
.btn-style-7 input[type=reset]:before,
.btn-style-7 input[type="button"]:before,
.btn-style-7 input[type="submit"]:before,
.btn-style-7 .sigma_btn:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0;
  background-color: var(--thm-base-hover);
  transform: scaleY(0);
  transform-origin: 50%;
  transition: 0.3s;
}

/* Style 8 */
.btn-style-8 button:before,
.btn-style-8 input[type=reset]:before,
.btn-style-8 input[type="button"]:before,
.btn-style-8 input[type="submit"]:before,
.btn-style-8 .sigma_btn:before {
  content: "";
  position: absolute;
  left: 110%;
  top: 0px;
  width: 100%;
  height: 100%;
  transform: skewX(-25deg);
  transition: 0.3s;
  z-index: -1;
  border-radius: 0;
  background-color: var(--thm-base-hover);
}

/* Style 9 */
.btn-style-9 button:before,
.btn-style-9 input[type=reset]:before,
.btn-style-9 input[type="button"]:before,
.btn-style-9 input[type="submit"]:before,
.btn-style-9 .sigma_btn:before {
  content: "";
  position: absolute;
  left: 90%;
  top: 0px;
  width: 100%;
  height: 100%;
  transform: skewX(-25deg);
  transition: 0.3s;
  z-index: -1;
  border-radius: 0;
  background-color: var(--thm-base-hover);
}

/* for secondary btn */
button.secondary:before,
input[type=reset].secondary:before,
input[type="button"].secondary:before,
input[type="submit"].secondary:before,
.sigma_btn.secondary:before {
  background-color: var(--thm-secondary-hover);
}

/* for tertiary btn */
button.tertiary:before,
input[type=reset].tertiary:before,
input[type="button"].tertiary:before,
input[type="submit"].tertiary:before,
.sigma_btn.tertiary:before,
button.tertiary:before {
  background-color: #4224b1;
}

/* for light btn */
button.light:before,
input[type=reset].light:before,
input[type="button"].light:before,
input[type="submit"].light:before,
.sigma_btn.light:before {
  background-color: rgba(0, 0, 0, 0.1);
}

/* for buttons have rounded borders */
.btn-rounded button:before,
.btn-rounded input[type=reset]:before,
.btn-rounded input[type="button"]:before,
.btn-rounded input[type="submit"]:before,
.btn-rounded .sigma_btn:before {
  border-radius: 6px;
}

/* for pill button */
.btn-pill button:before,
.btn-pill input[type=reset]:before,
.btn-pill input[type="button"]:before,
.btn-pill input[type="submit"]:before,
.btn-pill .sigma_btn:before {
  border-radius: 25px;
}

.btn-style-1 button:hover:before,
.btn-style-1 input[type=reset]:hover:before,
.btn-style-1 input[type="button"]:hover:before,
.btn-style-1 input[type="submit"]:hover:before,
.btn-style-1 .sigma_btn:hover:before {
  opacity: 1;
  visibility: visible;
  width: calc(100% - 10px);
}

.btn-style-2 button:hover:before,
.btn-style-2 input[type=reset]:hover:before,
.btn-style-2 input[type="button"]:hover:before,
.btn-style-2 input[type="submit"]:hover:before,
.btn-style-2 .sigma_btn:hover:before {
  height: 100%;
}

.btn-style-3 button:hover:before,
.btn-style-3 input[type=reset]:hover:before,
.btn-style-3 input[type="button"]:hover:before,
.btn-style-3 input[type="submit"]:hover:before,
.btn-style-3 .sigma_btn:hover:before {
  width: 160%;
  height: 100%;
}

.btn-style-4 button:hover:before,
.btn-style-4 input[type=reset]:hover:before,
.btn-style-4 input[type="button"]:hover:before,
.btn-style-4 input[type="submit"]:hover:before,
.btn-style-4 .sigma_btn:hover:before {
  width: 100%;
}

.btn-style-5 button:hover:before,
.btn-style-5 input[type=reset]:hover:before,
.btn-style-5 input[type="button"]:hover:before,
.btn-style-5 input[type="submit"]:hover:before,
.btn-style-5 .sigma_btn:hover:before {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.btn-style-6 button:hover:before,
.btn-style-6 input[type=reset]:hover:before,
.btn-style-6 input[type="button"]:hover:before,
.btn-style-6 input[type="submit"]:hover:before,
.btn-style-6 .sigma_btn:hover:before {
  right: 0;
  bottom: 0;
}

.btn-style-7 button:hover:before,
.btn-style-7 input[type=reset]:hover:before,
.btn-style-7 input[type="button"]:hover:before,
.btn-style-7 input[type="submit"]:hover:before,
.btn-style-7 .sigma_btn:hover:before {
  transform: scaleY(1);
}

.btn-style-8 button:hover:before,
.btn-style-8 input[type=reset]:hover:before,
.btn-style-8 input[type="button"]:hover:before,
.btn-style-8 input[type="submit"]:hover:before,
.btn-style-8 .sigma_btn:hover:before {
  left: 0%;
  transform: scale(1, 1);
}

.btn-style-9 button:hover:before,
.btn-style-9 input[type=reset]:hover:before,
.btn-style-9 input[type="button"]:hover:before,
.btn-style-9 input[type="submit"]:hover:before,
.btn-style-9 .sigma_btn:hover:before {
  left: 0%;
  transform: scale(1, 1);
}

.btn-style-1 button.slick-next:hover:before,
.btn-style-1 button.slick-prev:hover:before,
.btn-style-2 button.slick-next:hover:before,
.btn-style-2 button.slick-prev:hover:before,
.btn-style-3 button.slick-next:hover:before,
.btn-style-3 button.slick-prev:hover:before,
.btn-style-4 button.slick-next:hover:before,
.btn-style-4 button.slick-prev:hover:before,
.btn-style-5 button.slick-next:hover:before,
.btn-style-5 button.slick-prev:hover:before{
  top: 50%;
  left: 50%;
  height: auto;
  width: auto;
}

button.btn-sm,
input[type=reset].btn-sm,
input[type="button"].btn-sm,
input[type="submit"].btn-sm,
.sigma_btn.btn-sm {
  font-size: 14px;
  padding: 12px 20px;
  text-transform: capitalize;
}

.btn-link {
  transition: 0.3s;
  display: inline-flex;
  align-items: center;
  line-height: 1;
}

.btn-link:hover {
  text-decoration: none;
  color: var(--thm-base-hover);
}

.btn-link i {
  transition: 0.3s;
  margin-left: 10px;
  font-size: 13px;
}

.btn-link:hover i {
  margin-left: 13px;
}

/* Inputs */
input[type="number"],
input[type="text"],
input[type="date"],
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="url"],
input[type="search"],
select,
textarea,
.form-control {
  position: relative;
  display: block;
  height: 60px;
  width: 100%;
  font-size: 16px;
  color: #686a6f;
  font-weight: 400;
  padding: 14px 30px;
  letter-spacing: 0.1em;
  background-color: #fff;
  border: 1px solid var(--thm-border);
  border-radius: 0;
  transition: all 300ms ease;
  font-family: var(--thm-b-font);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='grey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 17px;
}

textarea {
  padding: 15px;
}

.form-group {
  position: relative;
  margin-bottom: 20px;
}

.form-group>i {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  z-index: 1;
}

i.show-password {
  cursor: pointer;
}

textarea {
  height: auto;
  resize: none;
  line-height: 1;
}

input[type="number"]:focus,
input[type="text"]:focus,
input[type="date"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
select:focus,
textarea:focus,
.form-control:focus {
  box-shadow: none;
  outline: none;
  border-color: var(--thm-base);
}

input[type="number"]::placeholder,
input[type="text"]::placeholder,
input[type="date"]::placeholder,
input[type="email"]::placeholder,
input[type="password"]::placeholder,
input[type="tel"]::placeholder,
input[type="url"]::placeholder,
input[type="search"]::placeholder,
select::placeholder,
textarea::placeholder {
  color: #a5a5a5;
}

/*Checkboxes and radios*/
input[type="checkbox"],
input[type="radio"] {
  opacity: 0;
  position: absolute;
}

input[type="checkbox"]+label,
input[type="radio"]+label {
  display: block;
  cursor: pointer;
  position: relative;
  padding-left: 25px;
  font-weight: 400;
}

input[type="checkbox"]+label:before,
input[type="radio"]+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  border: 1px solid var(--thm-border);
  border-radius: 3px;
  display: inline-block;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  height: 16px;
  outline: 0;
  -webkit-transition: background-color .2s ease-out;
  -o-transition: background-color .2s ease-out;
  transition: background-color .2s ease-out;
  width: 16px;
}

input[type="radio"]+label:before {
  border-radius: 50%;
}

input[type="checkbox"]+label:after,
input[type="radio"]+label:after {
  position: absolute;
  top: 50%;
  left: 3px;
  width: 10px;
  height: 5px;
  content: " ";
  transform: translateY(-50%) rotate(-45deg);
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  opacity: 0;
  transition: 0.3s;
}

input[type="radio"]+label:after {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 0;
  background-color: var(--thm-base);
}

input[type=checkbox]:checked+label:before,
input[type=checkbox]:checked+label.switch-toggle:before {
  background-color: var(--thm-base);
  border-color: var(--thm-base);
}

input[type=checkbox]:checked+label:after,
input[type=radio]:checked+label:after {
  opacity: 1;
}

.custom-control{
  padding-left: 0;
}
.custom-radio .custom-control-input:checked~.custom-control-label::before,
.custom-checkbox .custom-control-input:checked~.custom-control-label::before{
  background-color: var(--thm-base);
}
.custom-control-input:focus~.custom-control-label::before {
  -webkit-box-shadow: none;
  box-shadow: none;
}

/*Checkbox With Toggle Swich*/
input[type="checkbox"]+label.switch-toggle {
  padding-left: 70px;
}

input[type="checkbox"]+label.switch-toggle:before {
  border-radius: 16px;
  height: 30px;
  width: 60px;
  background-color: var(--thm-border);
}

input[type="checkbox"]+label.switch-toggle:after {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 0;
  background-color: #fff;
  opacity: 1;
}

input[type=checkbox]:checked+label.switch-toggle:after {
  left: 36px;
}

/* Input Groups */
.input-group input[type="number"],
.input-group input[type="text"],
.input-group input[type="date"],
.input-group input[type="email"],
.input-group input[type="password"],
.input-group input[type="tel"],
.input-group input[type="url"],
.input-group input[type="search"],
.input-group select,
.input-group textarea,
.input-group .form-control {
  flex: 1 auto;
  width: 1%;
}

.input-group-prepend,
.input-group-append {
  margin: 0;
}

.input-group-append button i{
  margin: 0;
}

.input-group-prepend button,
.input-group-prepend input[type=reset],
.input-group-prepend input[type="button"],
.input-group-prepend input[type="submit"],
.input-group-prepend .sigma_btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: none;
}

.input-group input[type="number"]:last-child,
.input-group input[type="text"]:last-child,
.input-group input[type="date"]:last-child,
.input-group input[type="email"]:last-child,
.input-group input[type="password"]:last-child,
.input-group input[type="tel"]:last-child,
.input-group input[type="url"]:last-child,
.input-group input[type="search"]:last-child,
.input-group select:last-child,
.input-group textarea:last-child,
.input-group .form-control:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group input[type="number"]:not(:last-child),
.input-group input[type="text"]:not(:last-child),
.input-group input[type="date"]:not(:last-child),
.input-group input[type="email"]:not(:last-child),
.input-group input[type="password"]:not(:last-child),
.input-group input[type="tel"]:not(:last-child),
.input-group input[type="url"]:not(:last-child),
.input-group input[type="search"]:not(:last-child),
.input-group select:not(:last-child),
.input-group textarea:not(:last-child),
.input-group .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-append button,
.input-group-append input[type=reset],
.input-group-append input[type="button"],
.input-group-append input[type="submit"],
.input-group-append .sigma_btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: none;
}

/* Colors &  background-colors */
.primary-color {
  color: var(--thm-base);
}

.secondary-color {
  color: var(--thm-secondary);
}

.tertiary-color {
  color: #7553f2;
}

.light-bg {
  background-color: #FCF7FF;
}

.bg-white {
  background-color: #fff;
}

.bg-primary-1 {
  background-color: var(--thm-base) !important;
}

.bg-secondary-1 {
  background-color: var(--thm-secondary) !important;
}

.bg-tertiary {
  background-color: #7553f2;
}

.bg-primary-light {
  background-color: var(--thm-base-hue) !important;
}

.bg-secondary-light {
  background-color: #c8c8c8;
}

.bg-tertiary-light {
  background-color: #d4c8ff;
}

.bg-gray {
  background-color: #FCF7FF;
}

.twitter {
  background-color: rgb(44, 170, 225) !important;
  color: #fff;
}

.twitter:before {
  background-color: #2488b3 !important;
}

.facebook {
  background-color: rgb(59, 87, 157) !important;
  color: #fff;
}

.facebook:before {
  background-color: #273a6b !important;
}

.google {
  background-color: rgb(220, 74, 56) !important;
  color: #fff;
}

.google:before {
  background-color: #af1705 !important;
}

.youtube {
  background-color: rgb(229, 45, 39) !important;
  color: #fff;
}

.youtube:before {
  background-color: #b7140f !important;
}

/* Slick */
.slick-slide {
  position: relative;
  outline: none;
}

.slick-prev,
.slick-next {
  position: relative;
  font-size: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
  color: var(--thm-secondary);
  border: 0;
  padding: 0;
  outline: none;
  transition: 0.3s;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background-color: var(--thm-base);
  color: #fff;
}

.slick-prev:before,
.slick-next:before {
  content: "\f053";
  font-family: "Font Awesome 5 Pro";
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
}

[class*="btn-style-"] .slick-prev::after,
[class*="btn-style-"] .slick-next::after {
  display: none;
}

[class*="btn-style-"] .slick-prev::before,
[class*="btn-style-"] .slick-next::before {
  content: "\f053";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  opacity: 1;
  visibility: visible;
  background-color: transparent;
}

[class*="btn-style-"] .slick-next::before,
.slick-next:before {
  content: "\f054";
}

.slick-dots li:focus,
.slick-dots li:hover,
.slick-dots li button:focus,
.slick-dots li button:hover {
  outline: none;
}

.slick-dots li+li {
  margin-left: 10px;
}

.slick-dots li button {
  font-size: 0;
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.3s;
  border: 0;
  padding: 0;
}

[class*="btn-style-"] .slick-dots li button::after,
[class*="btn-style-"] .slick-dots li button::before {
  display: none;
}

.slick-dots .slick-active button {
  background-color: var(--thm-base);
}

/*--- custom arrows ---- */

.sigma_custom-arrows{
  display: flex;
  align-items: center;
}

.sigma_custom-arrows .slick-prev,
.sigma_custom-arrows .slick-next{
  background-color: var(--thm-base);
  opacity: 1;
  visibility: visible;
  cursor: pointer;
}

.sigma_custom-arrows .slick-prev:hover,
.sigma_custom-arrows .slick-next:hover{
  background-color: var(--thm-base-hover);
}

.sigma_custom-arrows .slick-next{
  margin-left: 12px;
}

/* Typography */
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a,
h1, h2, h3, h4, h5, h6 {
  font-family: var(--thm-font);
  font-weight: 600;
  color: var(--thm-secondary);
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 20px;
}

h1 {
  font-size: 52px;
}

h2 {
  font-size: 44px;
}

h3 {
  font-size: 36px;
  line-height: 1.2;
}

h4 {
  font-size: 28px;
  line-height: 1.4;
}

h5 {
  font-size: 20px;
  line-height: 1.3;
}

h6 {
  font-size: 18px;
  line-height: 1.7;
}

p {
  margin-bottom: 20px;
}

a {
  color: var(--thm-base);
  transition: .3s;
  display: inline-block;
}

a:hover,
a:focus {
  outline: none;
  box-shadow: none;
  text-decoration: none;
}

h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover,
a:hover {
  color: var(--thm-base-hover);
  text-decoration: none;
}

/*======================
Tables
========================*/
table{
  margin-bottom: 30px;
}
table th,
table td {
    padding: 15px;
    vertical-align: middle;
    background-color: #fff;
}

table th {
    font-weight: 500;
    color: var(--thm-secondary);
    font-size: 16px;
    border: 1px solid var(--thm-border);
}

table {
    width: 100%;
    margin-bottom: 30px;
}

table td {
    border: 1px solid var(--thm-border);
}

table img {
    width: 40px;
    border-radius: 0;
}

.sigma_close {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s;
}

.sigma_close:hover span {
  opacity: 1;
}

.sigma_close span {
  position: absolute;
  width: 24px;
  height: 2px;
  opacity: .5;
  transition: .3s;
  background-color: var(--thm-secondary);
}

.sigma_close.light span {
  background-color: #fff;
}

.sigma_close span:first-child {
  transform: rotate(45deg);
}

.sigma_close:hover span:first-child {
  transform: rotate(0deg);
}

.sigma_close:hover span:last-child {
  transform: rotate(180deg);
}

.sigma_close span:last-child {
  transform: rotate(135deg);
}

/* Header */
.sigma_header {
  position: relative;
  background-color: #fff;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
}

.navbar {
  padding: 0;
}

.sigma_header .sigma_logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}
.sigma_header .sigma_logo-wrapper .sigma_logo{
  max-width: 130px;
}

.sigma_header .navbar-nav {
  flex-direction: row;
}

.sigma_header .navbar-nav li {
  position: relative;
  transition: 0.3s;
}

.sigma_header .navbar-nav li a {
  display: block;
  padding: 20px;
  color: #fff;
  font-weight: 500;
  font-family: var(--thm-font);
  position: relative;
  font-size: 16px;
}

.sigma_header .navbar-nav li:first-child>a {
  padding-left: 0;
}

.sigma_header .navbar-nav li:last-child>a {
  padding-right: 0;
}

.sigma_header .navbar-nav li a:hover,
.sigma_header .navbar-nav li.active>a {
  color: var(--thm-base);
}

.sigma_header .navbar-nav li .sub-menu {
  position: absolute;
  top: 110%;
  min-width: 250px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
  transition: 0.3s;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
}

.sigma_header .navbar-nav li .sub-menu li a {
  border-bottom: 1px solid var(--thm-border);
  padding: 15px;
  display: flex;
  font-weight: 400;
  align-items: center;
  color: var(--thm-b-text);
  position: relative;
}

.sigma_header .navbar-nav li .sub-menu li:last-child a {
  border-bottom: none;
}

.sigma_header .navbar-nav li.menu-item-has-children:hover>.sub-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.sigma_header .navbar-nav li.menu-item-has-children .sub-menu li .sub-menu {
  left: 100%;
  top: 0;
}

.sigma_header-absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 55;
}

/* Header top */
.sigma_header-top {
  background-color: #fff;
  border-bottom: 1px solid var(--thm-border);
  padding: 0;
}

.sigma_header-top-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.sigma_header-top-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin: 0;
}

.sigma_header-top .sigma_header-top-nav li {
  margin: 0;
}

.sigma_header-top .sigma_header-top-nav li a {
  color: #fff;
  font-weight: 600;
  font-size: 12px;
}

.sigma_header-top .sigma_header-top-nav li+li {
  margin-left: 10px;
}

.sigma_header-top-contacts .sigma_header-top-nav li a {
  display: block;
  font-size: 14px;
  width: 41px;
  height: 41px;
  background-color: rgba(var(--thm-base-rgb), .25);
  display: inline-flex;
  color: var(--thm-base);
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 12px;
  margin-right: 0;
}

.sigma_header.style-1 .sigma_header-top-contacts .sigma_header-top-nav li a{
   color: var(--thm-base);
}
.sigma_header-top-contacts .sigma_header-top-nav li a:hover,
.sigma_header.style-1 .sigma_header-top-contacts .sigma_header-top-nav li a:hover {
  background-color: var(--thm-base);
  color: #fff;
}

.sigma_header-top .sigma_header-top-cta li {
  height: 100%;
}

.sigma_header-top .sigma_header-top-cta li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px;
  background-color: var(--thm-base);
  color: #fff;
  border-radius: 4px;
  font-weight: 500;
}
.sigma_header-top .sigma_header-top-cta li a:hover{
  background-color: var(--thm-base-hover);
}

/* Header bottom */
.sigma_header-bottom{
  background-color: var(--thm-secondary);
}
.sigma_header-bottom-inner{
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.sigma_header .sigma_header-bottom .navbar-nav > li > a{
  color: #fff;
  padding-top: 15px;
  padding-bottom: 15px;
}
.sigma_header .sigma_header-bottom .navbar-nav li a:hover{
  color: var(--thm-base);
}

.sigma_header .sigma_header-bottom-inner .aside-toggle.desktop-toggler{
  display: flex;
  height: 62px;
  width: 62px;
  align-items: center;
  justify-content: center;
  background-color: var(--thm-base);
  transition: .3s;
  border-radius: 0;
  border: 0;
}
.sigma_header .sigma_header-bottom-inner .aside-toggle.desktop-toggler span{
  background-color: #fff;
}

/* Header Controls starts */
.sigma_header .sigma_header-controls ul {
  display: flex;
  align-items: center;
}

.sigma_header .sigma_header-controls ul li+li {
  margin-left: 15px;
}

.sigma_header .sigma_header-controls ul li.header-controls-item a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 58px;
  height: 58px;
  border: 1px solid var(--thm-border);
  color: #fff;
  font-size: 18px;
  border-radius: 10px;
}

.sigma_header .sigma_header-controls ul li.header-controls-item a.sigma_header-control-cart {
  width: auto;
  height: auto;
  padding: 13px 15px;
  line-height: 1;
  display: flex;
  align-items: center;
}

.sigma_header .sigma_header-controls ul li.header-controls-item a.sigma_header-control-cart .sigma_header-control-cart-inner {
  margin-left: 10px;
}

.sigma_header .sigma_header-controls ul li.header-controls-item a.sigma_header-control-cart p,
.sigma_header .sigma_header-controls ul li.header-controls-item a.sigma_header-control-cart span {
  font-size: 13px;
}

.sigma_header .sigma_header-controls ul li.header-controls-item a.sigma_header-control-cart span {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.sigma_header .sigma_header-controls ul li.header-controls-item a.sigma_header-control-cart p {
  margin: 0;
  font-weight: 600;
  color: var(--thm-base);
}

.sigma_header .sigma_header-controls ul li.header-controls-item a:hover {
  background-color: #FCF7FF;
}

.sigma_header .aside-toggle,
.sigma_header .aside-toggle.desktop-toggler {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  width: 58px;
  height: 58px;
  border: 1px solid var(--thm-border);
  border-radius: 10px;
  transition: 0.3s;
}

.sigma_header .aside-toggle:hover {
  background-color: #FCF7FF;
}
.sigma_header .aside-toggle:hover span{
  margin: 0;
}
.sigma_header .aside-toggle:hover span:nth-child(1){
  width: 10px;
  transform: rotate(45deg) translate(7px,-3px);
}
.sigma_header .aside-toggle:hover span:nth-child(3) {
  width: 10px;
  transform: rotate(-45deg) translate(7px,3px);
}

.sigma_header .aside-toggle {
  display: none;
}

.sigma_header .aside-toggle span {
  display: block;
  width: 20px;
  height: 2px;
  border-radius: 3px;
  background-color: var(--thm-secondary);
  transition: .3s;
  transform-origin: right;
}

.sigma_header .aside-toggle span+span {
  margin-top: 5px;
}
.sigma_header .sigma_header-controls.style-2 ul li.header-controls-item a.sigma_header-control-cart{
  width: 58px;
  position: relative;
  height: 58px;
  padding: 0;
}
.sigma_header .sigma_header-controls.style-2 ul li.header-controls-item a.sigma_header-control-cart span{
  position: absolute;
  top: -5px;
  right: -5px;
  width: 25px;
  height: 25px;
  font-weight: 600;
  font-size: 12px;
  background-color: var(--thm-base);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.sigma_header .sigma_header-controls.style-2 .aside-toggle,
.sigma_header .sigma_header-controls.style-2 ul li.header-controls-item a{
  border-radius: 50%;
}

.sigma_header .container-fluid{
  padding-left: 65px;
  padding-right: 65px;
}

/* Style 2 */
.sigma_header.style-2 .sigma_header-top-contacts .sigma_header-top-nav li a{
  background-color: transparent;
  border: 1px solid var(--thm-border);
  color: var(--thm-secondary);
}
.sigma_header.style-2 .sigma_header-top-contacts .sigma_header-top-nav li a:hover{
  background-color: var(--thm-base);
  border-color: var(--thm-base);
  color: #fff;
}

/* Style 3 */
.sigma_header.style-3{
  background-color: transparent;
  box-shadow: none;
}


.sigma_header-top .sigma_header-top-contacts ul li a{
  color: var(--thm-secondary);
}

.sigma_header-top .sigma_header-top-contacts ul li a:hover{
  color: var(--thm-base-hover);
}
.sigma_header-top.dark-bg{
  background-color: var(--thm-secondary);
}

.sigma_header-top.dark-bg .sigma_header-top-links li a,
.sigma_header-top.dark-bg .sigma_header-top-links li select,
.sigma_header-top.dark-bg .sigma_header-top-contacts ul li a{
  color: #fff;
  opacity: .9;
}

body .sigma_header.style-5 .sigma_header-top-contacts .sigma_header-top-nav li a:hover,
.sigma_header-top.dark-bg .sigma_header-top-links li a:hover,
.sigma_header-top.dark-bg .sigma_header-top-contacts ul li a:hover{
  color: #fff;
  opacity: 1;
}

.sigma_header.style-3 .sigma_header-top,
.sigma_header.style-5 .sigma_header-top{
  border: 0;
  padding: 10px 20px;
}
.sigma_header.style-3 .sigma_header-middle,
.sigma_header.style-5 .sigma_header-middle{
  padding: 0 20px;
  background-color: #fff;
  box-shadow: 0 0 40px 5px rgba(0,0,0,.03);
}
.sigma_header.style-3 .sigma_header-top-inner,
.sigma_header.style-5 .sigma_header-top-inner,
.sigma_header.style-7 .sigma_header-top-inner,
.sigma_header.style-8 .sigma_header-top-inner{
  padding: 0;
}

.sigma_header.style-3 .sigma_header-controls.style-2 ul li.header-controls-item a.sigma_header-control-cart,
.sigma_header.style-3 .aside-toggle,
.sigma_header.style-3 .aside-toggle.desktop-toggler,
.sigma_header.style-3 .sigma_header-controls ul li.header-controls-item a,

.sigma_header.style-5 .sigma_header-controls.style-2 ul li.header-controls-item a.sigma_header-control-cart,
.sigma_header.style-5 .aside-toggle,
.sigma_header.style-5 .aside-toggle.desktop-toggler,
.sigma_header.style-5 .sigma_header-controls ul li.header-controls-item a{
  width: 50px;
  height: 50px;
}

.sigma_header.style-3 .sigma_header-top .sigma_header-top-cta li a,
.sigma_header.style-3 .sigma_header-top-contacts .sigma_header-top-nav li a{
  background-color: rgba(255,255,255,.1);
  color: #fff;
}
.sigma_header.style-3 .sigma_header-top .sigma_header-top-cta li a:hover,
.sigma_header.style-3 .sigma_header-top-contacts .sigma_header-top-nav li a:hover,
.sigma_header.style-5 .sigma_header-top .sigma_header-top-cta li a:hover,
.sigma_header.style-5 .sigma_header-top-contacts .sigma_header-top-nav li a:hover{
  background-color: var(--thm-base);
}

/* Style 4 */
.sigma_header.style-4 .sigma_header-bottom-inner{
  justify-content: center;
}

/* Style 5 */

.sigma_header.style-5 .sigma_header-top,
.sigma_header.style-5 .sigma_header-middle,
.sigma_header.style-6 .sigma_header-top,
.sigma_header.style-6 .sigma_header-middle{
  padding-left: 0;
  padding-right: 0;
}

.sigma_header.style-5 .sigma_header-top-contacts .sigma_header-top-nav li a,
.sigma_header.style-6 .sigma_header-top-contacts .sigma_header-top-nav li a,
.sigma_header.style-7 .sigma_header-top-contacts .sigma_header-top-nav li a,
.sigma_header.style-8 .sigma_header-top-contacts .sigma_header-top-nav li a{
  background-color: transparent;
  width: auto;
  height: auto;
  font-size: 16px;
  font-weight: 400;
}
.sigma_header.style-5 .sigma_header-top-contacts .sigma_header-top-nav li a:hover,
.sigma_header.style-6 .sigma_header-top-contacts .sigma_header-top-nav li a:hover,
.sigma_header.style-7 .sigma_header-top-contacts .sigma_header-top-nav li a:hover,
.sigma_header.style-8 .sigma_header-top-contacts .sigma_header-top-nav li a:hover{
  background-color: transparent;
  color: var(--thm-base-hover);
}
.sigma_header.style-5 .sigma_header-top .sigma_header-top-nav li+li,
.sigma_header.style-7 .sigma_header-top .sigma_header-top-nav li+li,
.sigma_header.style-8 .sigma_header-top .sigma_header-top-nav li+li{
  margin-left: 24px;
}
.sigma_header .sigma_header-top-links li a,
.sigma_header .sigma_header-top-links li select{
  display: flex;
  align-items: center;
  color: var(--thm-secondary);
  font-size: 14px;
  font-weight: 400;
}

.sigma_header .sigma_header-top-links li select{
  background-color: transparent;
  height: auto;
  padding: 0;
  border: 0;
  background-image: none;
  -webkit-appearance: auto;
  cursor: pointer;
}

.sigma_header .sigma_header-top-links li a:hover,
.sigma_header .sigma_header-top-links.important-links li a:hover,
.sigma_header .sigma_header-top-links li select:hover{
  color: var(--thm-base-hover);
}

.sigma_header .sigma_header-top-links li a i{
  margin-right: 10px;
}

.sigma_header .sigma_header-top-links li + li{
  margin-left: 15px;
}

.sigma_header .sigma_header-top-links.important-links li a{
  font-size: 16px;
  padding: 30px 0;
  color: var(--thm-secondary);
}

.sigma_header .sigma_header-top-links.important-links li a i{
  width: 40px;
  height: 40px;
  border: 1px solid var(--thm-border);
  border-radius: 50%;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--thm-base);
}

.sigma_header .sigma_header-top-links.important-links li p{
  margin: 0;
  line-height: 1.3;
}

.sigma_header .sigma_header-top-links.important-links li + li{
  margin-left: 40px;
}

.sigma_header.style-5 .sigma_header-bottom,
.sigma_header.style-8 .sigma_header-bottom{
  background-color: #fff;
}

.sigma_header.style-5 .sigma_header-bottom .navbar{
  border-top: 1px solid var(--thm-border);
}

.sigma_header.style-5 .sigma_header-bottom .navbar-nav > li > a,
.sigma_header.style-8 .sigma_header-bottom .navbar-nav > li > a{
  color: var(--thm-secondary);
  padding-top: 20px;
  padding-bottom: 20px;
}

.sigma_header.style-5 .sigma_header-bottom .navbar-nav > li > a:hover{
  color: var(--thm-base);
}

.sigma_header.style-5 .sigma_header-controls ul li.header-controls-item a,
.sigma_header.style-5 .aside-toggle,
.sigma_header.style-5 .aside-toggle.desktop-toggler{
  background-color: #FCF7FF;
  border-color: #FCF7FF;
}

.sigma_header.style-5 .sigma_header-controls ul li.header-controls-item a:hover,
.sigma_header.style-5 .aside-toggle:hover,
.sigma_header.style-5 .aside-toggle.desktop-toggler:hover{
  background-color: var(--thm-base);
  border-color: var(--thm-base);
}

/* Style 6 */

.sigma_header.style-6 .sigma_header-top-links.important-links li a{
  padding: 0;
  margin: 30px 0;
}

.sigma_header.style-6 .sigma_header-top-links.important-links li + li{
  margin-left: 30px;
}

.sigma_header.style-6 .sigma_header-top-links.important-links li + li a{
  border-left: 2px solid var(--thm-border);
  padding-left: 30px;
}

.sigma_header.style-6 .sigma_header-bottom .navbar-nav > li > a{
  padding-top: 12px;
  padding-bottom: 12px;
}

.sigma_header.style-6 .sigma_header-controls ul li.header-controls-item a{
  border: 0;
  color: #fff;
}

.sigma_header.style-6 .aside-toggle.desktop-toggler{
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-radius: 0;
}

.sigma_header.style-6 .aside-toggle.desktop-toggler span{
  background-color: #fff;
}

.sigma_header.style-6 .sigma_header-controls ul li.header-controls-item a:hover,
.sigma_header.style-6 .aside-toggle.desktop-toggler:hover{
  background-color: transparent;
}

.sigma_header.style-6 .sigma_header-controls ul li.header-controls-item,
.sigma_header.style-6 .aside-toggle.desktop-toggler{
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}

.sigma_header.style-6 .sigma_header-controls ul li+li{
  margin-left: 0;
}

.sigma_header.style-6 .sigma_header-bottom .sigma_btn,
.sigma_header.style-6 .sigma_header-bottom .sigma_btn:before{
  border-radius: 0;
}

.sigma_header.style-6 .sigma_header-top-links.important-links li a i{
  border: 0;
  background-color: rgba(var(--thm-secondary-rgb), 0.1);
  color: var(--thm-secondary);
}


/* Style 7 */

.sigma_header.style-7 .sigma_header-top{
  background-color: var(--thm-base);
}

.sigma_header.style-7 .sigma_header-top-links li a,
.sigma_header.style-7 .sigma_header-top-links li select,
.sigma_header.style-7 .sigma_header-top-contacts .sigma_header-top-nav li a{
  color: #fff;
}

.sigma_header.style-7 .sigma_header-top-links li a:hover,
.sigma_header.style-7 .sigma_header-top-links li select:hover{
  color: var(--thm-base-hover);
}

.sigma_header.style-7 .sigma_header-top-inner .sigma_btn,
.sigma_header.style-8 .sigma_header-top-inner .sigma_btn{
  border-radius: 0;
  padding: 12px 30px;
  box-shadow: none;
}

.sigma_header.style-7 .sigma_header-top-inner .sigma_btn:before,
.sigma_header.style-8 .sigma_header-top-inner .sigma_btn:before{
  border-radius: 0;
}

.sigma_header.style-7 .navbar-nav > li.menu-item-has-children > a:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f078";
  font-size: 12px;
  margin-left: 10px;
  display: inline-block;
  transition: 0.3s;
  opacity: 0.5;
}
.sigma_header.style-7 .navbar-nav li .sub-menu li.menu-item-has-children > a:after {
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f054";
  right: 18px;
  font-size: 14px;
  opacity: 0.5;
}

.sigma_header.style-7 .sigma-call h5{
  color: var(--thm-base);
}

.sigma_header.style-7 .navbar-nav{
  margin-left: auto;
  margin-right: 80px;
}

/* Style 8 */

.sigma_header.style-8 .sigma_header-top{
  background-color: var(--thm-secondary);
  padding: 0;
  border: 0;
}

.sigma_header.style-8 .sigma_header-middle{
  border-bottom: 1px solid var(--thm-border);
}

.sigma_header.style-8 .sigma_logo-wrapper{
  padding: 20px 65px 20px 0;
  border-right: 1px solid var(--thm-border);
}

.sigma_header.style-8 .sigma_header-top-links.important-links{
  padding-left: 65px;
  border-left: 1px solid var(--thm-border);
}

.sigma_header.style-8 .sigma_header-top-links.important-links li:first-child a i{
  border: 0;
  background-color: rgba(var(--thm-base-rgb), 0.2);
}
.sigma_header.style-8 .sigma_header-top-links.important-links li:last-child a i{
  border: 0;
  background-color: rgba(var(--thm-secondary-rgb), 0.2);
  color: var(--thm-secondary);
}

.sigma_header.style-8 .sigma_header-bottom .navbar-nav > li > a,
.sigma_header.style-8 .sigma_header-bottom .navbar-nav li .sub-menu li a{
  color: var(--thm-b-text);
}
.sigma_header.style-8 .sigma_header-bottom .navbar-nav > li > a:hover,
.sigma_header.style-8 .sigma_header-bottom .navbar-nav li .sub-menu li a:hover,
.sigma_header.style-8 .sigma_header-bottom .navbar-nav > li.active > a{
  color: var(--thm-secondary);
}

.sigma_header.style-8 .sigma_header-controls ul li.header-controls-item a,
.sigma_header.style-8 .aside-toggle, .sigma_header .aside-toggle.desktop-toggler{
  border: 0;
  color: var(--thm-b-text);
}

.sigma_header.style-8 .aside-toggle span{
  background-color: var(--thm-b-text);
}

.sigma_header.style-8 .sigma_header-controls .form-group button{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  background-color: transparent;
  padding: 0;
  box-shadow: none;
}
.sigma_header.style-8 .sigma_header-controls .form-group button i{
  color: var(--thm-b-text);
  font-size: 18px;
}
.sigma_header.style-8 .sigma_header-controls .form-group button:before{
  content: none;
}
.sigma_header.style-8 .sigma_header-controls .form-group input{
  background-color: transparent;
  border: 0;
  padding-left: 38px;
}

/* style 8 has dark bg */

.sigma_header.style-8.has-dark-bg{
  background-color: transparent;
  box-shadow: none;
}

.sigma_header.style-8.has-dark-bg .sigma_header-middle,
.sigma_header.style-8.has-dark-bg .sigma_header-bottom{
  padding: 0 30px;
}

.sigma_header.style-8.has-dark-bg .sigma_header-middle .sigma_logo-wrapper{
  padding: 10px 0;
  border-right: 0;
}

.sigma_header.style-8.has-dark-bg .sigma_header-top-links.important-links{
  padding-left: 0;
  border-left: 0;
}
.sigma_header.style-8.has-dark-bg .sigma_header-middle{
  border-bottom: 0;
  background-color: var(--thm-secondary);
}
.sigma_header.style-8.has-dark-bg .sigma_header-top-links.important-links li:first-child a i,
.sigma_header.style-8.has-dark-bg .sigma_header-top-links.important-links li:last-child a i{
  border: 0;
  background-color: transparent;
  color: var(--thm-base);
}

.sigma_header.style-8.has-dark-bg .links-inner p{
  color: #fff;
}
.sigma_header.style-8.has-dark-bg .links-inner p:first-child{
  color: rgba(255, 255, 255, 0.6);
}
.sigma_header.style-8.has-dark-bg .sigma_header-middle .aside-toggle span{
  background-color: #fff;
}

.sigma_header.style-8.has-dark-bg .sigma_header-middle .aside-toggle:hover{
  background-color: transparent;
}


/* Search Form */
.search-form-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, .8);
  padding: 20px;
  opacity: 0;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s;
}

.search-form-wrapper.open {
  opacity: 1;
  visibility: visible;
}

.search-form-wrapper .sigma_close {
  position: absolute;
  top: 60px;
  right: 60px;
  border-color: #fff;
}

.search-form-wrapper .sigma_close span {
  background-color: #fff;
}

.search-form-wrapper form {
  position: relative;
  width: 70%;
  transition-duration: .3s;
  transition-delay: .3s;
  transform: scale(.5);
  opacity: 0;
  visibility: hidden;
}

.search-form-wrapper.open form {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.search-form-wrapper form input {
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid #fff;
  font-size: 40px;
  height: 80px;
  width: 100%;
  outline: none;
  color: #fff;
  font-weight: 400;
}

.search-form-wrapper form input::placeholder {
  color: #fff;
}

.search-form-wrapper .search-btn {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  background-color: transparent;
  border: 0;
  color: #fff;
  font-size: 30px;
  outline: none;
  transition: .3s;
  cursor: pointer;
  padding: 0;
}

.search-form-wrapper .search-btn:hover i {
  color: var(--thm-base);
}

/*Aside*/
.sigma_aside .sigma_close {
  position: absolute;
  right: 20px;
  top: 20px;
}

.sigma_aside {
  position: fixed;
  top: 0;
  left: -300px;
  width: 300px;
  height: 100%;
  z-index: 999;
  background-color: #fff;
  transition: .3s;
  overflow-y: auto;
}

.sigma_aside-overlay.aside-trigger,
.sigma_aside:not(.sigma_aside-desktop) {
  display: none;
}

.sigma_aside .sigma_logo-wrapper {
  padding: 30px 15px;
}

.sigma_aside .navbar-nav li a {
  display: flex;
  align-items: center;
  padding: 12px 15px;
  color: var(--thm-secondary);
  font-weight: 600;
  border-bottom: 1px solid var(--thm-border);
}

.sigma_aside .navbar-nav li a:hover{
  color: var(--thm-base);
}

.sigma_aside .navbar-nav li.menu-item-has-children>a:after {
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f107";
  right: 18px;
  display: flex;
  align-items: center;
  font-size: 18px;
}

.sigma_aside .navbar-nav li .sub-menu {
  display: none;
}

.sigma_aside .navbar-nav li .sub-menu {
  background-color: #FCF7FF;
}

.sigma_aside-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  transition: .3s;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  background-color: rgba(0, 0, 0, .4);
}

.aside-open .sigma_aside {
  left: 0;
}

.aside-open .sigma_aside:not(.sigma_aside-desktop)+.sigma_aside-overlay,
.aside-right-open .sigma_aside-desktop+.sigma_aside-overlay {
  opacity: 1;
  visibility: visible;
}

.sigma_aside.sigma_aside-desktop {
  right: -420px;
  width: 420px;
  left: auto;
}
.sigma_aside.sigma_aside-desktop .sidebar{
  padding: 30px;
}

.aside-right-open .sigma_aside.sigma_aside-desktop {
  right: 0;
}

/*-------------- Subheader --------------------------- */
.sigma_header-absolute .sigma_subheader{
  padding: 240px 0 180px;
}
.sigma_subheader {
  padding: 200px 0;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.sigma_subheader .br{
  z-index: 0;
  right: -150px;
}
.sigma_subheader .bl{
  left: -200px;
}
.sigma_subheader .bl,
.sigma_subheader .tr{
  z-index: -1;
}

.sigma_subheader .container {
  position: relative;
}

.sigma_subheader .sigma_subheader-inner {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  z-index: 2;
  position: relative;
}

.sigma_subheader .sigma_subheader-inner h1 {
  font-size: 72px;
  margin-bottom: 0;
}

.sigma_subheader .sigma_subheader-inner p {
  max-width: 550px;
  color: #999b9f;
}

.sigma_subheader .breadcrumb {
  background-color: transparent;
  justify-content: center;
  padding: 0;
  margin-bottom: 0;
  z-index: 2;
  position: relative;
}

.sigma_subheader .breadcrumb li {
  font-size: 16px;
  padding: 0;
}

.sigma_subheader .breadcrumb li a {
  color: var(--thm-base);
  font-weight: 600;
  display: inline-block;
}

.sigma_subheader .breadcrumb li a:hover {
  text-decoration: none;
  color: var(--thm-base-hover);
}

.sigma_subheader .breadcrumb .breadcrumb-item+.breadcrumb-item::before {
  padding: 0 12px;
}

/* Style 2 */
.sigma_subheader.style-3 .sigma_subheader-inner,
.sigma_subheader.style-2 .sigma_subheader-inner,
.sigma_subheader.style-11 .sigma_subheader-inner {
  text-align: left;
  margin: 0;
}

.sigma_subheader.style-2 .breadcrumb,
.sigma_subheader.style-11 .breadcrumb {
  justify-content: flex-start;
}

/* Style 3 */
.sigma_subheader.style-3 {
  padding: 0;
}

.sigma_subheader.style-3 .sigma_subheader-inner {
  padding: 140px 0;
}

.sigma_subheader.style-3 .breadcrumb {
  position: absolute;
  bottom: 0;
  right: 15px;
  background-color: #fff;
  border-radius: 0;
  padding: 20px;
}

.sigma_subheader.style-3 .breadcrumb li,
.sigma_subheader.style-3 .breadcrumb .breadcrumb-item+.breadcrumb-item::before {
  color: var(--thm-b-text);
}

/* Style 4 */
.sigma_subheader.style-4 .sigma_subheader-shape {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 185px;
  background-color: transparent;
}

.sigma_subheader.style-4 .sigma_subheader-shape svg {
  width: calc(125% + 1.3px);
  height: 185px;
  display: block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.sigma_subheader.style-4 .sigma_subheader-shape svg path {
  fill: #ffffff;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

/* Style 5 */

/* Style 6 */
.sigma_subheader.style-6 {
  padding: 60px 0;
}

.sigma_subheader.style-6 .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sigma_subheader.style-6 .sigma_subheader-inner {
  margin: 0;
  text-align: left;
}

.sigma_subheader.style-6 .sigma_subheader-inner h1 {
  font-size: 38px;
}

/* Style 7 */
.sigma_subheader.style-9,
.sigma_subheader.style-8,
.sigma_subheader.style-7 {
  padding: 280px 0 40px;
}

.sigma_subheader.style-9 .sigma_subheader-inner,
.sigma_subheader.style-8 .sigma_subheader-inner,
.sigma_subheader.style-7 .sigma_subheader-inner {
  margin: 0;
  text-align: left;
}

.sigma_subheader.style-9 .breadcrumb,
.sigma_subheader.style-8 .breadcrumb,
.sigma_subheader.style-7 .breadcrumb {
  justify-content: flex-start;
}

.sigma_subheader.style-9::before,
.sigma_subheader.style-8::before,
.sigma_subheader.style-7::before {
  background-image: linear-gradient(to top, rgba(0, 0, 0, .4), transparent);
}

.sigma_subheader.style-8 .sigma_stroke-text {
  text-align: left;
  opacity: 1;
  font-style: italic;
}

.sigma_subheader.style-9 {
  border-bottom: 2px solid var(--thm-border);
}

.sigma_subheader.style-9 .sigma_subheader-inner h1 {
  color: var(--thm-secondary);
}

/* Style 10 */
.sigma_subheader.style-10 {
  padding: 40px 0;
}

.sigma_subheader.style-10 .sigma_subheader-inner {
  text-align: left;
  margin: 0;
}

.sigma_subheader.style-10 .sigma_subheader-inner h1 {
  color: var(--thm-secondary);
  display: inline-block;
  position: relative;
}

.sigma_subheader.style-10 .sigma_subheader-inner h1::after,
.sigma_subheader.style-10 .sigma_subheader-inner h1::before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--thm-secondary);
  width: 100vw;
  height: 20px;
}

.sigma_subheader.style-10 .sigma_subheader-inner h1::after {
  margin-left: 20px;
}

.sigma_subheader.style-10 .sigma_subheader-inner h1::before {
  right: 100%;
  margin-right: 20px;
}

/* Style 11 */

.sigma_subheader.style-11 .breadcrumb .breadcrumb-item+.breadcrumb-item::before{
  content: "|";
}



/*-------------- Video Icon & Ratings General style --------------------------- */
.sigma_video-btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #fff;
  color: var(--thm-base);
  font-size: 20px;
  transition: 0.3s;
}

.sigma_video-btn:hover {
  background-color: var(--thm-base);
  color: #fff;
}

.sigma_video-btn:before {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  border: 2px solid #fff;
  animation-name: pulseInOut;
  opacity: 0;
  border-radius: 50%;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

.sigma_video-btn:after {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  border: 2px solid #fff;
  animation-name: pulseInOut;
  opacity: 0;
  border-radius: 50%;
  animation-duration: 3.5s;
  animation-iteration-count: infinite;
}

.sigma_rating-wrapper{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.sigma_rating-wrapper span{
  margin-left: 10px;
  font-size: 13px;
}

.sigma_rating {
  display: flex;
  align-items: center;
  margin: 20px 0;
  font-size: 14px;
}

.sigma_rating i {
  display: inline-block;
  color: #fcb71b;
}

.sigma_rating i+i {
  padding-left: 4px;
}

/*-------------- Info Box --------------------------- */
/* Info Box style 1 */
.sigma_info {
  position: relative;
  border-radius: 6px;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
  padding: 40px;
  margin-bottom: 30px;
  transition: 0.3s;
}

.sigma_info .sigma_info-title {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  position: relative;
}

.sigma_info .sigma_info-title .sigma_info-icon,
.sigma_info.style-22 .sigma_info-description .sigma_info-icon{
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-radius: 50%;
  margin-right: 20px;
}

.sigma_info .sigma_info-title .sigma_info-icon i {
  font-size: 32px;
}

.sigma_info .sigma_info-title h5 {
  margin: 0;
  flex: 1;
}

.sigma_info .sigma_info-description p {
  margin-bottom: 0;
}

/* Info Box style 2, 3 , 4 & 7 */
.sigma_info.style-2 {
  padding: 30px;
  background-color: #fff;
}

.sigma_info.style-2 .sigma_info-title,
.sigma_info.style-3 .sigma_info-title,
.sigma_info.style-4 .sigma_info-title,
.sigma_info.style-6 .sigma_info-title,
.sigma_info.style-7 .sigma_info-title,
.sigma_info.style-8 .sigma_info-title,
.sigma_info.style-11 .sigma_info-title,
.sigma_info.style-19 .sigma_info-title,
.sigma_info.style-12 .sigma_info-title,
.sigma_info.style-13 .sigma_info-title,
.sigma_info.style-16 .sigma_info-title,
.sigma_info.style-24 .sigma_info-title {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 0;
  max-width: 100%;
}

.sigma_info.style-2 .sigma_info-title .sigma_info-icon {
  border-radius: 8px
}

.sigma_info.style-2 .sigma_info-title .sigma_info-icon,
.sigma_info.style-3 .sigma_info-title .sigma_info-icon,
.sigma_info.style-4 .sigma_info-title .sigma_info-icon,
.sigma_info.style-7 .sigma_info-title .sigma_info-icon,
.sigma_info.style-11 .sigma_info-title .sigma_info-icon,
.sigma_info.style-19 .sigma_info-title .sigma_info-icon,
.sigma_info.style-12 .sigma_info-title .sigma_info-icon,
.sigma_info.style-13 .sigma_info-title .sigma_info-icon,
.sigma_info.style-16 .sigma_info-title .sigma_info-icon,
.sigma_info.style-24 .sigma_info-title .sigma_info-icon {
  margin-right: 0;
}

.sigma_info.style-2 .sigma_info-title h5,
.sigma_info.style-7 .sigma_info-title h5,
.sigma_info.style-11 .sigma_info-title h5,
.sigma_info.style-19 .sigma_info-title h5,
.sigma_info.style-12 .sigma_info-title h5,
.sigma_info.style-13 .sigma_info-title h5 {
  margin: 25px 0 14px;
}

.sigma_info.style-2 .sigma_btn,
.sigma_info.style-4 .sigma_btn,
.sigma_info.style-11 .sigma_btn,
.sigma_info.style-13 .sigma_btn,
.sigma_info.style-2 .btn-link,
.sigma_info.style-4 .btn-link,
.sigma_info.style-11 .btn-link,
.sigma_info.style-13 .btn-link {
  margin-top: 15px;
}

/* Info Box style 3 & 6 */
.sigma_info.style-3,
.sigma_info.style-5,
.sigma_info.style-6,
.sigma_info.style-8,
.sigma_info.style-16,
.sigma_info.style-21 {
  box-shadow: none;
  padding: 0;
  text-align: center;
}

.sigma_info.style-3 .sigma_info-title,
.sigma_info.style-6 .sigma_info-title,
.sigma_info.style-8 .sigma_info-title,
.sigma_info.style-16 .sigma_info-title {
  align-items: center;
}

.sigma_info.style-3 .sigma_info-title .sigma_info-icon,
.sigma_info.style-6 .sigma_info-title .sigma_info-icon,
.sigma_info.style-8 .sigma_info-title .sigma_info-icon,
.sigma_info.style-16 .sigma_info-title .sigma_info-icon {
  border-radius: 6px;
  background-color: var(--thm-base);
  margin-bottom: 30px;
  margin-right: 0;
  color: #fff;
}

.sigma_info.style-3 .sigma_info-title h5,
.sigma_info.style-16 .sigma_info-title h5 {
  margin: 0 0 12px;
}

.sigma_info.style-3 a {
  width: 50px;
  height: 50px;
  background-color: #FCF7FF;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--thm-text);
  border-radius: 50%;
  margin: 30px auto 0;
}

.sigma_info.style-3 a:hover {
  background-color: var(--thm-base);
  color: #fff;
}

/* Info Box style 4 */
.sigma_info.style-4 {
  text-align: center;
  margin-top: 40px;
  background-color: #fff;
  padding: 10px 10px 0;
}

.sigma_info.style-4 .sigma_info-title {
  height: 200px;
  border-radius: 6px;
  margin: -50px auto 0;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}

.sigma_info.style-4 .sigma_info-title .shape-1 {
  position: absolute;
  left: -10px;
  top: 20px;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  transform: rotate(45deg);
  transition: 1s;
}

.sigma_info.style-4 .sigma_info-title .shape-2 {
  position: absolute;
  right: -16px;
  bottom: 36px;
  width: 50px;
  height: 50px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  transform: rotate(135deg);
  transition: 1s;
}

.sigma_info.style-4 .sigma_info-title:hover .shape-1 {
  top: 40px;
}

.sigma_info.style-4 .sigma_info-title:hover .shape-2 {
  bottom: 46px;
}

.sigma_info.style-4 .sigma_info-title .shape-1.round,
.sigma_info.style-4 .sigma_info-title .shape-2.round {
  border-radius: 50%;
}

.sigma_info.style-4 .sigma_info-title .sigma_info-icon {
  font-size: 38px;
  margin-right: 0;
}

.sigma_info.style-4 .sigma_info-description {
  padding: 40px 20px;
}

.sigma_info.style-4 .sigma_info-description h5,
.sigma_info.style-5 .sigma_info-description h5 {
  margin-bottom: 15px;
}

.sigma_info.style-4 .sigma_info-description p {
  margin-bottom: 0;
}

.sigma_info.style-4 .btn-link {
  justify-content: center;
  margin-top: 15px;
  font-weight: 400;
}

/* Info Box style 5 */
.sigma_info.style-5,
.sigma_info.style-21 {
  background-color: #FCF7FF;
  border-radius: 0;
}

.sigma_info.style-5 .sigma_info-thumb,
.sigma_info.style-21 .sigma_info-thumb,
.sigma_info.style-22 .sigma_info-thumb {
  overflow: hidden;
  position: relative;
  display: block;
}

.sigma_info.style-5 .sigma_info-thumb img,
.sigma_info.style-21 .sigma_info-thumb img,
.sigma_info.style-22 .sigma_info-thumb img {
  transition: 0.3s;
  width: 100%;
}

.sigma_info.style-5 .sigma_info-thumb:hover img,
.sigma_info.style-21 .sigma_info-thumb:hover img,
.sigma_info.style-22 .sigma_info-thumb:hover img {
  transform: scale(1.1);
}

.sigma_info.style-5 .sigma_info-description {
  padding: 30px;
}

/* Info Box style 6 */
.sigma_info.style-6 {
  background-color: transparent;
  padding: 20px 20px 0;
}

.sigma_info.style-6 .sigma_info-title .sigma_info-icon,
.sigma_info.style-8 .sigma_info-title .sigma_info-icon {
  width: 120px;
  height: 120px;
  background-color: #fff;
  border-radius: 50%;
  color: var(--thm-base);
  border: 3px solid var(--thm-base);
  font-size: 30px;
  z-index: 1;
}

.sigma_info.style-6 .sigma_info-title span.text,
.sigma_info.style-8 .sigma_info-title span.text {
  position: absolute;
  display: block;
  top: -52px;
  right: -12px;
  font-size: 120px;
  color: rgb(51 51 51 / 19%);
}

.sigma_info.style-6 .sigma_info-title h5 {
  margin-bottom: 22px;
}

/* Info Box style 7 */
.sigma_info.style-7 {
  padding: 40px 30px;
  overflow: hidden;
}

.sigma_info.style-7 .sigma_info-title .sigma_info-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.sigma_info.style-7 .shape-1,
.sigma_info.style-7 .shape-2 {
  position: absolute;
  right: -70px;
  top: -70px;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  transform: none;
  background-color: rgb(255 255 255 / 13%);
  transition: .3s;
}

.sigma_info.style-7 .shape-2,
.sigma_info.style-7.bg-white .shape-2 {
  right: -22px;
  top: auto;
  bottom: 10px;
}

.sigma_info.style-7.bg-white .shape-1,
.sigma_info.style-7.bg-white .shape-2 {
  background-color: rgb(0 0 0 / 13%);
}

.sigma_info.style-7:hover .shape-1 {
  top: -40px;
}

.sigma_info.style-7:hover .shape-2 {
  bottom: 20px;
}

/* Info Box style 8 */
.sigma_info.style-8 {
  background-color: #fff;
  padding: 50px;
}

.sigma_info.style-8 .sigma_info-title .sigma_info-icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  color: var(--thm-secondary);
  border: 2px dashed var(--thm-border);
  font-size: 20px;
  position: relative;
  margin-bottom: 40px;
}

.sigma_info.style-8 .sigma_info-title h5 {
  line-height: 1.6;
}

.sigma_info.style-8 .sigma_info-title .sigma_info-icon span.text {
  position: absolute;
  top: -6px;
  left: -15px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  background-color: var(--thm-base);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 8px rgba(var(--thm-base-rgb), .5);
}

/*-------------- Features --------------------------- */

/* Info Box style 9 */
.sigma_info.style-9 {
  background-color: #fff;
  box-shadow: none;
  border: 1px solid var(--thm-border);
  border-radius: 25px;
  transition: .3s;
  padding: 30px;
}

.sigma_info.style-9 * {
  transition: .3s;
}

.sigma_info.style-9 .sigma_info-title {
  display: flex;
  align-items: center;
}

.sigma_info.style-9 .sigma_info-title .sigma_info-icon {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: var(--thm-base);
  box-shadow: 0px 0 40px 5px rgba(0, 0, 0, .03);
  border-radius: 50%;
  margin-right: 20px;
}

.sigma_info.style-9 .sigma_info-title .sigma_info-icon i {
  font-size: 16px;
}

.sigma_info.style-9 .sigma_info-title p {
  margin: 0;
}

.sigma_info.style-9 .sigma_info-description {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sigma_info.style-9 .sigma_info-description i {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--thm-secondary);
  color: #fff;
  border-radius: 50%;
  margin: 0;
}

.sigma_info.style-9:hover {
  background-color: var(--thm-secondary);
}

.sigma_info.style-9:hover * {
  color: #fff;
}

.sigma_info.style-9:hover .sigma_info-title .sigma_info-icon {
  background-color: var(--thm-base);
}

.sigma_info.style-9:hover .sigma_info-description i {
  background-color: var(--thm-base);
}

/* info box style 10 */
.sigma_info.style-10,
.sigma_info.style-15,
.sigma_info.style-17,
.sigma_info.style-18,
.sigma_info.style-20,
.sigma_info.style-26 {
  box-shadow: none;
  padding: 0;
  display: flex;
}

.sigma_info.style-10 .sigma_info-title,
.sigma_info.style-15 .sigma_info-title,
.sigma_info.style-17 .sigma_info-title,
.sigma_info.style-18 .sigma_info-title,
.sigma_info.style-20 .sigma_info-title,
.sigma_info.style-26 .sigma_info-title {
  align-items: flex-start;
  margin-bottom: 0;
}

.sigma_info.style-10 .sigma_info-description,
.sigma_info.style-15 .sigma_info-description,
.sigma_info.style-17 .sigma_info-description,
.sigma_info.style-18 .sigma_info-description,
.sigma_info.style-20 .sigma_info-description,
.sigma_info.style-26 .sigma_info-description {
  flex: 1;
}

.sigma_info.style-10 .sigma_info-description h5,
.sigma_info.style-15 .sigma_info-description h5,
.sigma_info.style-20 .sigma_info-description h5 {
  margin: 0 0 14px;
}

.sigma_info.style-10,
.sigma_info.style-11,
.sigma_info.style-15,
.sigma_info.style-19,
.sigma_info.style-20,
.sigma_info.style-24 {
  border: 1px solid var(--thm-border);
  padding: 30px;
  transition: .3s;
}

.sigma_info.style-10:hover,
.sigma_info.style-11:hover {
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, .03);
  border-color: transparent;
}

.sigma_info.style-10 .sigma_info-title .sigma_info-icon,
.sigma_features.style-4 .sigma_info-title .sigma_info-icon,
.sigma_features.style-17 .sigma_info-title .sigma_info-icon,
.sigma_features.style-18 .sigma_info-title .sigma_info-icon {
  border: 0;
  border-radius: 6px;
}

/* info box style 11 */
.sigma_info.style-11,
.sigma_info.style-12,
.sigma_info.style-13 {
  box-shadow: none;
  text-align: center;
}

.sigma_info.style-11 .sigma_info-title,
.sigma_info.style-13 .sigma_info-title {
  align-items: center;
}

.sigma_info.style-11 .sigma_info-title .sigma_info-icon,
.sigma_info.style-12 .sigma_info-title .sigma_info-icon,
.sigma_info.style-13 .sigma_info-title .sigma_info-icon,
.sigma_info.style-19 .sigma_info-title .sigma_info-icon,
.sigma_info.style-24 .sigma_info-title .sigma_info-icon {
  border-radius: 6px;
}

/* info box style 12 */
.sigma_info.style-12 {
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 0;
  border-radius: 0;
  text-align: left;
}

/* info box style 13 */
.sigma_info.style-13 {
  padding: 0;
  margin: 0;
}

.sigma_info.style-13 .sigma_info-description p {
  max-width: 700px;
  margin: 0 auto;
}

.sigma_info.style-13 .sigma_info-description {
  margin-bottom: 15px;
}

.sigma_info-slider .slick-dots,
.sigma_info-image-slider-2 .slick-dots {
  margin-top: 20px;
}

.sigma_info-slider .slick-dots li button,
.sigma_info-image-slider-2 .slick-dots li button {
  background-color: var(--thm-border);
}

.sigma_info-slider .slick-dots .slick-active button,
.sigma_info-image-slider-2 .slick-dots .slick-active button {
  background-color: var(--thm-base);
}

.sigma_info-image-slider .slick-prev,
.sigma_info-image-slider .slick-next,
.sigma_info-image-slider-2 .slick-prev,
.sigma_info-image-slider-2 .slick-next {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--thm-base);
  color: #fff;
}

.sigma_info-image-slider .slick-prev:hover,
.sigma_info-image-slider .slick-next:hover,
.sigma_info-image-slider-2 .slick-prev:hover,
.sigma_info-image-slider-2 .slick-next:hover {
  background-color: var(--thm-base-hover);
}

.sigma_info-image-slider .slick-next,
.sigma_info-image-slider-2 .slick-next {
  right: 10px;
  left: auto;
}

.sigma_info-image-slider:hover .slick-prev,
.sigma_info-image-slider-2:hover .slick-prev {
  left: 20px;
  opacity: 1;
  visibility: visible;
}

.sigma_info-image-slider:hover .slick-next,
.sigma_info-image-slider-2:hover .slick-next {
  right: 20px;
  opacity: 1;
  visibility: visible;
}

.sigma_info-image.has-width {
  width: calc(1140px - 20%);
}

hr {
  border-top: 1px solid var(--thm-border);
  margin: 30px 0;
}

/* info box style 14 */
.sigma_info.style-14 {
  background-color: var(--thm-secondary);
  text-align: center;
  transition: .3s;
}

.sigma_info.style-14 .sigma_info-icon {
  width: 80px;
  height: 80px;
  background-color: #fff;
  border-radius: 50%;
  color: #313131;
  margin: 0 auto 30px;
  display: flex;
  align-items: center;
  font-size: 40px;
  line-height: 1;
  justify-content: center;
}

.sigma_info.style-14 h5 {
  color: #fff;
}

.sigma_info.style-14 p {
  color: #999b9f;
}

.sigma_info.style-14 .sigma_icon-block-content {
  margin-top: 20px;
}

.sigma_info.style-14 .sigma_icon-block-content i {
  width: 50px;
  height: 50px;
  color: #999b9f;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin: 0;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #999b9f;
  transition: .3s;
}

.sigma_info.style-14::before {
  content: '';
  position: absolute;
  top: -40px;
  right: -40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, .1);
  width: 120px;
  height: 120px;
  transition: .3s;
}

.sigma_info.style-14:hover {
  transform: translateY(-5px);
}

.sigma_info.style-14:hover::before {
  width: 160px;
  height: 160px;
}

.sigma_info.style-14:hover .sigma_icon-block-content i {
  color: var(--thm-base);
  border-color: #fff;
  transform: rotate(45deg);
}

/* info box style 15 */

.sigma_info.style-15{
  border: 0;
}

.sigma_info.style-15 .sigma_info-title .sigma_info-icon{
  width: 40px;
  height: 40px;
}

/* info box style 16 */

.sigma_info.style-16 .sigma_info-title .sigma_info-icon{
    width: 120px;
    height: 120px;
    background-color: transparent;
    border: 4px solid rgb(225 225 225 / 24%);
    color: var(--thm-secondary);
    position: relative;
}

.sigma_info.style-16 .sigma_info-title .sigma_info-icon i{
  font-size: 36px;
}

.sigma_info.style-16 .sigma_info-title .sigma_info-icon span{
  width: 35px;
  height: 35px;
  background-color: var(--thm-base);
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -20px;
  right: -20px;
}

.sigma_info-image.style-16{
  position: relative;
  margin: -190px 0 100px;
}

.info-space{   /* Temporary Code for space */
  padding-bottom: 200px;
}

/* info box style 17 */

.sigma_info.style-17,
.sigma_info.style-18,
.sigma_info.style-26{
  background-color: var(--thm-base);
  margin-bottom: 0;
  border-radius: 0;
  padding: 35px 30px;
}

.sigma_info.style-17.with-hue{
  background-color: rgba(var(--thm-base-rgb), .8);
}

.sigma_info.style-17 .sigma_info-title .sigma_info-icon{
  width: auto;
  height: auto;
  color: #fff;
}
.sigma_info.style-17 .sigma_info-title .sigma_info-icon i,
.sigma_info.style-18 .sigma_info-title .sigma_info-icon i{
  font-size: 40px;
}

.sigma_info.style-17 .sigma_info-description h5 {
  margin: 0 0 10px;
}

.sigma_info.style-17 .sigma_info-description h5,
.sigma_info.style-17 .sigma_info-description p,
.sigma_info.style-18 .sigma_info-description p{
  color: #fff;
}

/* info box style 18 */

.sigma_info-wrapper.style-18,
.sigma_info-wrapper.style-26{
  display: flex;
  align-items: center;
  background-color: #252525;
  padding: 35px 30px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.sigma_info.style-18,
.sigma_info.style-26{
  flex: 0 0 33.3%;
  padding: 0;
  background-color: transparent;
}
.sigma_info.style-18 + .sigma_info.style-18,
.sigma_info.style-26 + .sigma_info.style-26{
  padding-left: 25px;
  border-left: 2px solid rgba(255, 255, 255, 0.3);
}
.sigma_info.style-18 .sigma_info-title .sigma_info-icon,
.sigma_info.style-26 .sigma_info-title .sigma_info-icon{
  color: var(--thm-base);
  width: 50px;
  height: 50px;
}
.sigma_info.style-18 .sigma_info-title,
.sigma_info.style-26 .sigma_info-title{
  align-items: center;
}

/* info box style 19 */

.sigma_info.style-19,
.sigma_info.style-24{
  text-align: left;
  border: 0;
  margin-bottom: 60px;
}

.sigma_info.style-19 .sigma_info-title .sigma_info-icon{
  margin: -65px 0 0;
}

/* info box style 20 */

.sigma_info.style-20{
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.sigma_info.style-20 .sigma_info-description h5 a,
.sigma_info.style-20 .sigma_info-description p{
  color: #fff;
}

.sigma_info.style-20 .sigma_info-description h5 a:hover{
  color: var(--thm-base-hover);
}
.sigma_info.style-20 .sigma_info-title{
  align-items: center;
}
.sigma_info.style-20 .sigma_info-title .sigma_info-icon{
  color: var(--thm-base);
}
.sigma_info.style-20 .sigma_info-title .sigma_info-icon i{
  font-size: 46px;
}

/* info box style 21 */

.sigma_info.style-21{
  background-color: transparent;
  text-align: left;
}

.sigma_info.style-21 .sigma_info-description{
  padding-top: 20px;
}

.sigma_info.style-21 .sigma_info-description .sigma_info-category{
  display: block;
  font-weight: 500;
  margin-bottom: 10px;
}

.sigma_info.style-21 .sigma_info-description h5{
  margin-bottom: 0;
}

.sigma_info.style-21 .sigma_info-description p{
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid var(--thm-border);
}

/* info box style 22 */

.sigma_info-dark-space.style-22{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 490px;
  background-color: var(--thm-secondary);
}
.sigma_info.style-22{
  background-color: #fff;
  text-align: center;
  border-radius: 0;
  padding: 0;
}

.sigma_info.style-22 .sigma_info-description{
  padding: 80px 30px 25px;
  position: relative;
}

.sigma_info.style-22 .sigma_info-description .sigma_info-icon{
  width: 100px;
  height: 100px;
  font-size: 30px;
  background-color: #fff;
  box-shadow: 0 0 20px 5px rgb(0 0 0 / 3%);
  margin: -50px auto 0;
  color: var(--thm-base);
  position: absolute;
  left: 50%;
  top: -6px;
  transform: translateX(-50%);
}

.sigma_info.style-22 .sigma_info-description .sigma_info-icon:after{
  content: "";
  width: calc(100% + 40px);
  height: calc(100% + 40px);
  border: 3px solid var(--thm-base);
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.sigma_info.style-22 .sigma_info-description.secondary-color .sigma_info-icon{
  color: var(--thm-secondary);
}

.sigma_info.style-22 .sigma_info-description.secondary-color .sigma_info-icon:after{
  border-color: var(--thm-secondary);
}

.sigma_info.style-22:hover .sigma_info-description .sigma_info-icon:after{
  opacity: 1;
  visibility: visible;
}

.sigma_info.style-22 .sigma_info-footer{
  padding: 15px;
  border-top: 1px solid rgba(var(--thm-border-rgb), 0.2);
}

.sigma_info.style-22 .sigma_info-footer .btn-link{
  color: var(--thm-b-text);
}
.sigma_info.style-22 .sigma_info-footer .btn-link:hover{
  color: var(--thm-base);
}

/* info box style 23 */
.sigma_info.style-23{
  text-align: center;
  border-radius: 0;
  padding: 50px 40px;
  overflow: hidden;
}

.sigma_info.style-23 .sigma_info-title{
  flex-direction: column;
  margin-bottom: 14px;
}

.sigma_info.style-23 .sigma_info-title .sigma_info-icon{
  width: auto;
  height: auto;
  background-color: transparent;
  color: var(--thm-base);
  margin-bottom: 26px;
  margin-right: 0;
}

.sigma_info.style-23 .sigma_info-title  h5{
  font-size: 20px;
}
.sigma_info.style-23 .sigma_info-title .sigma_info-icon i{
  font-size: 66px;
}

.sigma_info.style-23 .absolute-icon{
  position: absolute;
  top: -28px;
  right: -28px;
  font-size: 140px;
  color: #fff;
  opacity: 0;
  visibility: hidden;
}

.sigma_info.style-23:hover{
  background-color: var(--thm-base);
  box-shadow: 0 0 40px 5px rgb(255 170 23 / 30%);
}

.sigma_info.style-23:hover .sigma_info-title h5,
.sigma_info.style-23:hover .sigma_info-description p,
.sigma_info.style-23:hover .sigma_info-title .sigma_info-icon{
  color: #fff;
}

.sigma_info.style-23:hover .absolute-icon{
  opacity: 0.2;
  visibility: visible;
}

.sigma_info.style-23.has-border{
  border: 2px solid var(--thm-border);
  box-shadow: 0 0 40px 5px rgb(0 0 0 / 3%);
}

.sigma_info.style-23.has-border:hover{
  box-shadow: none;
  background-color: transparent;
}

.sigma_info.style-23.has-border .sigma_info-title .sigma_info-icon.secondary-color,
.sigma_info.style-23.has-border:hover .sigma_info-title .sigma_info-icon.secondary-color{
  color: var(--thm-secondary);
}
.sigma_info.style-23.has-border:hover .sigma_info-title h5{
  color: var(--thm-secondary);
}
.sigma_info.style-23.has-border:hover .sigma_info-title .sigma_info-icon{
  color: var(--thm-base);
}

/* info box style 24 */

.sigma_info.style-24{
  margin-bottom: 30px;
  display: flex;
  align-items: flex-start;
  padding: 30px 25px;
}

.sigma_info.style-24 .sigma_info-description{
  flex: 1;
}

.sigma_info.style-24 .sigma_info-title .sigma_info-icon{
  margin: 0 20px 0 0;
}

.sigma_info.style-24 .sigma_info-description h5{
  margin: 0 0 14px;
}

/* info box style 25 */

.sigma_info-wrapper.style-25{
  position: relative;
  padding: 0;
  background-color: transparent;
}

/*.sigma_info-wrapper.style-25:after{*/
/*content: "";*/
/*position: absolute;*/
/*left: 50%;*/
/*transform: translateX(-50%);*/
/*bottom: 44px;*/
/*width: calc(100% - 30px);*/
/*height: 1px;*/
/*z-index: -1;*/
/*background-color: var(--thm-border);*/
/*}*/

.sigma_info.style-25{
  padding: 0;
  box-shadow: none;
}

.sigma_info.style-25 .sigma_info-title{
  margin-bottom: 0;
  align-items: flex-start;
}

.sigma_info.style-25 .sigma_info-description h5{
  margin-bottom: 5px;
}
.sigma_info.style-25 .sigma_info-title .sigma_info-icon{
  width: 110px;
  height: 110px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.03);
  color: var(--thm-base);
  margin-right: 28px;
}
.sigma_info.style-25 .sigma_info-title .sigma_info-icon i{
  font-size: 56px;
}

.sigma_info.style-25 .sigma_info-description .steps{
  font-size: 18px;
  display: block;
  font-weight: 600;
  color: var(--thm-base);
  margin: 10px 0 18px;
}

.sigma_info.style-25 .sigma_info-description .pulsive-dot{
  position: relative;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--thm-base);
  margin-left: 6px;
}
.sigma_info.style-25 .sigma_info-description .pulsive-dot:before{
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--thm-base);
  opacity: 0.2;
  width: calc(100% + 16px);
  height: calc(100% + 16px);
  border-radius: 50%;
}


/* info box style 26 */

.sigma_info-wrapper.style-26{
  background-color: #fff;
  padding: 20px 50px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
}

.sigma_info.style-26{
  padding: 10px 0;
}
.sigma_info.style-26 + .sigma_info.style-26{
  border-left-color: var(--thm-border);
  padding-left: 70px;
}

.sigma_info.style-26 .sigma_info-title .sigma_info-icon{
  border: 1px solid var(--thm-border);
  background-color: #fff;
}
.sigma_info.style-26 .sigma_info-title .sigma_info-icon i{
  font-size: 24px;
}

/*-------------- Category --------------------------- */
/* Category style 1 */
.sigma_category {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

.sigma_category a,
.sigma_category img {
  width: 100%;
  transition: 0.3s;
}

.sigma_category img {
  height: 100%;
  object-fit: cover;
}

.sigma_category:hover img {
  transform: scale(1.1);
}

.sigma_category .sigma_category-content {
  width: calc(100% - 20px);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
}

.sigma_category .sigma_category-content span {
  background-color: #fff;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--thm-secondary);
  font-size: 15px;
  font-weight: 700;
  border-radius: 0;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, .03);
}

/* Category style 2 */
.sigma_category.style-2 .sigma_category-content,
.sigma_category.style-3 .sigma_category-content {
  width: auto;
  left: 30px;
  top: 30px;
  bottom: auto;
  transform: none;
}

.sigma_category.style-2 .sigma_category-content span {
  background-color: #fff;
  padding: 8px 15px;
  border-radius: 0;
}

/* Category style 3 */
.sigma_category.style-3 {
  margin-bottom: 0;
}

.sigma_category.style-3 a,
.sigma_category.style-3 img {
  width: 100%;
}

.sigma_category.style-3 img {
  height: 100%;
  object-fit: cover;
}

.sigma_category.style-3 .sigma_category-content span {
  line-height: 1.3;
  font-size: 24px;
  background-color: transparent;
  padding: 0;
  margin-bottom: 10px;
}

.sigma_category.style-3 .sigma_category-content a {
  color: var(--thm-secondary);
  font-size: 12px;
  display: inline-block;
  font-weight: 600;
  text-transform: uppercase;
}

.sigma_category.style-3 .sigma_category-content a:hover {
  color: var(--thm-base-hover);
}

/* Category style 4 */
.sigma_category.style-4 {
  transition: .3s;
}

.sigma_category.style-4 .sigma_category-content {
  text-align: center;
  color: var(--thm-secondary);
  margin-bottom: 10px;
}

.sigma_category.style-4 .sigma_category-content h6 {
  margin: 0;
  font-size: 24px;
  transition-duration: .3s;
  transition-delay: .1s;
  transform: translateY(20px);
}

.sigma_category.style-4 .sigma_category-content span {
  background-color: transparent;
  padding: 0;
  color: var(--thm-b-text);
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
  transition-duration: .3s;
  transition-delay: .1s;
  font-weight: 400;
  box-shadow: none;
}

.sigma_category.style-4:hover {
  transform: scale(1.3);
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, .1);
}

.sigma_category.style-4:hover .sigma_category-content h6,
.sigma_category.style-4:hover .sigma_category-content span {
  transform: translateY(0);
}

.sigma_category.style-4:hover .sigma_category-content h6 {
  font-size: 20px;
}

.sigma_category.style-4:hover .sigma_category-content span {
  opacity: 1;
  visibility: visible;
  font-size: 12px;
}

/*-------------- General Section --------------------------- */
/* Style 1 */
.sigma_general-list {
  position: relative;
  margin-bottom: 30px;
}

.sigma_general-list ul li {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 30px;
  padding: 10px;
}

.sigma_general-list ul li+li {
  margin-top: 20px;
}

.sigma_general-list ul li i {
  width: 40px;
  height: 40px;
  background-color: var(--thm-base);
  color: #fff;
  border-radius: 50%;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 8px rgba(var(--thm-base-rgb), .5);
}

.sigma_general-list ul li span {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: var(--thm-secondary);
  padding-left: 20px;
  flex: 1;
}

/* Style 2 */
.sigma_general-list.style-2 ul li,
.sigma_general-list.style-3 ul li {
  background-color: transparent;
  padding: 0;
  border-radius: 0;
}

/* Style 3 */
.sigma_general-list.style-4 ul li i,
.sigma_general-list.style-3 ul li i {
  background-color: transparent;
  color: var(--thm-base);
  box-shadow: none;
  width: auto;
  height: auto;
}

/* Style 4 */
.sigma_general-list.style-4 ul li {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sigma_general-list.style-4 ul li+li {
  margin-top: 15px;
}

/* Style 5 */
.sigma_general-list.style-5 ul li {
  display: block;
  padding: 0;
  margin: 0;
  border-radius: 0;
  background-color: transparent;
}
.sigma_general-list.style-5,
.sigma_general-list.style-5 ul li p{
  margin: 0;
}
.sigma_general-list.style-5 ul li span{
  display: block;
  padding: 0;
}

.sigma_general-list.style-5 ul li + li {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid var(--thm-border);
}

/*-------------- Blog Sections --------------------------- */
/* Pagination */
.pagination {
  margin-top: 60px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 0;
}

.pagination .page-item {
  margin: 0;
}

.pagination .page-item:last-child .page-link,
.pagination .page-item:first-child .page-link,
.pagination .page-item .page-link {
  border-radius: 50%;
}

.pagination .page-link {
  color: var(--thm-secondary);
  background-color: rgba( var(--thm-base-rgb), .1 );
  border: 0;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 600;
  padding: 15px 20px;
  margin-left: 15px;
}

.pagination .page-item.active .page-link {
  background-color: var(--thm-base);
}

.pagination .page-item:not(.active) .page-link:hover,
.pagination .page-item:not(.active) .page-link:focus {
  color: var(--thm-base);
  outline: none;
}

/* Style 1 */
.sigma_post {
  position: relative;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
  background-color: #fff;
  margin-bottom: 30px;
  transition: 0.3s;
}

.sigma_post .sigma_post-thumb {
  position: relative;
  overflow: hidden;
}

.sigma_post .sigma_post-thumb img{
  transition: 0.3s;
  width: 100%;
}

.sigma_post .sigma_post-thumb:hover img {
  transform: scale(1.1);
}

.sigma_post .sigma_post-categories {
  position: absolute;
  left: 20px;
  top: 20px;
  display: flex;
  align-items: center;
}

.sigma_post .sigma_post-categories a,
.widget.widget-sigma-recent-posts.style-3 .sigma_recent-post .sigma_post-categories a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 15px;
  background-color: var(--thm-base);
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  border-radius: 26px;
  transition: 0.3s;
}

.sigma_post .sigma_post-categories a:hover,
.widget.widget-sigma-recent-posts.style-3 .sigma_recent-post .sigma_post-categories a:hover {
  background-color: var(--thm-base-hover);
}

.sigma_post .sigma_post-categories a+a {
  margin-left: 10px;
}

.sigma_post .sigma_post-body {
  padding: 30px;
  position: relative;
}

.sigma_post .sigma_post-body:before {
  content: "";
  position: absolute;
  bottom: -3px;
  right: -3px;
  width: 10px;
  height: 10px;
  background-color: var(--thm-base);
}

.sigma_post .sigma_post-meta {
  border-bottom: 1px solid var(--thm-border);
  padding-bottom: 15px;
}

.sigma_post .sigma_post-meta ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 15px;
}

.sigma_post .sigma_post-meta ul li {
  font-family: var(--thm-font);
  color: var(--thm-b-text);
  font-size: 14px;
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 400;
}

.sigma_post .sigma_post-meta ul li i {
  margin-top: -4px;
}

.sigma_post .sigma_post-meta ul li a {
  color: var(--thm-b-text);
}

.sigma_post .sigma_post-meta ul li a:hover {
  color: var(--thm-base-hover);
}

.sigma_post .sigma_post-meta ul li+li {
  padding-left: 10px;
  margin-left: 10px;
}

.sigma_post .sigma_post-meta ul li+li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 12px;
  background-color: var(--thm-border);
}

.sigma_post .sigma_post-date {
  position: absolute;
  right: 20px;
  top: -28px;
  text-align: center;
  padding: 12px 15px;
  font-weight: 600;
  background-color: var(--thm-base);
  color: #fff;
  line-height: 1;
  font-size: 15px;
  transition: 0.3s;
}

.sigma_post .sigma_post-date:hover {
  background-color: var(--thm-base-hover);
}

.sigma_post .sigma_post-body .sigma_post-content {
  padding: 15px 0 0;
}

.sigma_post .sigma_post-body .sigma_post-content h5 {
  margin-bottom: 15px;
}

.sigma_post .sigma_post-body .btn-link {
  color: var(--thm-secondary);
  font-weight: 500;
  margin-top: 15px;
  font-size: 14px;
}

.sigma_post .sigma_post-body .btn-link i {
  color: var(--thm-base);
}

.sigma_post .sigma_post-body .btn-link:hover {
  color: var(--thm-base-hover);
}

.sigma_post.style-1 .sigma_post-meta ul {
  margin-top: 0;
}

/* Style 2 */
.sigma_post.style-2 .sigma_post-body,
.sigma_post.style-10 .sigma_post-body,
.sigma_post.style-11 .sigma_post-body {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 25px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 0.6) 100%);
}

.sigma_post.style-2 img,
.sigma_post.style-10 img,
.sigma_post.style-11 img {
  width: 100%;
}

.sigma_post.style-2 .sigma_post-body:before,
.sigma_post.style-11 .sigma_post-body:before {
  content: none;
}

.sigma_post.style-2 .sigma_post-body .sigma_post-content,
.sigma_post.style-11 .sigma_post-body .sigma_post-content {
  padding: 0;
}

.sigma_post.style-2 .sigma_post-body .sigma_post-content h5 a,
.sigma_post.style-2 .sigma_post-body .sigma_post-content p,
.sigma_post.style-11 .sigma_post-body .sigma_post-content h5 a,
.sigma_post.style-11 .sigma_post-body .sigma_post-content p {
  color: #fff;
}

.sigma_post.style-2 .sigma_post-body .sigma_post-content h5 a:hover,
.sigma_post.style-11 .sigma_post-body .sigma_post-content h5 a:hover {
  color: var(--thm-base-hover);
}

.sigma_post.style-2 .sigma_post-body .sigma_post-content p{
  font-size: 15px;
}

.sigma_post.style-2 .sigma_post-body .btn-link {
  color: var(--thm-base);
}

/* Style 3 */
.sigma_post.style-3,
.sigma_post.style-4,
.sigma_post.style-12 {
  background-color: transparent;
  box-shadow: none;
}

.sigma_post.style-3 .sigma_post-body,
.sigma_post.style-4 .sigma_post-body,
.sigma_post.style-12 .sigma_post-body {
  border-radius: 0;
  background-color: #fff;
  width: calc(100% - 30px);
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
  margin: -25px auto 0;
}

.sigma_post.style-3 .sigma_post-body:before,
.sigma_post.style-4 .sigma_post-body:before,
.sigma_post.style-12 .sigma_post-body:before {
  content: none;
}

.sigma_post.style-3 .sigma_post-thumb {
  border-radius: 0;
}

.sigma_post.style-3 .sigma_post-meta,
.sigma_post.style-4 .sigma_post-meta,
.sigma_post.style-11 .sigma_post-meta,
.sigma_post.style-12 .sigma_post-meta,
.sigma_post.style-15 .sigma_post-meta {
  border-bottom: none;
  padding: 0;
}

.sigma_post.style-3 .sigma_post-meta ul,
.sigma_post.style-5 .sigma_post-meta ul {
  margin: 0;
}

.sigma_post.style-3 .sigma_post-meta ul li.author img {
  border-radius: 50%;
  margin-right: 10px;
}

.sigma_post.style-3 .sigma_post-meta ul li+li,
.sigma_post.style-4 .sigma_post-meta ul li+li,
.sigma_post.style-11 .sigma_post-meta ul li+li,
.sigma_post.style-12 .sigma_post-meta ul li+li,
.sigma_post.style-15 .sigma_post-meta ul li+li {
  padding-left: 0;
}

.sigma_post.style-3 .sigma_post-meta ul li+li:before,
.sigma_post.style-4 .sigma_post-meta ul li+li:before,
.sigma_post.style-11 .sigma_post-meta ul li+li:before,
.sigma_post.style-12 .sigma_post-meta ul li+li:before,
.sigma_post.style-15 .sigma_post-meta ul li+li:before {
  content: none;
}

.sigma_post.style-3 .sigma_post-body .sigma_post-content {
  padding: 15px 0;
}

.sigma_post.style-3 .sigma_post-body .sigma_post-content h5,
.sigma_post.style-4 .sigma_post-body .sigma_post-content h5 {
  margin-bottom: 0;
}

/* Style 4 */
.sigma_post.style-4 .sigma_post-body,
.sigma_post.style-12 .sigma_post-body,
.sigma_post.style-15 .sigma_post-body {
  border-radius: 0;
}

.sigma_post.style-4 .sigma_post-meta ul li.bold-text a {
  color: var(--thm-secondary);
  font-weight: 700;
}

.sigma_post.style-4 .sigma_post-body .sigma_post-content,
.sigma_post.style-12 .sigma_post-body .sigma_post-content,
.sigma_post.style-15 .sigma_post-body .sigma_post-content {
  padding: 0;
}

.sigma_post.style-4 .sigma_post-meta {
  margin: 12px 0 10px;
}

.sigma_post.style-4 .sigma_post-body .sigma_post-content p,
.sigma_post.style-12 .sigma_post-body .sigma_post-content p {
  font-size: 14px;
}

/* Style 5 */
.sigma_post.style-5 {
  border-radius: 0;
  overflow: hidden;
}

.sigma_post.style-5 .sigma_post-body:before {
  content: none;
}

.sigma_post.style-5 .sigma_post-meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

 .sigma_post.style-5 .sigma_post-meta a,
 .sigma_post.style-11 .sigma_post-meta .sigma_post-date,
 .sigma_post.style-12 .sigma_post-meta .sigma_post-date,
 .sigma_post.style-15 .sigma_post-meta .sigma_post-date{
  color: var(--thm-b-text);
  font-family: var(--thm-font);
  font-size: 14px;
  display: flex;
  text-transform: uppercase;
  align-items: center;
  font-weight: 400;
}

.sigma_post.style-5 .sigma_post-meta a:hover {
  color: var(--thm-base-hover);
}

.sigma_post.style-5 .sigma_post-meta a i,
.sigma_post.style-12 .sigma_post-meta a i,
.sigma_post.style-15 .sigma_post-meta a i,
.sigma_post.style-11 .sigma_post-meta i {
  color: var(--thm-base);
  font-weight: 500;
  margin-right: 8px;
  font-size: 16px;
}

.sigma_post.style-5 .sigma_post-meta ul li+li:before {
  content: none;
}

.sigma_post.style-5 .sigma_post-meta ul li+li {
  padding-left: 0;
}

.sigma_post.style-5 .sigma_post-meta ul li a {
  color: var(--thm-base);
  font-weight: 500;
  font-size: 16px;
}

/* Style 6 */
.sigma_post.style-6,
.sigma_post.style-7,
.sigma_post.style-9,
.sigma_post.style-10 {
  box-shadow: none;
  background-color: transparent;
}

.sigma_post.style-6 .sigma_post-body,
.sigma_post.style-7 .sigma_post-body,
.sigma_post.style-9 .sigma_post-body {
  padding: 15px 0 0 0;
}

.sigma_post.style-6 .sigma_post-body .sigma_post-content,
.sigma_post.style-9 .sigma_post-body .sigma_post-content,
.sigma_post.style-10 .sigma_post-body .sigma_post-content {
  padding: 15px 0 0;
}

.sigma_post.style-6 .sigma_post-meta,
.sigma_post.style-7 .sigma_post-meta,
.sigma_post.style-9 .sigma_post-meta,
.sigma_post.style-10 .sigma_post-meta {
  padding: 0;
  border: 0;
}

.sigma_post.style-6 .sigma_post-body:before,
.sigma_post.style-7 .sigma_post-body:before,
.sigma_post.style-9 .sigma_post-body:before,
.sigma_post.style-10 .sigma_post-body:before {
  content: none;
}

.sigma_post.style-6 .sigma_post-meta ul {
  margin: 0;
}

/* Style 7 */
.sigma_post.style-7 {
  margin-bottom: 60px;
}

.sigma_post.style-7 .sigma_post-body{
  padding: 30px;
  border: 1px solid var(--thm-border);
}

.sigma_post.style-7 .sigma_post-body .sigma_post-content h5+p {
  margin: 15px 0;
}

.sigma_post.style-7 .sigma_post-body .sigma_post-content h5,
.sigma_post.style-8 .sigma_post-body .sigma_post-content h5 {
  margin-bottom: 0;
}

.sigma_post.style-7 .sigma_post-body .sigma_post-content,
.sigma_post.style-8 .sigma_post-body .sigma_post-content {
  padding: 0;
}

.sigma_post.style-7 .sigma_post-meta ul li i {
  margin-right: 10px;
  font-size: 16px;
}

.sigma_post.style-7 .sigma_post-meta ul li+li:before,
.sigma_post.style-8 .sigma_post-meta ul li+li:before {
  content: none;
}

.sigma_post.style-7 .sigma_post-meta ul li+li,
.sigma_post.style-8 .sigma_post-meta ul li+li {
  padding-left: 0;
  margin-left: 20px;
}

/* Style 8 */
.sigma_post.style-8 {
  border-radius: 0;
  overflow: hidden;
}

.sigma_post .sigma_post-body:before {
  content: none;
}

.sigma_post.style-8 .sigma_post-body .sigma_post-content p {
  margin: 20px 0;
}

.sigma_post.style-8 .sigma_post-body .sigma_post-meta {
  border: 0;
  padding: 0;
}

.sigma_post.style-8 .sigma_post-body .sigma_post-meta ul {
  justify-content: space-between;
}

.sigma_post.style-8 .sigma_post-body .sigma_post-meta ul li span,
.widget.widget-sigma-recent-posts.style-3 .sigma_recent-post .author-info span {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--thm-b-text);
  color: #fff;
  font-size: 15px;
  border-radius: 50%;
  margin-right: 10px;
}

.sigma_post.style-8 .sigma_post-body .sigma_post-meta ul .author-name,
.widget.widget-sigma-recent-posts.style-3 .sigma_recent-post .author-info .author-name {
  color: var(--thm-secondary);
  display: block;
  line-height: 1;
  font-size: 14px;
  font-weight: 500;
}

.sigma_post.style-8 .sigma_post-body .sigma_post-meta ul .author-name:hover,
.widget.widget-sigma-recent-posts.style-3 .sigma_recent-post .author-info .author-name:hover {
  color: var(--thm-base-hover);
}

.sigma_post.style-8 .sigma_post-body .sigma_post-meta ul li:last-child {
  flex-direction: column;
}

/* Style 9 */
.sigma_post.style-9 .sigma_post-body .sigma_post-content,
.sigma_post.style-10 .sigma_post-body .sigma_post-content {
  border-bottom: 1px solid var(--thm-base);
  padding-bottom: 25px;
  margin-bottom: 25px;
}

.sigma_post.style-9 .sigma_post-body .btn-link,
.sigma_post.style-10 .sigma_post-body .btn-link {
  margin-top: 0;
}

/* Style 10 */
.sigma_post.style-10 .sigma_post-meta ul li,
.sigma_post.style-10 .sigma_post-meta ul li a,
.sigma_post.style-10 .sigma_post-body .sigma_post-content h5 a,
.sigma_post.style-10 .sigma_post-body .sigma_post-content p,
.sigma_post.style-10 .sigma_post-body .btn-link {
  color: #fff;
}

.sigma_post.style-10 .sigma_post-meta ul li+li:before {
  background-color: #fff;
}

.sigma_post.style-10 .sigma_post-meta ul li a:hover,
.sigma_post.style-10 .sigma_post-body .sigma_post-content h5 a:hover,
.sigma_post.style-10 .sigma_post-body .btn-link:hover {
  color: var(--thm-base-hover);
}

/* Style 11 */

.sigma_post.style-11 .sigma_post-meta .sigma_post-date,
.sigma_post.style-12 .sigma_post-meta .sigma_post-date,
.sigma_post.style-15 .sigma_post-meta .sigma_post-date{
    border: 0;
    padding: 5px 25px;
    background-color: var(--thm-base);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 22px;
    position: relative;
    top: 0;
    left: 0;
    line-height: 1.8;
}
.sigma_post.style-11 .sigma_post-meta i{
  color: #fff;
  margin-top: 0;
}
.sigma_post.style-11 .sigma_post-meta ul li + li,
.sigma_post.style-12 .sigma_post-meta ul li + li,
.sigma_post.style-15 .sigma_post-meta ul li + li{
  margin-left: 20px;
}

.sigma_post.style-11 .sigma_post-meta .sigma_post-date:hover,
.sigma_post.style-12 .sigma_post-meta .sigma_post-date:hover,
.sigma_post.style-15 .sigma_post-meta .sigma_post-date:hover{
  background-color: var(--thm-base-hover);
}

.sigma_post.style-11 .sigma_post-meta li a{
  color: #fff;
}

.sigma_post.style-11 .sigma_post-body .sigma_post-content h5{
  margin-bottom: 10px;
}

/* Style 12 */

.sigma_post.style-12 .sigma_post-thumb a,
.sigma_post.style-15 .sigma_post-thumb a{
  width: 100%;
}

.sigma_post.style-12 .sigma_post-body{
  margin: -35px 0 0;
  padding: 30px 25px;
  border-bottom: 5px solid var(--thm-base);
}

.sigma_post.style-12 .sigma_post-meta a i,
.sigma_post.style-15 .sigma_post-meta a i{
  color: var(--thm-b-text);
  margin-top: 0;
}

.sigma_post.style-12 .sigma_post-meta .sigma_post-date i{
  color: #fff;
}
.sigma_post.style-12 .sigma_post-meta.is-absolute,
.sigma_post.style-15 .sigma_post-meta.is-absolute{
  margin: -52px 0 0;
  position: relative;
}
.sigma_post.style-12 .sigma_post-meta.is-absolute .sigma_post-date,
.sigma_post.style-15 .sigma_post-meta.is-absolute .sigma_post-date{
  margin-bottom: 0;
}
.sigma_post.style-12 .sigma_post-body .sigma_post-content h5,
.sigma_post.style-15 .sigma_post-body .sigma_post-content h5{
  margin-bottom: 8px;
}
.sigma_post.style-12 .sigma_post-meta ul{
  margin: 20px 0 8px;
}

/* Style 13 */

.sigma_post.style-13{
  box-shadow: none;
}

.sigma_post.style-13 .sigma_post-date,
.sigma_post.style-14 .sigma_post-date{
  right: 10px;
  top: -40px;
  padding: 14px 20px 18px;
  z-index: 1;
  font-weight: 400;
}
.sigma_post.style-13 .sigma_post-date span,
.sigma_post.style-14 .sigma_post-date span{
  line-height: 1.3;
  font-size: 30px;
  display: block;
  font-weight: 500;
}
.sigma_post.style-13 .sigma_post-body,
.sigma_post.style-14 .sigma_post-body{
 border: 2px solid var(--thm-border);
 border-top: 0;
 padding: 20px 30px 30px;
}
.sigma_post.style-13 .sigma_post-meta,
.sigma_post.style-14 .sigma_post-meta{
  border: 0;
  padding: 0;
}
.sigma_post.style-13 .sigma_post-meta ul li:last-child a,
.sigma_post.style-14 .sigma_post-meta ul li:last-child a{
  color: var(--thm-base);
}
.sigma_post.style-13 .sigma_post-meta ul li:last-child a:hover,
.sigma_post.style-14 .sigma_post-meta ul li:last-child a:hover{
  color: var(--thm-base-hover);
}

/* Style 14 */

.sigma_post.style-14 .sigma_post-date{
  right: 30px;
}
.sigma_post.style-14{
  box-shadow: 0 0 40px 5px rgb(0 0 0 / 3%);
}
.sigma_post.style-14:hover{
  box-shadow: none;
}
.sigma_post.style-14 .sigma_post-date{
  padding: 10px 20px 14px;
}
.sigma_post.style-14 .sigma_post-body{
  border-width: 1px;
}
.sigma_post.style-14 .sigma_post-body .sigma_post-content h5{
  margin-bottom: 0;
}

/* Style 15 */

.sigma_post.style-15{
  padding: 20px 20px 30px;
  box-shadow: none;
  border: 2px solid var(--thm-border);
  transition: .3s;
}
.sigma_post.style-15:hover{
  box-shadow: 0 0 40px 5px rgb(0 0 0 / 3%);
  border-color: transparent;
}


.sigma_post.style-15 .sigma_post-thumb{
  border-radius: 6px;
}

.sigma_post.style-15 .sigma_post-meta.is-absolute{
  text-align: right;
}

.sigma_post.style-15 .sigma_post-body{
  padding: 30px 0 0;
}

.sigma_post.style-15 .sigma_post-meta.is-absolute .sigma_post-date{
  left: auto;
  right: 20px;
  font-weight: 600;
  border-radius: 15px;
}

.sigma_post.style-15 .sigma_post-meta ul{
  margin: 15px 0 12px;
}

.sigma_post.style-15 .sigma_post-body .btn-link{
  color: var(--thm-base);
  font-weight: 600;
  margin-top: 0;
  font-size: 16px;
}

.sigma_post.style-15 .sigma_post-body .btn-link:hover{
  color: var(--thm-base-hover);
}

/* Post-list */
.sigma_post.sigma_post-list {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.sigma_post-list+.sigma_post-list {
  padding-top: 40px;
  margin-top: 40px;
  border-top: 1px solid var(--thm-border);
}

.sigma_post.sigma_post-list .sigma_post-thumb {
  width: 270px;
  margin-right: 30px;
}

.sigma_post.sigma_post-list .sigma_post-thumb img {
  width: auto;
}

.sigma_post.sigma_post-list .sigma_post-body {
  flex: 1;
  padding-top: 0;
}

.sigma_post.sigma_post-list .sigma_post-body .sigma_post-categories {
  position: static;
  margin-bottom: 15px;
}

.sigma_post.sigma_post-list.post-format-audio .embed-responsive-16by9::before {
  padding-top: 100%;
}

/*Blog: Misc*/
.sigma_post h5 {
  font-size: 34px;
}

.col-lg-6 .sigma_post h5 {
  font-size: 28px;
}

.col-lg-6 .sigma_post.style-7,
.col-lg-4 .sigma_post.style-7,
.col-lg-3 .sigma_post.style-7 {
  margin-bottom: 30px;
}

.sigma_related-posts .sigma_post h5,
.col-lg-4 .sigma_post h5,
.col-lg-5 .sigma_post h5 {
  font-size: 20px;
}

.col-lg-4 .sigma_post.style-6 .sigma_post-body,
.col-lg-4 .sigma_post.style-7 .sigma_post-body,
.col-lg-4 .sigma_post.style-9 .sigma_post-body {
  padding: 20px 0 0;
}

.col-lg-5 .sigma_post.sigma_post-list .sigma_post-thumb {
  width: auto;
}

.col-lg-3 .sigma_post h5 {
  font-size: 22px;
}

.col-lg-3 .sigma_post.post-format-quote blockquote cite span {
  width: 50px;
  height: 50px;
}

.col-lg-4 .sigma_post.post-format-link .sigma_post-body,
.col-lg-3 .sigma_post.post-format-link .sigma_post-body {
  padding: 30px;
}

.col-lg-4 .sigma_post.post-format-quote blockquote,
.col-lg-3 .sigma_post.post-format-quote blockquote {
  padding: 30px;
}

.col-lg-4 .sigma_post.post-format-audio .embed-responsive-16by9::before {
  padding-top: 46.25%;
}

.col-lg-3 .sigma_post.post-format-audio .embed-responsive-16by9::before {
  padding-top: 66.25%;
}

/*------- Formats------------- */
/*  post format gallery */
.sigma_post.post-format-gallery .sigma_post-thumb .slick-prev,
.sigma_post.post-format-gallery .sigma_post-thumb .slick-next {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.sigma_post.post-format-gallery .sigma_post-thumb .slick-next {
  right: 10px;
  left: auto;
}

.sigma_post.post-format-gallery .sigma_post-thumb:hover .slick-prev {
  left: 20px;
  opacity: 1;
  visibility: visible;
}

.sigma_post.post-format-gallery .sigma_post-thumb:hover .slick-next {
  right: 20px;
  opacity: 1;
  visibility: visible;
}

.sigma_post.post-format-gallery .sigma_post-thumb .slick-dots {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

/*  post format Quote */
.sigma_post.post-format-quote blockquote {
  position: relative;
  padding: 60px 50px;
  background-color: var(--thm-base);
  border-radius: 6px;
  overflow: hidden;
  margin: 0;
  z-index: 1;
}
.sigma_post.post-format-quote h5{
  color: #fff;
}


.sigma_post.post-format-quote blockquote:before {
  content: "\f10d";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  font-weight: 600;
  left: 40px;
  top: -10px;
  color: rgba(255, 255, 255, 0.4);
  font-size: 80px;
  z-index: -1;
}

.sigma_post.post-format-quote blockquote p {
  color: #fff;
  font-style: italic;
  margin: 0;
  font-size: 20px;
}

.sigma_post.post-format-quote blockquote cite {
  display: flex;
  align-items: center;
  margin-top: 20px;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.sigma_post.post-format-quote blockquote cite span {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: var(--thm-secondary);
  font-size: 15px;
  border-radius: 50%;
  margin-right: 15px;
}

/*  post format Video */
.sigma_post.post-format-video .sigma_video-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/*  post format Audio */
.sigma_post.post-format-audio .embed-responsive-16by9::before {
  padding-top: 16.25%;
}

/*  post format Link */
.sigma_post.post-format-link {
  box-shadow: none;
}

.sigma_post.post-format-link .sigma_post-body {
  background-color: var(--thm-secondary);
  padding: 50px;
  position: relative;
  border-radius: 6px;
}

.sigma_post.post-format-link .sigma_post-content {
  padding: 0;
}

.sigma_post.post-format-link .sigma_post-content p {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}

.sigma_post.post-format-link .sigma_post-meta {
  margin-bottom: 0;
  padding-bottom: 0;
  border: 0;
}

.sigma_post.post-format-link .sigma_post-meta a {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 16px;
  margin-top: 20px;
}

.sigma_post.post-format-link .sigma_post-meta a i {
  margin-right: 10px;
  font-size: 20px;
}

.sigma_post.post-format-link .sigma_post-meta a:hover {
  color: var(--thm-base-hover);
}

.sigma_post.post-format-link h5 {
  color: #fff;
}

/*-------------- Blog Widgets --------------------------- */
.sidebar .widget {
  padding: 30px;
  border-radius: 6px;
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.04);
}

.sidebar .widget+.widget {
  margin-top: 50px;
}

.sidebar .widget .widget-title {
  position: relative;
  margin-bottom: 30px;
  font-size: 26px;
}

.sidebar .widget .widget-title::before {
  content: '';
  background-color: var(--thm-base);
  width: 3px;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: -30px;
}

.sidebar .widget .btn-link {
  margin-top: 10px;
}

/*== search Widget & banner widget start ==*/
.widget.widget-banner,
.widget.widget-search {
  padding: 0;
  box-shadow: none;
  background-color: transparent;
}

.widget.widget-banner .widget-title::before,
.widget.widget-search .widget-title::before {
  display: none;
}

.widget.widget-blockquote{
  padding: 0;
  border: 0;
  box-shadow: none;
}
.widget.widget-blockquote .blockquote{
  border-radius: 0;
  text-align: left;
}
.widget.widget-blockquote .blockquote p{
  font-size: 14px;
  font-weight: 400;
}
.widget.widget-blockquote .blockquote a{
  margin-top: 20px;
}

/*== category Widget start ==*/
.widget.widget-categories ul li+li,
.widget.widget-sigma-recent-posts.style-3 .sigma_recent-post+.sigma_recent-post,
.widget.widget-sigma-recent-posts.style-4 .sigma_recent-post+.sigma_recent-post {
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid var(--thm-border);
}

.widget.widget-categories ul li a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--thm-b-text);
  font-weight: 500;
  position: relative;
  padding-left: 18px;
}

.widget.widget-categories ul li a:hover {
  padding-left: 24px;
}

.widget.widget-categories ul li a:before {
  content: "\f0da";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Font Awesome 5 Pro";
}

.widget.widget-categories ul li a:hover {
  color: var(--thm-base);
}

.widget.widget-categories ul li a span {
  color: #fff;
  background-color: var(--thm-base);
  font-size: 14px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  font-weight: 400;
  transition: 0.3s;
}

.widget.widget-categories ul li a span:hover {
  background-color: var(--thm-base-hover);
}

/*== Recent Post Widget start ==*/
.widget.widget-sigma-recent-posts .sigma_recent-post {
  display: flex;
  align-items: center;
}

.widget.widget-sigma-recent-posts .sigma_recent-post+.sigma_recent-post {
  margin-top: 30px;
}

.widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-image {
  position: relative;
  margin-right: 15px;
  display: block;
  border-radius: 14px;
  overflow: hidden;
  transition: 0.3s;
}

.widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-image span {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  border-bottom-left-radius: 14px;
  color: #fff;
  font-size: 14px;
  background-color: var(--thm-base);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  transition: .3s;
}

.widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-image:hover span {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-descr {
  flex: 1;
}

.widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-descr h6 {
  font-size: 16px;
  margin-bottom: 0;
  line-height: 1.4;
}

.widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-descr h6 a {
  color: var(--thm-secondary);
}

.widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-descr h6 a:hover,
.widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-descr .date:hover,
.widget.widget-sigma-recent-posts.style-4 .recent-post-image:hover,
.widget.widget-sigma-recent-posts.style-4 .recent-post-descr h6 a:hover span {
  color: var(--thm-base);
}

.widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-descr .date {
  color: var(--thm-b-text);
  font-size: 14px;
  display: inline-block;
}

/* Recent Post Style 2 */
.widget.widget-sigma-recent-posts.style-2 .sigma_recent-post {
  flex-direction: column;
  align-items: start;
}

.widget.widget-sigma-recent-posts.style-2 .sigma_recent-post .recent-post-image {
  margin-right: 0;
  margin-bottom: 15px;
}

/* Recent Post Style 3 */
.widget.widget-sigma-recent-posts.style-3 .sigma_recent-post .recent-post-descr h6 {
  margin: 8px 0 16px;
}

.widget.widget-sigma-recent-posts.style-3 .sigma_recent-post {
  display: block;
}

.widget.widget-sigma-recent-posts.style-3 .sigma_recent-post .sigma_post-categories {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.widget.widget-sigma-recent-posts.style-3 .sigma_recent-post .sigma_post-categories a {
  margin-bottom: 8px;
  margin-right: 8px;
}

/* Recent Post Style 4 */
.widget.widget-sigma-recent-posts.style-4 .recent-post-image {
  font-size: 18px;
  color: var(--thm-b-text);
}

.widget.widget-sigma-recent-posts.style-4 .recent-post-descr span {
  color: var(--thm-b-text);
  transition: 0.3s;
  font-weight: 400;
}

/*== Tags Widget start ==*/
.tagcloud a {
  display: inline-block;
  font-size: 14px;
  padding: 6px 15px;
  color: #fff;
  font-weight: 500;
  transition: 0.3s;
  background-color: var(--thm-secondary);
  margin: 6px 6px 0px 0px;
  border-radius: 25px;
}

.tagcloud a:hover {
  background-color: var(--thm-base);
}

/*== Banner Widget start ==*/
.widget.widget-banner .banner-area {
  border-radius: 6px;
  overflow: hidden;
}

/*== Follow Widget start ==*/
.widget.widget-follow .sigma_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  text-transform: capitalize;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, .03);
}

.widget.widget-follow .sigma_btn+.sigma_btn {
  margin-top: 15px;
}

.widget.widget-follow .sigma_btn .follow-icon i {
  display: inline-block;
  font-size: 30px;
  margin-right: 0;
  width: 40px;
  font-weight: 400;
}

.widget.widget-follow .sigma_btn .follow-icon p span {
  font-weight: 400;
  font-size: 14px;
  display: block;
  line-height: 1;
}

.widget.widget-follow .sigma_btn i:last-child {
  font-size: 16px;
  margin: 0;
  position: relative;
  transition: 0.3s;
  left: 0;
}

.widget.widget-follow .sigma_btn:hover i:last-child {
  left: 3px;
}

/*== Insta Widget start ==*/
.widget.widget-instagram .insta-images {
  margin: 4px 2px 0 2px;
  display: flex;
  flex-wrap: wrap;
}

.widget.widget-instagram .insta-images a {
  position: relative;
  margin: 0 2px 4px;
  display: block;
  flex: 25%;
  overflow: hidden;
  border-radius: 16px;
}

.widget.widget-instagram .insta-images a::before {
  content: "\f16d";
  font-family: "Font Awesome 5 Brands";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  background-color: #fff;
  color: var(--thm-secondary);
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, .03);
  transition: .3s;
  opacity: 0;
  z-index: 2;
  visibility: hidden;
  border-radius: 50%;
}

.widget.widget-instagram .insta-images a:hover::before {
  top: 50%;
  opacity: 1;
  visibility: visible;
}

.widget.widget-instagram .insta-images a img {
  transition: 0.3s;
}

.widget.widget-instagram .insta-images a:hover img {
  transform: scale(1.1);
}

.instagram_gallery {
  display: flex;
  flex-wrap: wrap;
}

/* Sidebar style 2 */
.sidebar-style-2 .sidebar .widget {
  background-color: transparent;
  box-shadow: none;
  padding: 0;
}

.sidebar-style-2 .sidebar .widget .widget-title::before {
  display: none;
}

/* Sidebar style 3 */
.sidebar-style-3 .sidebar .widget {
  background-color: #FCF7FF;
  box-shadow: none;
}

.sidebar-style-3 .widget.widget-search {
  padding: 30px;
}

.sidebar-style-3 .sidebar input[type="number"], .sidebar-style-3 .sidebar input[type="text"], .sidebar-style-3 .sidebar input[type="email"], .sidebar-style-3 .sidebar input[type="password"],
.sidebar-style-3 .sidebar input[type="tel"], .sidebar-style-3 .sidebar input[type="url"], .sidebar-style-3 .sidebar input[type="search"], .sidebar-style-3 .sidebar select,
.sidebar-style-3 .sidebar textarea, .sidebar-style-3 .sidebar .form-control {
  background-color: #fff;
}

/* Sidebar style 4 */
.sidebar-style-4 .sidebar .widget .widget-title {
  padding-left: 50px;
}

.sidebar-style-4 .sidebar .widget .widget-title::before,
.sidebar-style-4 .sidebar .widget .widget-title::after {
  position: absolute;
  top: 50%;
  left: 0;
  height: 3px;
  width: 20px;
  transform: translateY(-50%);
}

.sidebar-style-4 .sidebar .widget .widget-title::after {
  content: '';
  background-color: var(--thm-base);
  width: 10px;
  margin-right: 10px;
  left: 25px
}

.sidebar-style-4 .sidebar .widget.widget-search .widget-title {
  padding-left: 0;
}

.sidebar-style-4 .sidebar .widget.widget-search .widget-title::after {
  display: none;
}

/* Sidebar style 5 */
.sidebar-style-5 .sidebar .widget .widget-title::before {
  left: 0;
  width: 100px;
  z-index: -1;
  opacity: .3;
}

.sidebar-style-5 .sidebar .widget .widget-title {
  padding-left: 25px;
}

/* Sidebar style 6 */
.sidebar-style-6 .sidebar .widget .widget-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-style-6 .sidebar .widget .widget-title::after {
  content: '';
  background-color: var(--thm-base);
  margin-left: 10px;
}

.sidebar-style-6 .sidebar .widget .widget-title::before {
  margin-right: 10px;
}

.sidebar-style-6 .sidebar .widget .widget-title::after,
.sidebar-style-6 .sidebar .widget .widget-title::before {
  position: relative;
  top: auto;
  left: auto;
  height: 3px;
  width: 30px;
}

.sidebar-style-6 .sidebar .widget.widget-search .widget-title::after {
  display: none;
}

/* Sidebar style 7 */

.sidebar-style-7 .sidebar .widget{
  box-shadow: none;
  border: 2px solid var(--thm-border);
  border-radius: 0;
  padding: 40px;
}
.sidebar-style-7 .sidebar .widget.widget-search{
  padding: 40px;
}
.sidebar-style-7 .sidebar .widget .widget-title::before,
.sidebar-style-7 .sidebar .widget .widget-title::after{
    content: '';
    background-color: var(--thm-secondary);
    width: 3px;
    height: 24px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(16deg);
}
.sidebar-style-7 .sidebar .widget .widget-title::after{
  left: 7px;
}

.sidebar-style-7 .sidebar .widget .widget-title{
  padding-bottom: 15px;
  padding-left: 20px;
  border-bottom: 1px solid var(--thm-border);
}

.sidebar-style-7 .sidebar .widget.widget-categories ul li + li{
  margin-top: 15px;
  border-top: 0;
}

.sidebar-style-7 .sidebar .widget.widget-categories ul li a{
  height: 52px;
  background-color: #FCF7FF;
}

.sidebar-style-7 .sidebar .widget.widget-categories ul li a:before{
  content: none;
}
.sidebar-style-7 .sidebar .widget.widget-categories ul li a span{
  border-radius: 0;
  background-color: #dcdcdc;
  height: 52px;
  width: 50px;
  color: var(--thm-b-text);
}
.sidebar-style-7 .sidebar .widget.widget-categories ul li a:hover span{
  background-color: var(--thm-base);
  color: #fff;
}

.sidebar-style-7 .sidebar .widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-image{
 border-radius: 0;
}
.sidebar-style-7 .sidebar .widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-descr .date i{
 color: var(--thm-secondary);
}

.sidebar-style-7 .sidebar .widget.widget-about .sigma_author-box h5 {
    margin-top: 25px;
    margin-bottom: 8px;
}
.sidebar-style-7 .sidebar .widget.widget-about .sigma_author-box .sigma_sm{
  margin-top: 25px;
}

.sidebar-style-7 .sidebar .widget.widget-about .sigma_author-box .sigma_sm li a{
  background-color: transparent;
  color: var(--thm-b-text);
}
.sidebar-style-7 .sidebar .widget.widget-about .sigma_author-box .sigma_sm li a:hover{
  background-color: transparent;
  color: var(--thm-base);
}


/* Sidebar style 8 */

.sidebar-style-8 .sidebar .widget{
  padding: 0;
  box-shadow: none;
  border-radius: 0;
}
.sidebar-style-8 .sidebar .widget-title{
  font-size: 22px;
}
.sidebar-style-8 .sidebar .widget.widget-categories ul li a,
.sidebar-style-9 .sidebar .widget.widget-categories ul li a{
  padding-left: 0;
}

.sidebar-style-8 .sidebar .widget.widget-categories ul li a:hover,
.sidebar-style-9 .sidebar .widget.widget-categories ul li a:hover{
  padding-left: 5px;
}

.sidebar-style-8 .sidebar .widget.widget-categories ul li a:before,
.sidebar-style-8 .sidebar .widget .widget-title::before,
.sidebar-style-9 .sidebar .widget.widget-categories ul li a:before,
.sidebar-style-9 .sidebar .widget .widget-title::before,
.sidebar-style-10 .sidebar .widget .widget-title::before{
  content: none;
}
.sidebar-style-8 .sidebar .widget.widget-categories ul li a span{
 background-color: transparent;
 color: var(--thm-b-text);
 font-weight: 500;
}
.sidebar-style-8 .sidebar .widget.widget-categories ul li a span:hover{
  background-color: transparent;
}

.sidebar-style-8 .sidebar .widget.widget-categories.archive ul,
.sidebar-style-8 .sidebar .widget.widget-sigma-recent-posts .sigma_recent-post{
  border: 1px solid var(--thm-border);
  padding: 20px;
  border-radius: 6px;
}

.sidebar-style-8 .sidebar .widget.widget-categories.archive ul li+li,
.sidebar-style-9 .sidebar .widget.widget-categories ul li+li{
  padding-top: 0;
  border-top: 0;
}

.sidebar-style-8 .sidebar .widget.widget-categories.archive ul li a{
  font-weight: 400;
}

.sidebar-style-8 .sidebar .widget.widget-sigma-recent-posts .sigma_recent-post{
  padding: 15px;
}

.sidebar-style-8 .sidebar .widget.widget-sigma-recent-posts .recent-post-descr .date{
  color: var(--thm-base);
}
.sidebar-style-8 .sidebar .widget.widget-sigma-recent-posts .recent-post-descr .date:hover{
  color: var(--thm-base-hover);
}

.sidebar-style-8 .sidebar .widget.tagcloud a{
 padding: 4px 18px;
 border: 1px solid var(--thm-border);
 border-radius: 6px;
 font-size: 14px;
 margin: 6px 6px 0px 0px;
}

.sidebar-style-8 .sidebar .widget.tagcloud a:hover{
  background-color: var(--thm-base);
  color: #fff;
  border-color: var(--thm-base);
}


/* Sidebar style 9 */

.sidebar-style-9 .sidebar .widget{
  box-shadow: none;
  background-color: transparent;
  padding: 0;
}

.sidebar-style-9 .sidebar .widget{
  padding: 0 30px 30px;
  border: 1px solid var(--thm-border);
  border-radius: 6px;
}

.sidebar-style-9 .sidebar .widget .widget-title{
  padding: 20px;
  background-color: #EBF2F3;
  font-size: 20px;
  margin: 0 0 30px;
  border-radius: 6px 6px 0 0;
  width: calc(100% + 60px);
  margin-left: -30px;
}

.sidebar-style-9 .sidebar .widget.widget-gender ul li + li{
  margin-top: 30px;
}

.sidebar-style-9 .sidebar .widget .irs-with-grid .irs-grid{
  display: none;
}
.sidebar-style-9 .sidebar .widget .irs--flat .irs-handle>i:first-child{
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 2px solid var(--thm-base);
  border-radius: 50%;
  top: -5px;
}
.sidebar-style-9 .sidebar .widget .irs--flat .irs-handle{
  width: auto;
}

.sidebar-style-9 .sidebar .widget .irs--flat .irs-bar,
.sidebar-style-9 .sidebar .widget .irs--flat .irs-line{
  height: 5px;
}


/* Sidebar style 10 */

.sidebar-style-10 .sidebar .widget{
  padding: 0;
  overflow: hidden;
}
.sidebar-style-10 .sidebar .widget .widget-title{
  padding: 15px 25px;
  background-color: rgba(var(--thm-secondary-rgb), 0.1);
  margin-bottom: 0;
}

.sidebar-style-10 .sidebar .widget .widget-inner{
  padding: 25px;
}

.sidebar-style-10 .sidebar .widget + .widget-inner{
  border-top: 2px solid var(--thm-border);
}

.sidebar-style-10 .sidebar .widget.widget-form .form-group>i{
  top: 70%;
}

.sidebar-style-10 .sidebar .widget .widget-service ul li{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  border: 1px solid var(--thm-border);
  border-radius: 6px;
}

.sidebar-style-10 .sidebar .widget .widget-service ul li + li{
  margin-top: 10px;
}

.sidebar-style-10 .sidebar .widget.widget-booking ul li + li{
  margin-top: 15px;
}

/*-------------- Blog details Sections --------------------------- */
.sigma_post-details-categories{
  display: flex;
  align-items: center;
  margin: 0 0 15px;
}
.sigma_post-details-categories a{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 15px;
  background-color: var(--thm-base);
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  border-radius: 26px;
  transition: 0.3s;
  margin: 0 5px 5px 0;
}
.sigma_post-details-categories a:hover{
  background-color: var(--thm-base-hover);
}
.sigma_post-details-meta{
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.sigma_post-details-meta span{
  display: inline-block;
  margin: 0 20px 5px 0;
  font-family: var(--thm-font);
  font-size: 14px;
  text-transform: uppercase;
}
.sigma_post-details-meta span i{
  margin-right: 5px;
  color: var(--thm-base);
}

.sigma_post-details-meta-item{
  flex: 1;
}
.sigma_post-details .sigma_sm{
  display: flex;
  align-items: center;
}
.sigma_post-details .sigma_sm li + li{
  margin-left: 15px;
}
.sigma_post-details .sigma_sm li a{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #FCF7FF;
  color: var(--thm-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
}
.sigma_post-details .sigma_sm li a:hover{
  background-color: var(--thm-base);
  color: #fff;
}

.sigma_author-about{
  display: flex;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 0 40px 5px rgba(0,0,0,.03);
  padding: 30px;
}
.sigma_author-about img{
  max-width: 150px;
}
.sigma_author-about-content{
  flex: 1;
  margin-left: 20px;
}
.sigma_author-about-content span{
  text-transform: uppercase;
  color: var(--thm-base);
  font-family: var(--thm-font);
  font-size: 18px;
}
.sigma_author-about-content p{
  margin: 0;
}
.comments-list ul {
  margin: 0;
}

.comments-list .comment-item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 30px;
  margin-top: 0;
}

.comments-list .comment-item+.comment-item {
  border-top: 1px solid var(--thm-border);
  padding-top: 30px;
}

.comments-list .comment-item:last-child {
  margin-bottom: 0;
}

.comments-list .comment-item img {
  width: 100px;
  margin-right: 20px;
  border-radius: 50%;
}

.comments-list .comment-item .comment-body {
  position: relative;
  flex: 1;
}

.comments-list .comment-item h5 {
  margin-bottom: 10px;
}

.comments-list .comment-item span {
  display: inline-block;
  font-size: 13px;
  margin-bottom: 15px;
  color: var(--thm-base);
  font-weight: 600;
}

.comments-list .comment-item p {
  margin-bottom: 0;
}

.comments-list .comment-item .btn-link {
  padding: 10px 25px;
  border: 2px solid var(--thm-border);
  border-radius: 35px;
  font-size: 14px;
  position: absolute;
  top: 0;
  color: #777;
  font-weight: 700;
  font-style: normal;
  cursor: pointer;
  right: 0;
}
.comments-list .comment-item .btn-link:hover{
  background-color: var(--thm-base);
  border-color: var(--thm-base);
  color: #fff;
}

.sigma_single-pagination-item + .sigma_single-pagination-item{
  margin-top: 10px;
}
.sigma_single-pagination-thumb{
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--thm-border);
  background-color: #fff;
  border-radius: 0;
  margin-right: 10px;
}
.sigma_single-pagination a{
  display: flex;
  align-items: center;
}
.sigma_single-pagination a span{
  font-size: 14px;
  color: var(--thm-b-text);
  display: block;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.sigma_single-pagination a h6{
  margin: 0;
  line-height: 1;
}
.sigma_single-pagination-content{
  line-height: 1;
}
.sigma_single-pagination a img{
  max-width: 40px;
}
.sigma_single-pagination-prev,
.sigma_single-pagination-next{
  padding: 10px;
  border-radius: 0;
  background-color: #fff;
  border: 1px solid var(--thm-border);
  box-shadow: 0 0 40px 5px rgba(0,0,0,.03);
  transition: .3s;
}
.sigma_single-pagination-prev:hover,
.sigma_single-pagination-next:hover{
  background-color: #FCF7FF;
}
.sigma_single-pagination i{
  color: #838383;
  margin-left: auto;
  margin-right: 20px;
  transition: .3s;
}
.sigma_single-pagination-prev:hover i{
  transform: translateX(-3px);
}
.sigma_single-pagination-next:hover i{
  transform: translateX(3px);
}

.sigma_post-details .big-text-wrapper{
  display: flex;
  align-items: center;
  margin: 50px 0 20px;
}
.sigma_post-details .big-text-wrapper span{
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--thm-base);
  color: #fff;
  font-size: 48px;
  font-weight: 500;
  margin-right: 32px;
}

.sigma_post-details .big-text-wrapper p{
  flex: 1;
  margin-bottom: 0;
}

.sigma_post-details .sigma_team.style-17 .sigma_team-thumb img{
  margin-bottom: 0;
}

.sigma_post-details .sigma_team.style-17 .sigma_team-body{
  border-right: 0;
  padding: 20px;
}

.sigma_post-details .sigma_team.style-17 .sigma_rating{
  margin: 6px 0;
}

.sigma_post-details .sigma_team.style-17 .sigma_team-categories{
  margin-top: 0;
}
.sigma_post-details .detail-menu-list{
  background-color: #fff;
  box-shadow: 0 0 40px 5px rgba(0,0,0,.03);
  margin: 50px 0 40px;
}

.sigma_post-details .detail-menu-list .menu{
  padding: 26px 20px;
  border-right: 1px solid var(--thm-border);
  display: flex;
  align-items: center;
  justify-content: center;
}

.sigma_post-details .detail-menu-list .menu a{
  color: var(--thm-secondary);
  opacity: 0.4;
  font-size: 18px;
  font-weight: 500;
}

.sigma_post-details .detail-menu-list .menu a:hover,
.sigma_post-details .detail-menu-list .menu.nav-item .nav-link.active{
  opacity: 1;
}

.sigma_post-details .spacer{
  height: 50px;
}

/* Style 2 */
.sigma_post-details.style-2 .sigma_post-details-inner,
.sigma_post-details.style-5 .sigma_post-details-inner{
  border: 2px solid var(--thm-border);
  padding: 30px;
}

.sigma_post-details.style-2 .sigma_post-details-inner .sigma_author-about,
.sigma_post-details.style-5 .sigma_post-details-inner .sigma_author-about{
  box-shadow: none;
  border: 2px solid var(--thm-border);
}

.sigma_post-details.style-2 .comments-list .comment-item img{
  border-radius: 0;
}

/* Style 3 */
.sigma_post-details.style-3{
  text-align: center;
}

.sigma_post-details.style-3 .sigma_post-details-meta-item .sigma_sm,
.sigma_post-details.style-3 .sigma_post-details-categories,
.sigma_post-details.style-3 .sigma_post-details-meta{
  justify-content: center;
}

.sigma_post-details.style-3 .sigma_author-about{
  text-align: left;
}

.sigma_post-details.style-3 .sigma_general-list ul li i{
  display: none;
}

.sigma_post-details.style-3 .comments-list .comment-item .btn-link{
  position: relative;
  top: auto;
  margin-top: 15px;
}
.sigma_post-details.style-3 .comments-list .comment-item{
  display: block;
}
.sigma_post-details.style-3 .comments-list .comment-item img{
  margin: 0 0 15px;
}

.entry-content img{
  margin-bottom: 20px;
}

.blockquote,
.entry-content blockquote{
  position: relative;
  margin: 20px 0 30px;
  color: #fff;
  padding: 10px 20px;
  border-left: 0;
  text-align: center;
  padding: 40px;
  background-position: center;
  background-size: cover;
  background-color: #313131;
  border-radius: 35px;
}

.blockquote::before,
.entry-content blockquote::before{
  position: absolute;
  bottom: 0;
  right: 10px;
  font-size: 160px;
  content: "\f10e";
  color: #fff;
  opacity: .1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.blockquote cite,
.entry-content blockquote cite{
  position: relative;
  font-weight: 600;
  display: block;
  margin: 0 0 10px;
  color: var(--thm-base);
}

.blockquote p,
.entry-content blockquote p{
  position: relative;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 700;
  color: #fff;
}

/* Style 4 */

.sigma_post-details.style-4 .entry-content blockquote{
  background-color: #fff;
  box-shadow: 0 10px 30px rgba(0,0,0,.05);
  text-align: left;
  border-radius: 0;
  overflow: hidden;
  margin: 0;
}

.sigma_post-details.style-4 .entry-content blockquote p{
  font-size: 16px;
  color: var(--thm-b-text);
  font-weight: 500;
}

.sigma_post-details.style-4 .entry-content blockquote:before{
  color: var(--thm-b-text);
  bottom: -20px;
  right: -28px;
  font-size: 130px;
}

.sigma_post-details.style-4 .entry-content blockquote cite{
  font-style: normal;
  margin: 15px 0 0;
  font-weight: 500;
}

.sigma_post-details.style-4 .sigma_general-list ul li i{
  background-color: rgba(var(--thm-base-rgb), 0.1);
  color: var(--thm-base);
  box-shadow: none;
}

.sigma_post-details.style-4 .sigma_single-pagination,
.sigma_post-details.style-5 .sigma_single-pagination{
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 40px 5px rgb(0 0 0 / 3%);
  background-color: #fff;
  width: 100%;
}

.sigma_post-details.style-4 .sigma_single-pagination i{
  color: #fff;
  margin: 0;
}

.sigma_post-details.style-4 .sigma_single-pagination-prev,
.sigma_post-details.style-4 .sigma_single-pagination-next{
  padding: 0;
  box-shadow: none;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin: 0;
  border: 0;
}

.sigma_post-details.style-4 .sigma_single-pagination-prev{
  background-color: var(--thm-secondary);
}
.sigma_post-details.style-4 .sigma_single-pagination-next{
  background-color: var(--thm-base);
}

.sigma_post-details.style-4 .pagination-breadcrumb{
  width: 50px;
  height: 50px;
  border: 1px solid var(--thm-base);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sigma_post-details.style-4 .pagination-breadcrumb span{
  background-color: var(--thm-base);
  width: 20px;
  height: 2px;
}
.sigma_post-details.style-4 .pagination-breadcrumb span + span{
  margin-top: 5px;
}

.sigma_post-details.style-4 .widget-sigma-portfolio-details{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 1;
  background-color: #fff;
  padding: 60px;
  box-shadow: 0 2px 24px rgb(0 0 0 / 4%);
}

.sigma_post-details.style-4 .widget-sigma-portfolio-details .sigma_general-list.style-5 ul li{
  display: flex;
  align-items: center;
}

.sigma_post-details.style-4 .widget-sigma-portfolio-details .sigma_general-list.style-5 ul li span{
  display: inline-block;
}

.sigma_post-details.style-4 .widget-sigma-portfolio-details .sigma_general-list.style-5 ul li + li{
  border: 0;
  padding-top: 0;
}
.sigma_post-details.style-4 .widget-sigma-portfolio-details .sigma_social-icons li a{
  color: #fff;
}

.sigma_post-details.style-4 hr{
  margin: 25px 0;
  opacity: 0;
}

/* Style 5 */

.sigma_post-details.style-5 .sigma_post-details-meta span{
  margin: 0;
  position: relative;
}

.sigma_post-details.style-5 .sigma_post-details-meta span + span:before{
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 12px;
  background-color: var(--thm-b-text);
  opacity: 0.3;
}

.sigma_post-details.style-5 .sigma_post-details-meta span + span{
  padding-left: 30px;
  margin-left: 30px;
}

.sigma_post-details.style-5 .sigma_general-list ul li span{
  color: var(--thm-b-text);
  font-weight: 400;
}
.sigma_post-details.style-5 .sigma_general-list ul li+li{
  margin-top: 10px;
}

.sigma_post-details.style-5 .entry-content blockquote{
  background-color: #FCF7FF;
  border-radius: 0;
  padding: 50px;
}

.sigma_post-details.style-5 .entry-content blockquote p{
  color: var(--thm-secondary);
  font-weight: 600;
}
.sigma_post-details.style-5 .entry-content blockquote cite{
  font-style: normal;
  font-weight: 500;
}
.sigma_post-details.style-5 .entry-content blockquote:before{
  content: "\f10d";
  opacity: 1;
  bottom: 20px;
  right: 50px;
  font-size: 220px;
}

.sigma_post-details.style-5 .tagcloud a{
  background-color: #FCF7FF;
  color: var(--thm-b-text);
  border-radius: 0;
}
.sigma_post-details.style-5 .tagcloud a:hover{
  background-color: var(--thm-base);
  color: #fff;
}
.sigma_post-details.style-5 .sigma_post-details-meta-item .sigma_sm li + li{
  margin-left: 10px;
}
.sigma_post-details.style-5 .sigma_post-details-meta-item .sigma_sm li a{
  background-color: transparent;
  color: var(--thm-b-text);
}
.sigma_post-details.style-5 .sigma_post-details-meta-item .sigma_sm li a:hover{
  background-color: transparent;
  color: var(--thm-base);
}
.sigma_post-details.style-5 .sigma_single-pagination{
  box-shadow: none;
}
.sigma_post-details.style-5 .sigma_single-pagination-prev,
.sigma_post-details.style-5 .sigma_single-pagination-next{
  padding: 0;
  box-shadow: none;
  border: 0;
  margin: 0;
}
.sigma_post-details.style-5 .sigma_single-pagination-prev:hover,
.sigma_post-details.style-5 .sigma_single-pagination-next:hover{
  background-color: transparent;
}
.sigma_post-details.style-5 .sigma_single-pagination-prev h4,
.sigma_post-details.style-5 .sigma_single-pagination-next h4{
  transition: 0.3s;
}
.sigma_post-details.style-5 .sigma_single-pagination-prev:hover h4,
.sigma_post-details.style-5 .sigma_single-pagination-next:hover h4{
  color: var(--thm-base);
}

.sigma_post-details.style-5 .sigma_single-pagination .grid-block{
  display: grid;
  grid-template-columns: 7px 7px 7px;
  grid-template-rows: 7px 7px 7px;
  column-gap: 8px;
  grid-row-gap: 8px;
}
.sigma_post-details.style-5 .sigma_single-pagination .grid-block span{
  width: 12px;
  height: 12px;
  border: 2px solid var(--thm-base);
  border-radius: 50%;
}
.sigma_post-details.style-5 .sigma_post-details-inner .sigma_author-about{
  margin: 20px 0 40px;
}
.sigma_post-details.style-5 .comments-list .comment-item img{
  border-radius: 50%;
}
.sigma_post-details.style-5 .comments-list .comment-item h5{
  margin-bottom: 0;
}
.sigma_post-details.style-5 .comments-list .comment-item span{
  color: var(--thm-b-text);
  font-size: 14px;
  font-weight: 500;
}
.sigma_post-details.style-5 .comments-list .comment-item span i{
  color: var(--thm-base);
  margin-right: 5px;
}
.sigma_post-details.style-5 .comments-list .comment-item .btn-link{
  border-radius: 0;
}
.sigma_post-details.style-5 .comments-list .comment-item .btn-link i{
  color: var(--thm-base);
}
.sigma_post-details.style-5 .comments-list .comment-item .btn-link:hover i{
  color: #fff;
}

.sigma_post-details.style-5 .comments-list .comment-item:nth-child(2){
 margin-left: 120px;
}
.sigma_post-details.style-5 .comment-form{
  background-color: #FCF7FF;
  padding: 50px;
}

.sigma_post-details.style-5 .comment-form .form-control{
  background-color: #fff;
  border: 0;
}

/* Style 6 */

.sigma_post-details.style-6 .entry-content img{
  margin-bottom: 25px;
  border-radius: 6px;
}

.sigma_post-details.style-6 .sigma_post-details-meta span{
  margin-bottom: 0;
}
.sigma_post-details.style-6 .sigma_post-details-meta{
  margin-bottom: 12px;
}
.sigma_post-details.style-6 .entry-content blockquote{
  background-color: transparent;
  padding: 0 0 0 30px;
  border-radius: 0;
  text-align: left;
  margin: 30px 0 30px;
}

.sigma_post-details.style-6 .entry-content blockquote:after{
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 100%;
  background-color: var(--thm-base);
  border-radius: 30px;
}

.sigma_post-details.style-6 .entry-content blockquote:before{
  content: none;
}
.sigma_post-details.style-6 .entry-content blockquote p{
  font-size: 16px;
  color: var(--thm-b-text);
}
.sigma_post-details.style-6 .entry-content blockquote cite{
  font-style: italic;
  margin: 10px 0 0;
  font-weight: 600;
}

.sigma_post-details.style-6 .sigma_post-details-meta-item{
  display: flex;
  align-items: center;
}

.sigma_post-details.style-6 .tagcloud a{
  padding: 0;
  background-color: transparent;
  color: var(--thm-b-text);
  margin: 0;
  font-size: 16px;
}

.sigma_post-details.style-6 .tagcloud a:hover{
  color: var(--thm-base-hover);
}

.sigma_post-details.style-6 .tagcloud a + a{
  margin-left: 6px;
}

.sigma_post-details.style-6 .comments-list .comment-item .btn-link{
 position: relative;
 border: 0;
 padding: 0;
 color: var(--thm-base);
 margin-top: 18px;
 font-size: 16px;
}
.sigma_post-details.style-6 .comments-list .comment-item .btn-link:hover{
  color: var(--thm-base-hover);
  background-color: transparent;
}

.sigma_post-details.style-6 .comments-list .comment-item .date{
  position: absolute;
  top: 0;
  right: 0;
  font-size: 16px;
  margin-bottom: 0;
  color: var(--thm-b-text);
  font-weight: 400;
}

.sigma_post-details.style-6 .comments-list .comment-item{
  padding: 30px;
  border: 2px solid var(--thm-border);
}

.sigma_post-details.style-6 .comments-list .comment-item:last-child{
  margin-left: 70px;
}

/*-------------- Services Sections --------------------------- */

/* Services */
.sigma_service{
  display: block;
  background-color: #fff;
  border: 2px solid var(--thm-border);
  padding: 40px;
  text-align: center;
  transition: .3s;
  margin-bottom: 30px;
  color: var(--thm-b-text);
  position: relative;
}

.sigma_service.border .sigma_service-thumb{
  position: relative;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  margin: 0 auto 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--thm-border);
}
.sigma_service.border .sigma_service-thumb::before{
  content: '';
  position: absolute;
  top: 10px;
  right: 17px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--thm-base);
  transition: .3s;
}
.sigma_service.border .sigma_service-thumb::after{
  content: '';
  position: absolute;
  bottom: 10px;
  left: 17px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--thm-base);
  transition: .3s;
}

.sigma_service .sigma_service-thumb img{
  width: 100%;
}

.sigma_service.primary-bg:hover,
.sigma_service.primary-bg{
  background-color: var(--thm-base);
  border: 0;
}
.sigma_service.secondary-bg:hover,
.sigma_service.secondary-bg{
  background-color: var(--thm-secondary);
  border: 0;
}

.sigma_service.border.primary-bg .sigma_service-thumb::after,
.sigma_service.border.primary-bg .sigma_service-thumb::before,
.sigma_service.border.secondary-bg .sigma_service-thumb::after,
.sigma_service.border.secondary-bg .sigma_service-thumb::before{
  background-color: #fff;
}

.sigma_service.border:hover .sigma_service-thumb::after{
  bottom: -4px;
  left: 47px;
}
.sigma_service.border:hover .sigma_service-thumb::before{
  top: -4px;
  right: 47px;
}

.sigma_service.bg-white{
  border: 0;
}

.sigma_service.style-1.bg-white:hover .btn-link,
.sigma_service.style-1.bg-white:hover p,
.sigma_service.style-1.bg-white:hover h5{
  color: var(--thm-secondary);
}
.sigma_service.style-1.bg-white:hover i{
  color: var(--thm-base);
}

.sigma_service i{
  font-size: 60px;
  color: var(--thm-base);
  line-height: 1;
  transition: .3s;
}
.sigma_service .btn-link{
  margin-top: 20px;
  font-weight: 600;
  display: block;
}
.sigma_service .btn-link i{
  color: var(--thm-secondary);
  margin-left: 5px;
}
.sigma_service-thumb{
  margin-bottom: 40px;
}
.sigma_service h5{
  margin-bottom: 10px;
  transition: .3s;
}
.sigma_service p{
  margin: 0;
  transition: .3s;
}
.sigma_service:hover{
  background-color: var(--thm-base);
  transform: translateY(-3px);
}
.sigma_service.style-1:hover .btn-link,
.sigma_service.style-1:hover i,
.sigma_service.style-1:hover p,
.sigma_service.style-1:hover h5{
  color: #fff;
}

/* Style 2 & 3 */
.sigma_service.style-2 h5,
.sigma_service.style-6 h5,
.sigma_service.style-7 h5,
.sigma_service.style-8 h5,
.sigma_service.style-11 h5,
.sigma_service.style-17 h5{
  margin-bottom: 10px;
}
.sigma_service.style-2 i,
.sigma_service.style-6 i.icon-box,
.sigma_service.style-7 .icon-box,
.sigma_service.style-8 .icon-box{
  position: absolute;
  bottom: -30px;
  right: 30px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--thm-b-text);
  background-color: #fff;
  font-size: 24px;
  border-radius: 50%;
  box-shadow: 0 0 40px 5px rgba(0,0,0,.03);
}
.sigma_service.style-2:hover i{
  background-color: #fdb900;
  color: #fff;
}

.sigma_service.style-3,
.sigma_service.style-2,
.sigma_service.style-6,
.sigma_service.style-7,
.sigma_service.style-8,
.sigma_service.style-11,
.sigma_service.style-17{
  background-color: transparent;
  box-shadow: none;
  text-align: left;
  padding: 0;
}

.sigma_service.style-3 .sigma_service-thumb,
.sigma_service.style-2 .sigma_service-thumb,
.sigma_service.style-6 .sigma_service-thumb,
.sigma_service.style-7 .sigma_service-thumb,
.sigma_service.style-8 .sigma_service-thumb,
.sigma_service.style-11 .sigma_service-thumb,
.sigma_service.style-17 .sigma_service-thumb{
  position: relative;
  margin: 0;
  z-index: 1;
}

.sigma_service.style-3 i{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  transition: .3s;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, -50%);
}

.sigma_service.style-3 .sigma_service-thumb::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.4);
  transition: .3s;
  opacity: 0;
  visibility: hidden;
}

.sigma_service.style-3:hover i,
.sigma_service.style-3:hover .sigma_service-thumb::before,
.sigma_service.style-2:hover i,
.sigma_service.style-2:hover .sigma_service-thumb::before{
  opacity: 1;
  visibility: visible;
}

.sigma_service.style-3 h5{
  margin-bottom: 20px;
}

.sigma_service.style-3 p,
.sigma_service.style-2 p,
.sigma_service.style-6 p,
.sigma_service.style-7 p,
.sigma_service.style-8 p,
.sigma_service.style-11 p,
.sigma_service.style-17 p{
  margin: 0;
}

.sigma_service.style-3 .sigma_service-body,
.sigma_service.style-2 .sigma_service-body{
  padding: 30px;
}
.sigma_service.style-3,
.sigma_service.style-6,
.sigma_service.style-7,
.sigma_service.style-8{
  border: 0;
}
.sigma_service.style-3 .sigma_service-body{
  position: relative;
  background-color: #fff;
  box-shadow: 0 0 40px 5px rgba(0,0,0,.03);
  border: 0;
}
.sigma_service.style-3 .sigma_service-body::before{
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px;
  background-color: var(--thm-base);
  transition: .3s;
}
.sigma_service.style-3:hover .sigma_service-body::before{
  width: 100%;
}

/* Style 4 */
.sigma_service.style-4,
.sigma_service.style-9,
.sigma_service.style-13{
  text-align: left;
}
.sigma_service.style-4 .sigma_service-footer,
.sigma_service.style-13 .sigma_service-footer{
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid var(--thm-border);
}
.sigma_service.style-4 i,
.sigma_service.style-9 i,
.sigma_service.style-13 i{
  margin-bottom: 20px;
  display: block;
}
.sigma_service.style-4 .sigma_service-footer ul{
  margin: 0;
}
.sigma_service.style-4 .sigma_service-footer ul li{
  position: relative;
  color: var(--thm-b-text);
  display: flex;
  font-size: 14px;
  align-items: center;
  margin: 0;
}
.sigma_service.style-4 .sigma_service-footer ul li + li{
  margin-top: 10px;
}
.sigma_service.style-4 .sigma_service-footer ul li::before{
  content: '-';
  margin-right: 10px;
  transition: .3s;
  color: var(--thm-base);
  display: block;
}
.sigma_service.style-4:hover *{
  color: #fff;
}
.sigma_service.style-4:hover .sigma_service-footer ul li{
  color: #fff;
}
.sigma_service.style-4:hover .sigma_service-footer ul li::before{
  color: #fff;
}

.sigma_service.style-4.dark,
.sigma_service.style-9.dark{
  background-color: var(--thm-secondary);
  border-color: rgba(255,255,255,.1);
}
.sigma_service.style-4.dark .sigma_service-footer{
  border-top-color: rgba(255,255,255,.1);
}
.sigma_service.style-4.dark i,
.sigma_service.style-9.dark i{
  color: var(--thm-base);
}
.sigma_service.style-4.dark h5,
.sigma_service.style-9.dark h5{
  color: #fff;
}
.sigma_service.style-4.dark *,
.sigma_service.style-9.dark *{
  color: #999b9f;
}
.sigma_service.style-4.dark .sigma_service-footer ul li{
  color: #999b9f;
}

/* Style 5 */
.sigma_service.style-5{
  text-align: left;
  padding: 30px;
}
.sigma_service.style-5:hover{
  background-color: transparent;
}
.sigma_service.style-5 > i{
  display: block;
  margin-bottom: 20px;
}
.sigma_service.style-5 span{
  color: #777;
}
.sigma_service.style-5 h5{
  font-size: 22px;
}
.sigma_service.style-5 .sigma_service-link i{
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #313131;
  box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
  margin-top: 50px;
  transition: .3s;
}
.sigma_service.style-5:hover .sigma_service-link i{
  background-color: #fdb900;
  color: #fff;
}


/* Style 6 */

.sigma_service.style-6,
.sigma_service.style-7{
  background-color: #fff;
  padding: 25px;
}

.sigma_service.style-6:before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 126px;
    width: 4px;
    height: 68px;
    background-color: var(--thm-base);
    transition: 0.3s;
}

.sigma_service.style-6 .sigma_service-body{
  padding-top: 25px;
}

.sigma_service.style-6 .sigma_service-body h5 a,
.sigma_service.style-7 .sigma_service-body h5 a,
.sigma_service.style-8 .sigma_service-body h5 a,
.sigma_service.style-11 .sigma_service-body h5 a,
.sigma_service.style-17 .sigma_service-body h5 a{
  color: var(--thm-secondary);
}

.sigma_service.style-6 .sigma_service-body h5 a:hover,
.sigma_service.style-7 .sigma_service-body h5 a:hover,
.sigma_service.style-8 .sigma_service-body h5 a:hover,
.sigma_service.style-9 .sigma_service-body h5 a:hover,
.sigma_service.style-17 .sigma_service-body h5 a:hover{
  color: var(--thm-base-hover);
}

.sigma_service.style-6 .sigma_service-body .btn-link,
.sigma_service.style-8 .sigma_service-body .btn-link,
.sigma_service.style-9 .sigma_service-body .btn-link,
.sigma_service.style-11 .sigma_service-body .btn-link,
.sigma_service.style-16 .sigma_service-body .btn-link,
.sigma_service.style-17 .sigma_service-body .btn-link{
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.sigma_service.style-6 i.icon-box,
.sigma_service.style-7 .icon-box,
.sigma_service.style-8 .icon-box{
  border-radius: 0;
  background-color: var(--thm-base);
  color: #fff;
  width: 70px;
  height: 70px;
  bottom: auto;
  top: 30px;
  right: 0;
  font-size: 36px;
}

.sigma_service.style-6 .sigma_service-body .btn-link i,
.sigma_service.style-8 .sigma_service-body .btn-link i,
.sigma_service.style-9 .sigma_service-body .btn-link i,
.sigma_service.style-11 .sigma_service-body .btn-link i,
.sigma_service.style-14 .sigma_service-body .btn-link i,
.sigma_service.style-17 .sigma_service-body .btn-link i{
  font-size: 13px;
  margin-left: 10px;
}

.sigma_service.style-6 .sigma_service-body .btn-link:hover i,
.sigma_service.style-8 .sigma_service-body .btn-link:hover i,
.sigma_service.style-9 .sigma_service-body .btn-link:hover i,
.sigma_service.style-11 .sigma_service-body .btn-link:hover i,
.sigma_service.style-14 .sigma_service-body .btn-link:hover i,
.sigma_service.style-17 .sigma_service-body .btn-link:hover i{
  margin-left: 13px;
}

/* Style 7 */

.sigma_service.style-7 .sigma_service-body{
  padding-bottom: 25px;
}

.sigma_service.style-7 .icon-box{
  background-color: transparent;
  border: 1px solid var(--thm-border);
  margin-bottom: 22px;
  position: relative;
  top: 0;
  right: auto;
  left: 0;
  box-shadow: 0 0 16px 0px rgb(0 0 0 / 3%);
  color: var(--thm-secondary);
}

.sigma_service.style-7 .icon-box span{
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 50%;
  background-color: var(--thm-base);
  width: 25px;
  height: 25px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Style 8 */

.sigma_service.style-8,
.sigma_service.style-11{
  background-color: #fff;
  box-shadow: 0 0 40px 5px rgb(0 0 0 / 8%);
}

.sigma_service.style-8 .sigma_service-body .btn-link i,
.sigma_service.style-16 .sigma_service-body .btn-link i,
.sigma_service.style-17 .sigma_service-body .btn-link i{
  color: var(--thm-base);
}

.sigma_service.style-8 .sigma_service-body{
  padding: 60px 30px 30px;
}

.sigma_service.style-8 .icon-box{
  bottom: -30px;
  top: auto;
  right: auto;
  left: 30px;
  font-weight: 600;
}

.sigma_service-wrapper{
  position: relative;
  margin-bottom: -250px;
}

.space-top{  /* Temporary Code */
  padding-top: 200px;
}

/* Style 9 */

.sigma_service.style-9{
  border: 0;
}

.sigma_service.style-9:before{
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: 0;
  background-color: var(--thm-base);
  transition: 0.3s;
}

.sigma_service.style-9:hover:before{
  height: 100%;
}

.sigma_service.style-9.dark span{
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 75px;
    display: block;
    line-height: 1;
    font-weight: 600;
    color: rgb(79 77 77 / 85%);
}

.sigma_service.style-9 .sigma_service-body h5 a{
  color: #fff;
}

.sigma_service.style-9 .sigma_service-body .btn-link,
.sigma_service.style-9 .sigma_service-body .btn-link i{
  color: #fff;
  margin-bottom: 0;
}

.sigma_service.style-9 .sigma_service-body .btn-link:hover{
  color: var(--thm-base);
}

.sigma_counter-wrapper.with-service{
  padding: 30px;
  margin-bottom: 30px;
}

.sigma_counter-wrapper.with-service .sigma_counter.style-3{
  margin-top: 12px;
  padding: 12px 0 0;
}

/* Style 10 */

.sigma_service-has-image:before{
 content: "";
 position: absolute;
 right: 0;
 top: 0;
 width: 38%;
 height: 100%;
 background-image: url("http://via.placeholder.com/730x640");
 background-size: cover;
 background-position: center;
}

.sigma_service-wrapper.style-10{
  padding: 70px 70px 20px;
  box-shadow: 0 0 40px 5px rgb(0 0 0 / 8%);
  background-color: #fff;
  margin-bottom: 0;
}

.sigma_service.style-10,
.sigma_service.style-12{
  border: 0;
  padding: 0;
  max-width: 450px;
  margin-bottom: 50px;
  text-align: left;
  display: flex;
}

.sigma_service.style-10:hover{
  background-color: transparent;
  transform: none;
}

.sigma_service.style-10 .sigma_service-thumb,
.sigma_service.style-12 .sigma_service-thumb{
  margin-bottom: 0;
  margin-right: 26px;
  text-align: center;
}

.sigma_service.style-10 .sigma_service-thumb i,
.sigma_service.style-12 .sigma_service-thumb i{
  font-size: 46px;
}

.sigma_service.style-10 .sigma_service-thumb .number{
  display: block;
  color: rgb(104 106 111 / 3%);
  font-size: 46px;
  font-weight: 400;
  margin-top: 12px;
  line-height: 1;
}

.sigma_service.style-10 .sigma_service-body{
  flex: 1;
}

.sigma_service.style-10 .sigma_service-body h5 a:hover,
.sigma_service.style-14 .sigma_service-body h5 a:hover,
.sigma_service.style-15 .sigma_service-body h5 a:hover{
  color: var(--thm-base-hover);
}

/* Style 11 */

.sigma_service.style-11,
.sigma_service.style-17{
  text-align: center;
  box-shadow: none;
  border-width: 2px;
}

.sigma_service.style-11:hover,
.sigma_service.style-17:hover{
  box-shadow: 0 0 20px 18px rgb(0 0 0 / 3%);
  transform: none;
}

.sigma_service.style-11 .sigma_service-body,
.sigma_service.style-17 .sigma_service-body{
  padding: 30px;
}

.sigma_service.style-11 .sigma_service-body .btn-link,
.sigma_service.style-14 .sigma_service-body .btn-link{
  justify-content: center;
  color: var(--thm-b-text);
  display: inline-flex;
}

.sigma_service.style-11 .sigma_service-body h5 a,
.sigma_service.style-11 .sigma_service-body .btn-link{
  position: relative;
}

.sigma_service.style-11 .sigma_service-body .btn-link:hover,
.sigma_service.style-14 .sigma_service-body .btn-link:hover{
  color: var(--thm-base);
}

.sigma_service.style-11 .sigma_service-body h5 a:before{
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: var(--thm-secondary);
  transition: 0.3s;
}

.sigma_service.style-11 .sigma_service-body h5 a:hover:before{
  width: 100%;
}

/* Style 12 */
.sigma_service-wrapper.style-12{
  box-shadow: 0 0 10px 10px rgb(0 0 0 / 3%);
  margin-bottom: 0;
}

.sigma_service.style-12{
  padding:60px;
  background-color: #fff;
  max-width: 100%;
  margin-bottom: 0;
  align-items: center;
}

.sigma_service.has-bg-primary.style-12{
  background-color: var(--thm-base);
}
.sigma_service.has-bg-secondary.style-12{
  background-color: var(--thm-secondary);
}

.sigma_service.has-bg-primary.style-12 .sigma_service-thumb i,
.sigma_service.has-bg-secondary.style-12 .sigma_service-thumb i,
.sigma_service.has-bg-primary.style-12 .sigma_service-body h5 a,
.sigma_service.has-bg-secondary.style-12 .sigma_service-body h5 a{
  color: #fff;
}

.sigma_service.has-bg-primary.style-12 .sigma_service-body p,
.sigma_service.has-bg-secondary.style-12 .sigma_service-body p{
  color: #fbf6f6;
}

.sigma_service.has-bg-secondary.style-12 .sigma_service-body .btn-link,
.sigma_service.has-bg-primary.style-12 .sigma_service-body .btn-link{
  color: #fbf6f6;
  border-bottom:1px solid #fbf6f6;
}

.sigma_service.style-12:hover,
.sigma_service.style-13:hover{
  background-color: #fff;
  transform: none;
}

.sigma_service.has-bg-primary.style-12:hover{
  background-color: var(--thm-base);
  transform: none;
}
.sigma_service.has-bg-secondary.style-12:hover{
  background-color: var(--thm-secondary);
  transform: none;
}

.sigma_service.style-12 .sigma_service-body .number,
.sigma_service.style-13 .sigma_service-body .number{
  display: block;
  -webkit-text-stroke: 1px var(--thm-base);
  -webkit-text-fill-color: #fff;
  font-size: 64px;
  font-weight: 400;
  margin-bottom: 15px;
  line-height: 1;
}

.sigma_service.has-bg-primary.style-12 .sigma_service-body .number{
  -webkit-text-stroke: 1px #fff;
  -webkit-text-fill-color: var(--thm-base);
}
.sigma_service.has-bg-secondary.style-12 .sigma_service-body .number{
  -webkit-text-stroke: 1px #fff;
  -webkit-text-fill-color: var(--thm-secondary);
}

.sigma_service.style-12 .sigma_service-body .btn-link{
   margin-top: 15px;
   color: var(--thm-b-text);
   border-bottom: 1px solid var(--thm-b-text);
   display: inline-flex;
}

.sigma_service.style-12 .sigma_service-body h5 a:hover,
.sigma_service.style-12 .sigma_service-body .btn-link:hover{
  color: var(--thm-base-hover);
  border-color: var(--thm-base-hover);
}

/* Style 13 */

.sigma_service.style-13{
  border: 0;
}

.sigma_service.style-13 .sigma_service-body{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sigma_service.style-13 .sigma_service-body i{
  font-size: 36px;
  margin-bottom: 0;
  color: var(--thm-b-text);
  opacity: 0.1;
}
.sigma_service.style-13 .sigma_service-body .number{
  font-size: 70px;
}
.sigma_service.style-13 .sigma_service-body .number.text-color{
  -webkit-text-stroke: 1px var(--thm-base-hover);
}
.sigma_service.style-13 .sigma_service-body .number.secondary-color{
  -webkit-text-stroke: 1px var(--thm-secondary);
}
.sigma_service.style-13 .sigma_service-footer{
  border-color: rgba(var(--thm-border-rgb), 0.3);
}

/* Style 14 */

.sigma_service.style-14{
  border: 0;
}

.sigma_service.style-14:hover,
.sigma_service.style-15:hover {
  box-shadow: 0 0 10px 10px rgb(0 0 0 / 3%);
  background-color: #fff;
  transform: none;
}

/* Style 15 */

.sigma_service.style-15{
  overflow: hidden;
  text-align: left;
}

.sigma_service.style-15 .number{
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 22px;
  font-weight: 400;
  line-height: 1;
  background-color: var(--thm-base-hue);
  color: var(--thm-base);
  width: 80px;
  height: 80px;
  border-bottom-left-radius: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sigma_service.style-15 .number.secondary-hue{
  background-color: rgba(var(--thm-secondary-rgb), 0.1);
  color: var(--thm-secondary);
}

/* Style 16 */

.sigma_service-sec.style-16.section{
  padding: 135px 0 70px;
}

.sigma_service.style-16,
.sigma_service.style-18{
  text-align: left;
  padding: 30px 20px 20px;
  margin-bottom: 65px;
}

.sigma_service.style-16:hover,
.sigma_service.style-18:hover{
  background-color: transparent;
  transform: none;
}

.sigma_service.style-16 .sigma_service-thumb,
.sigma_service.style-18 .sigma_service-thumb{
  width: 80px;
  height: 80px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 16px rgba(0,0,0,.06);
  margin: -70px 0 26px;
  background-color: #fff;
}

.sigma_service.style-16 .sigma_service-thumb i,
.sigma_service.style-18 .sigma_service-thumb i{
  font-size: 44px;
  color: var(--thm-secondary);
}

.sigma_service.style-16 .sigma_service-body .btn-link{
  font-size: 0;
  font-weight: 600;
  margin-top: 12px;
}
.sigma_service.style-16 .sigma_service-body .btn-link i{
  font-size: 26px;
  margin-left: 0;
}

.sigma_service.style-16:hover .sigma_service-body .btn-link{
  font-size: 16px;
}
.sigma_service.style-16:hover .sigma_service-body .btn-link i{
  margin-left: 12px;
}

/* Style 17 */

.sigma_service.style-17{
  text-align: left;
  border: 0;
  border-radius: 6px;
}

.sigma_service.style-17:hover{
  background-color: #fff;
}
.sigma_service.style-17 .sigma_service-thumb{
  overflow: hidden;
  border-radius: 6px 6px 0 0;
}

.sigma_service.style-17 .sigma_service-body{
  border: 2px solid var(--thm-border);
  border-top: 0;
  padding: 36px 30px 30px;
  transition: 0.3s;
  border-radius: 0 0 6px 6px;
}
.sigma_service.style-17:hover .sigma_service-body{
  border-color: transparent;
}

/* Style 18 */

.sigma_service-wrapper.style-18{
  padding: 70px 50px 40px;
  background-color: #fff;
  margin: 0;
  border-radius: 6px;
}
.sigma_service-wrapper.style-18.margin-negative{
  margin: -200px 0 100px;
}

.sigma_service-wrapper.style-18 .section-title h3{
  max-width: 690px;
}
.sigma_service.style-18{
  padding: 0;
  border: 0;
  display: flex;
  margin-bottom: 30px;
}

.sigma_service.style-18 .sigma_service-body{
  flex: 1;
}
.sigma_service.style-18 .sigma_service-thumb{
  margin: 0 20px 0 0;
}

/* Style 18 with bg */
.sigma_service.style-18.has-bg{
  background-color: #fff;
  padding: 30px 20px;
  border-radius: 6px;
  box-shadow: 0 3px 20px rgba(0,0,0,.03);
}

.sigma_service.style-18.has-bg .sigma_service-thumb{
  box-shadow: none;
  width: 60px;
  height: 60px;
}



/*-------------- Portfolio Sections --------------------------- */

/* Style 1 */
.sigma_portfolio {
  position: relative;
  margin-bottom: 30px;
  cursor: pointer;
}

.sigma_portfolio .sigma_portfolio-thumb {
  position: relative;
  overflow: hidden;
  transition: 0.3s;
}

.sigma_portfolio .sigma_portfolio-thumb img {
  width: 100%;
  transition: 0.3s;
}

.sigma_portfolio .sigma_portfolio-thumb:hover img {
  transform: scale(1.1);
}

.sigma_portfolio .sigma_portfolio-content {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 20px;
  transition: 0.3s;
}

.sigma_portfolio a.sigma_portfolio-category {
  color: var(--thm-base);
  font-weight: 500;
}

.sigma_portfolio .sigma_portfolio-categories a+a {
  margin-left: 10px;
}

.sigma_portfolio .sigma_portfolio-content h5 {
  margin-top: 8px;
  margin-bottom: 0;
}

.sigma_portfolio .sigma_portfolio-content h5 a {
  color: #fff;
}

.sigma_portfolio .sigma_portfolio-content h5 a:hover,
.sigma_portfolio a.sigma_portfolio-category:hover {
  color: var(--thm-base-hover);
}

.sigma_portfolio .sigma_portfolio-link {
  position: absolute;
  bottom: 10px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: var(--thm-base);
  font-size: 16px;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
}

.sigma_portfolio .sigma_portfolio-link:hover {
  background-color: var(--thm-base);
  color: #fff;
}

.sigma_portfolio .sigma_portfolio-thumb:hover .sigma_portfolio-link {
  opacity: 1;
  visibility: visible;
  bottom: 20px;
}

/* Style 2 */
.sigma_portfolio.style-2 .sigma_portfolio-content {
  position: relative;
  align-items: center;
  background-color: transparent;
  padding: 20px 0 0 0;
}

.sigma_portfolio.style-2 .sigma_portfolio-content h5 {
  margin-top: 0;
  margin-bottom: 8px;
}

.sigma_portfolio.style-2 .sigma_portfolio-content h5 a {
  color: var(--thm-secondary);
}

.sigma_portfolio.style-2 .sigma_portfolio-content h5 a:hover {
  color: var(--thm-base-hover);
}

.sigma_portfolio.style-2 .sigma_portfolio-thumb.has-max-width {
  max-width: 400px;
  margin: 0 auto;
}

/* Style 3 (slider version) */
.sigma_portfolio.style-3 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
}

.sigma_portfolio.style-3 .sigma_portfolio-content {
  flex: 1;
  position: relative;
  padding: 0;
  max-width: 500px;
  margin-left: 60px;
  background-color: transparent;
}

.sigma_portfolio.style-3 .sigma_portfolio-content p {
  margin: 0;
}

.sigma_portfolio.style-3 .sigma_portfolio-content h5 a {
  color: var(--thm-secondary);
}

.sigma_portfolio.style-3 .sigma_portfolio-content h5 a:hover {
  color: var(--thm-base-hover);
}

.sigma_portfolio.style-3 .sigma_portfolio-content .btn-link {
  margin-top: 20px;
}

.sigma_portfolio-slider .slick-prev,
.sigma_portfolio-slider .slick-next {
  background-color: var(--thm-base);
  color: #fff;
  position: absolute;
  right: 0;
  top: calc(50% - 30px);
  transform: translateY(-50%);
  opacity: 1;
  visibility: visible;
}

.sigma_portfolio-slider .slick-next {
  top: calc(50% + 30px);
}

.sigma_portfolio-slider .slick-prev:hover,
.sigma_portfolio-slider .slick-next:hover,
.sigma_portfolio-slider-2 .slick-prev:hover,
.sigma_portfolio-slider-2 .slick-next:hover {
  background-color: var(--thm-base-hover);
}

.sigma_portfolio-slider .slick-prev:before {
  content: "\f077";
}

.sigma_portfolio-slider .slick-next:before {
  content: "\f078";
}

.sigma_portfolio-slider .slick-dots {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  flex-direction: column;
}

.sigma_portfolio-slider .slick-dots li button,
.sigma_portfolio-slider-2 .slick-dots li button {
  background-color: var(--thm-border);
}

.sigma_portfolio-slider .slick-dots .slick-active button,
.sigma_portfolio-slider-2 .slick-dots .slick-active button {
  background-color: var(--thm-base);
}

.sigma_portfolio-slider .slick-dots li+li {
  margin-left: 0;
}

/* Style 4 (slider version) */
.sigma_portfolio.style-4 .sigma_portfolio-content {
  height: auto;
}

.sigma_portfolio-slider-2.slick-slider .slick-list {
  overflow: visible;
}

.sigma_portfolio-slider-2 .sigma_portfolio.style-4 {
  opacity: 0.7;
}

.sigma_portfolio-slider-2 .sigma_portfolio.style-4.slick-current {
  opacity: 1;
}

.sigma_portfolio.style-4 .sigma_portfolio-content {
  background-color: var(--thm-base);
}

.sigma_portfolio.style-4 a.sigma_portfolio-category {
  color: #fff;
}

.sigma_portfolio.style-4 .sigma_portfolio-content h5 a:hover,
.sigma_portfolio.style-4 a.sigma_portfolio-category:hover {
  color: var(--thm-secondary);
}

.sigma_portfolio.style-4 .sigma_portfolio-link:hover {
  background-color: var(--thm-base-hover);
}

/* Style 5 */
.sigma_portfolio.style-5 {
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
  background-color: #fff;
  border-radius: 0;
  overflow: hidden;
}

.sigma_portfolio.style-5 .sigma_portfolio-content {
  position: relative;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color: #fff;
  padding: 15px 20px;
}

.sigma_portfolio.style-5 .sigma_portfolio-content h5 a {
  color: var(--thm-secondary);
}

.sigma_portfolio.style-5 a.sigma_portfolio-category {
  color: var(--thm-b-text);
}

.sigma_portfolio.style-5 .sigma_portfolio-content h5 a:hover,
.sigma_portfolio.style-5 a.sigma_portfolio-category:hover {
  color: var(--thm-base-hover);
}

.sigma_portfolio.style-5 .sigma_portfolio-link {
  position: relative;
  bottom: 0;
  right: 0;
  background-color: var(--thm-base);
  color: #fff;
  opacity: 1;
  visibility: visible;
}

.sigma_portfolio.style-5 .sigma_portfolio-link:hover {
  background-color: var(--thm-base-hover);
}

/* Style 6 */
.sigma_portfolio.style-6 {
  margin-bottom: 0;
}

.sigma_portfolio.style-6 .sigma_portfolio-content {
  opacity: 0;
  visibility: hidden;
}

.sigma_portfolio.style-6:hover .sigma_portfolio-content {
  opacity: 1;
  visibility: visible;
}

/* Style 7 */
.sigma_portfolio.style-7 .sigma_portfolio-content {
  height: auto;
  padding: 12px 20px;
}

/* Isotope filter */
/* bordered filter items */
.sigma_isotope-filter-items {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 0 50px 0;
  padding: 0;
}

.sigma_isotope-filter-items .sigma_isotope-trigger {
  padding-bottom: 15px;
  min-width: 120px;
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  border-bottom: 1px solid var(--thm-border);
  cursor: pointer;
  transition: 0.3s;
}

.sigma_isotope-filter-items .sigma_isotope-trigger.active,
.sigma_isotope-filter-items .sigma_isotope-trigger:hover {
  color: var(--thm-base);
  border-color: var(--thm-base);
}

.sigma_isotope-filter-items .sigma_isotope-trigger+.sigma_isotope-trigger {
  margin-left: 40px;
}

/* background filter items */
.sigma_isotope-filter-items.has-background .sigma_isotope-trigger,
.sigma_isotope-filter-items.has-round-border .sigma_isotope-trigger {
  padding: 10px 20px;
  color: #fff;
  border: none;
  background-color: var(--thm-base);
}

.sigma_isotope-filter-items.has-background .sigma_isotope-trigger.active,
.sigma_isotope-filter-items.has-background .sigma_isotope-trigger:hover,
.sigma_isotope-filter-items.has-round-border .sigma_isotope-trigger.active,
.sigma_isotope-filter-items.has-round-border .sigma_isotope-trigger:hover {
  background-color: var(--thm-base-hover);
}

/* round border filter items */
.sigma_isotope-filter-items.has-round-border .sigma_isotope-trigger {
  border-radius: 35px;
}

/* Style 8 */

.sigma_portfolio.style-8 .sigma_portfolio-content,
.sigma_portfolio.style-9 .sigma_portfolio-content,
.sigma_portfolio.style-10 .sigma_portfolio-content{
  left: 50%;
  transform: translateX(-50%);
  width:calc(100% - 20px);
  height: auto;
  background-color: var(--thm-base);
  padding: 30px;
  opacity: 0;
  visibility: hidden;
}

.sigma_portfolio.style-8:hover .sigma_portfolio-content{
  opacity: 1;
  visibility: visible;
  bottom: 10px;
}

.sigma_portfolio.style-8 .sigma_portfolio-content h5,
.sigma_portfolio.style-9 .sigma_portfolio-content h5,
.sigma_portfolio.style-10 .sigma_portfolio-content h5{
  margin-bottom: 8px;
  margin-top: 0;
}

.sigma_portfolio.style-8 .sigma_portfolio-content p{
  margin-bottom: 0;
  color: #fff;
}

/* Style 9 */

.sigma_portfolio-wrapper.style-9{
  position: relative;
  margin-bottom: -250px;
}

.sigma_portfolio-slider-3 .slick-slide,
.sigma_portfolio-slider-4 .slick-slide,
.sigma_portfolio-slider-5 .slick-slide,
.sigma_portfolio-slider-6 .slick-slide{
  margin: 0 10px;
}

.sigma_portfolio-slider-3 .slick-list,
.sigma_portfolio-slider-4 .slick-list,
.sigma_portfolio-slider-5 .slick-list,
.sigma_portfolio-slider-6 .slick-list{
  margin: 0 -10px;
  overflow: visible;
}

.sigma_portfolio-wrapper.style-9 .sigma_custom-arrows i,
.sigma_portfolio-wrapper.style-13 .sigma_custom-arrows i{
  background-color: #fff;
  border-radius: 6px;
}

.sigma_portfolio-wrapper.style-9 .sigma_custom-arrows a{
  color: #fff;
  text-decoration: underline;
  margin-right: 20px;
}

.sigma_portfolio-wrapper.style-9 .sigma_custom-arrows a:hover{
  color: var(--thm-base);
}

.sigma_portfolio-wrapper.style-9 .sigma_custom-arrows .slick-prev:hover,
.sigma_portfolio-wrapper.style-9 .sigma_custom-arrows .slick-next:hover,
.sigma_portfolio-wrapper.style-13 .sigma_custom-arrows .slick-prev:hover,
.sigma_portfolio-wrapper.style-13 .sigma_custom-arrows .slick-next:hover{
  background-color: var(--thm-base-hover);
}

.sigma_portfolio.style-9 .sigma_portfolio-thumb,
.sigma_portfolio.style-10 .sigma_portfolio-thumb{
  overflow: visible;
}
.sigma_portfolio.style-9 .sigma_portfolio-thumb:hover img,
.sigma_portfolio.style-10 .sigma_portfolio-thumb:hover img{
  transform: none;
}

.sigma_portfolio.style-9 .sigma_portfolio-content,
.sigma_portfolio.style-10 .sigma_portfolio-content{
  background-color: #fff;
  width: calc(100% - 60px);
  border-bottom: 4px solid var(--thm-base);
  bottom: -60px;
}

.sigma_portfolio.style-9.slick-current .sigma_portfolio-content{
  opacity: 1;
  visibility: visible;
  bottom: -50px;
}

.sigma_portfolio.style-9 .sigma_portfolio-content h5 a,
.sigma_portfolio.style-10 .sigma_portfolio-content h5 a{
  color: var(--thm-secondary);
}

.sigma_portfolio.style-9 .sigma_portfolio-content h5 a:hover,
.sigma_portfolio.style-10 .sigma_portfolio-content h5 a:hover{
  color: var(--thm-base-hover);
}
.sigma_portfolio.style-9 .sigma_portfolio-content p,
.sigma_portfolio.style-10 .sigma_portfolio-content p{
  color: var(--thm-text);
  margin-bottom: 12px;
}

.sigma_portfolio.style-9 .sigma_portfolio-content .icon-box{
  font-size: 32px;
  color: var(--thm-secondary);
  display: block;
  margin-bottom: 20px;
}
.sigma_portfolio.style-9 .sigma_portfolio-content .btn-link{
  color: var(--thm-secondary);
}
.sigma_portfolio.style-9 .sigma_portfolio-content .btn-link:hover{
  color: var(--thm-base-hover);
}

.sigma_portfolio-slider-4 .slick-list{
  overflow: hidden;
}

/* Style 10 */

.sigma_portfolio.style-10{
  margin-bottom: 90px;
}

.sigma_portfolio.style-10 .sigma_portfolio-content p{
  margin-bottom: 0;
}

.sigma_portfolio.style-10 .sigma_portfolio-content{
  opacity: 1;
  visibility: visible;
}

/* Style 11 */

.sigma_portfolio.style-11 .sigma_portfolio-content{
  position: relative;
  background-color: transparent;
  padding: 20px 0 0;
}

.sigma_portfolio.style-11 .sigma_portfolio-thumb:before{
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--thm-secondary);
  opacity: 0;
  transition: 0.3s;
}

.sigma_portfolio.style-11 .sigma_portfolio-thumb:hover:before{
  opacity: 0.6;
}

.sigma_portfolio.style-11 .sigma_portfolio-thumb:hover img{
  transform: none;
}

.sigma_portfolio.style-11 .sigma_portfolio-link{
  left: 50%;
  right: auto;
  top: 52%;
  bottom: auto;
  transform: translate(-50%, -50%);
  background-color: transparent;
  color: #fff;
  font-size: 24px;
}

.sigma_portfolio.style-11 .sigma_portfolio-thumb:hover .sigma_portfolio-link{
  top: 50%;
}

.sigma_portfolio.style-11 .sigma_portfolio-content h5{
  margin-top: 0;
  margin-bottom: 8px;
}
.sigma_portfolio.style-11 .sigma_portfolio-content h5 a{
  color: var(--thm-secondary);
}

.sigma_portfolio.style-11 .sigma_portfolio-content h5 a:hover,
.sigma_portfolio.style-11 .sigma_portfolio-link:hover{
 color: var(--thm-base-hover);
}

/* Style 12 */

.sigma_portfolio.style-12{
  margin-bottom: 0;
}
.sigma_portfolio.style-12 .sigma_portfolio-content{
  justify-content: center;
  align-items: center;
  background-color: rgba(var(--thm-secondary-rgb), 0.4);
  opacity: 0;
  visibility: hidden;
}

.sigma_portfolio.style-12:hover .sigma_portfolio-content{
  opacity: 1;
  visibility: visible;
}
.sigma_portfolio.style-12 a.sigma_portfolio-category{
  color: #fff;
  font-weight: 400;
}
.sigma_portfolio.style-12 a.sigma_portfolio-category:hover{
  color: var(--thm-base-hover);
}

/* Style 13 */

.sigma_portfolio-wrapper.style-13{
  position: relative;
  margin-top: -280px;
  z-index: 1;
}

.sigma_portfolio.style-13 span{
  position: absolute;
  bottom: -30px;
  left: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: var(--thm-secondary);
  padding: 8px 26px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.03);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.sigma_portfolio.style-13.slick-current span{
  bottom: -20px;
  opacity: 1;
  visibility: visible;
}

.sigma_portfolio.style-13 .sigma_portfolio-content{
  background: linear-gradient(to bottom, transparent 57%,rgba(var(--thm-secondary-rgb), 0.2) 100%);
  padding: 20px 40px;
}

.sigma_portfolio.style-13.slick-current .sigma_portfolio-content{
  background: linear-gradient(to bottom, transparent 57%,rgba(var(--thm-secondary-rgb), 0.7) 100%);
}

.sigma_portfolio-wrapper.style-13 a.sigma_portfolio-category{
  color: rgb(255 255 255 / 72%);
  font-weight: 400;
}
.sigma_portfolio-wrapper.style-13 a.sigma_portfolio-category:hover{
  color: var(--thm-base-hover);
}

.sigma_portfolio.style-13 .sigma_portfolio-content h5{
  margin-bottom: 15px;
  transition: 0.3s;
}
.sigma_portfolio.style-13.slick-current .sigma_portfolio-content h5{
  margin-bottom: 30px;
}

.sigma_portfolio-wrapper.style-13 .sigma_portfolio-contact{
  margin-top: 70px;
  margin-bottom: 0;
}

.sigma_portfolio-wrapper.style-13 .sigma_portfolio-contact a{
  color: var(--thm-base);
  margin-left: 5px;
  text-decoration: underline;
}
.sigma_portfolio-wrapper.style-13 .sigma_portfolio-contact a:hover{
  color: var(--thm-base-hover);
}


/* Style 14 */

.sigma_portfolio.style-14 .sigma_portfolio-content-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  bottom: -60px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 40px);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.sigma_portfolio.style-14.slick-current .sigma_portfolio-content-wrapper{
  opacity: 1;
  visibility: visible;
}

.sigma_portfolio.style-14 .sigma_portfolio-link{
  position: relative;
  bottom: 0;
  right: 0;
  border-radius: 0;
  color: #fff;
  background-color: var(--thm-base);
  opacity: 1;
  visibility: visible;
}

.sigma_portfolio.style-14 .sigma_portfolio-link:hover{
  background-color: var(--thm-base-hover);
}

.sigma_portfolio.style-14  .sigma_portfolio-content{
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: var(--thm-secondary);
  padding: 20px;
}

.sigma_portfolio-wrapper.style-14 .sigma_custom-dots span{
  width: 3px;
  display: inline-flex;
  height: 16px;
  background-color: rgba(var(--thm-secondary-rgb), 0.1);
  transition: 0.3s;
  cursor: pointer;
}
.sigma_portfolio-wrapper.style-14 .sigma_custom-dots span:nth-child(2){
  height: 24px;
  background-color: var(--thm-base);
}
.sigma_portfolio-wrapper.style-14 .sigma_custom-dots span + span{
  margin-left: 2px;
}

.sigma_portfolio-wrapper.style-14 .sigma_custom-dots:hover span:first-child,
.sigma_portfolio-wrapper.style-14 .sigma_custom-dots:hover span:last-child{
  height: 24px;
}

.sigma_portfolio-wrapper.style-14 .sigma_custom-dots:hover span:nth-child(2){
  height: 16px;
}

/* Style 15 */

.sigma_portfolio.style-15  .sigma_portfolio-content{
  position: relative;
  background-color: transparent;
  padding: 65px 0 0;
  justify-content: start;
}
.sigma_portfolio.style-15 .sigma_portfolio-content p{
  border-top: 1px solid var(--thm-border);
  margin-top: 18px;
  margin-bottom: 0;
  padding-top: 18px;
}

.sigma_portfolio.style-15 .sigma_portfolio-thumb .sigma_btn{
  position: absolute;
  bottom: 20px;
  right: 20px;
  opacity: 0;
  visibility: hidden;
}

.sigma_portfolio.style-15.slick-current .sigma_portfolio-thumb .sigma_btn{
  opacity: 1;
  visibility: visible;
}
.sigma_portfolio-slider-7 .slick-slide,
.sigma_portfolio-slider-8 .slick-slide{
  margin: 0 30px;
}

.sigma_portfolio-slider-7 .slick-list,
.sigma_portfolio-slider-8 .slick-list{
  margin: 0 -30px;
  overflow: visible;
}

.sigma_portfolio-slider-8 .slick-list{
  overflow: hidden;
}

.sigma_portfolio-wrapper.style-15 .sigma_custom-arrows{
  position: relative;
  bottom: 110px;
  left: 0;
}
.sigma_portfolio-slider-7 .slick-list .slick-current + .slick-slide .row{
  flex-direction: row-reverse;
}

/*-------------- Team Sections --------------------------- */
/* style 1 */
.sigma_team {
  position: relative;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
  margin-bottom: 30px;
  text-align: center;
  padding: 30px;
  border-radius: 0;
  overflow: hidden;
  transition: 0.3s;
}

.sigma_team .sigma_team-thumb {
  position: relative;
  overflow: hidden;
}

.sigma_team .sigma_team-thumb.has-width {
  width: 120px;
  height: 120px;
  margin: 0 auto;
}

.sigma_team .sigma_team-thumb img {
  transition: 0.3s;
  width: 100%;
}

.sigma_team .sigma_team-thumb:hover img {
  transform: scale(1.1);
}

.sigma_team .sigma_team-thumb.has-width:hover img {
  transform: none;
}

.sigma_team .sigma_team-body {
  margin-top: 20px;
  transition: 0.3s;
}

.sigma_team .sigma_team-body h5 {
  margin-bottom: 0;
}

.sigma_team .sigma_team-categories,
.sigma_team .sigma_team-body p {
  margin-top: 10px;
  margin-bottom: 0;
}

.sigma_team .sigma_team-categories a+a {
  margin-left: 10px;
}

.sigma_team .sigma_team-categories a {
  color: var(--thm-secondary);
  font-weight: 500;
}

.sigma_team .sigma_team-categories a:hover {
  color: var(--thm-base-hover);
}

.sigma_social-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

.sigma_social-icons li+li {
  margin-left: 10px;
}

.sigma_social-icons li a {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--thm-base);
  background-color: #f4f5f8;
  font-size: 15px;
  border-radius: 50%;
  transition: 0.3s;
}

.sigma_social-icons li a:hover {
  background-color: var(--thm-base);
  color: #fff;
}

.sigma_social-icons.has-border li a{
  background-color: transparent;
  border: 1px solid var(--thm-border);
  color: var(--thm-base);
}

.sigma_social-icons.has-border li a:hover{
  background-color: var(--thm-base);
  border-color: var(--thm-base);
  color: #fff;
}

.sigma_team .sigma_social-icons {
  margin-top: 18px;
}

.sigma_team .sigma_team-info span{
  display: flex;
  align-items: center;
  font-size: 16px;
}

.sigma_team .sigma_team-info span + span{
  margin-top: 10px;
}

.sigma_team .sigma_team-info span i{
  margin-right: 10px;
  color: var(--thm-base);
}

.sigma_team .sigma_team-controls a{
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #fff;
  color: var(--thm-base);
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}
.sigma_team .sigma_team-controls a + a{
  margin-top: 10px;
}
.sigma_team .sigma_team-controls a:hover{
  background-color: var(--thm-base);
  color: #fff;
}

/* style 2 */
.sigma_team.style-2,
.sigma_team.style-4,
.sigma_team.style-5,
.sigma_team.style-6,
.sigma_team.style-8,
.sigma_team.style-9,
.sigma_team.style-10 {
  box-shadow: none;
  text-align: left;
  padding: 0;
}

.sigma_team.style-2 .sigma_social-icons,
.sigma_team.style-8 .sigma_social-icons {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  right: 0;
  top: 0;
  margin-top: 0;
  opacity: 0;
  visibility: hidden;
}

.sigma_team.style-2 .sigma_social-icons li a,
.sigma_team.style-8 .sigma_social-icons li a {
  color: #fff;
  background-color: var(--thm-base);
  position: relative;
  bottom: -10px;
  opacity: 0;
  visibility: hidden;
}

.sigma_team.style-2 .sigma_social-icons li a:hover,
.sigma_team.style-4 .sigma_social-icons li a:hover,
.sigma_team.style-6 .sigma_social-icons li a:hover,
.sigma_team.style-8 .sigma_social-icons li a:hover,
.sigma_team.style-9 .sigma_social-icons li a:hover {
  color: var(--thm-secondary);
  background-color: #fff;
}

.sigma_team.style-2 .sigma_team-thumb:hover .sigma_social-icons,
.sigma_team.style-8 .sigma_team-thumb:hover .sigma_social-icons {
  opacity: 1;
  visibility: visible;
}

.sigma_team.style-2 .sigma_team-thumb:hover .sigma_social-icons li a,
.sigma_team.style-8 .sigma_team-thumb:hover .sigma_social-icons li a {
  opacity: 1;
  visibility: visible;
  bottom: 10px;
}

.sigma_team.style-2 .sigma_team-thumb:hover .sigma_social-icons li:nth-child(2) a,
.sigma_team.style-8 .sigma_team-thumb:hover .sigma_social-icons li:nth-child(2) a {
  transition: bottom 0.8s, background-color .3s, color .3s;
}

.sigma_team.style-2 .sigma_team-thumb:hover .sigma_social-icons li:nth-child(3) a,
.sigma_team.style-8 .sigma_team-thumb:hover .sigma_social-icons li:nth-child(3) a {
  transition: bottom 1.2s, background-color .3s, color .3s;
}

/* style 3 */
.sigma_team.style-3,
.sigma_team.style-5 {
  text-align: left;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
}

.sigma_team.style-3 .sigma_team-body,
.sigma_team.style-5 .sigma_team-body {
  flex: 1;
  margin: 0;
  padding: 0 20px;
}

.sigma_team.style-3 .sigma_social-icons,
.sigma_team.style-4 .sigma_social-icons,
.sigma_team.style-5 .sigma_social-icons,
.sigma_team.style-6 .sigma_social-icons,
.sigma_team.style-9 .sigma_social-icons,
.sigma_team.style-10 .sigma_social-icons {
  justify-content: flex-start;
}

/* style 4 */
.sigma_team.style-4 .sigma_team-body,
.sigma_team.style-6 .sigma_team-body,
.sigma_team.style-9 .sigma_team-body {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.4);
  margin-top: 0;
  padding: 30px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.sigma_team.style-4 .sigma_team-thumb:hover .sigma_team-body,
.sigma_team.style-6 .sigma_team-thumb:hover .sigma_team-body {
  opacity: 1;
  visibility: visible;
}

.sigma_team.style-4 .sigma_team-body .sigma_team-title,
.sigma_team.style-6 .sigma_team-body .sigma_team-title,
.sigma_team.style-4 .sigma_social-icons,
.sigma_team.style-6 .sigma_social-icons,
.sigma_team.style-6 .sigma_team-body p {
  position: relative;
  transition: 0.3s;
}

.sigma_team.style-4 .sigma_team-body .sigma_team-title,
.sigma_team.style-6 .sigma_team-body .sigma_team-title {
  top: -5px;
}

.sigma_team.style-4 .sigma_team-thumb:hover .sigma_team-title,
.sigma_team.style-6 .sigma_team-thumb:hover .sigma_team-title {
  top: 5px;
}

.sigma_team.style-4 .sigma_team-body h5 a,
.sigma_team.style-9 .sigma_team-body h5 a,
.sigma_team.style-6 .sigma_team-body h5 a,
.sigma_team.style-4 .sigma_team-categories a,
.sigma_team.style-9 .sigma_team-categories a,
.sigma_team.style-6 .sigma_team-categories a {
  color: #fff;
}

.sigma_team.style-4 .sigma_team-body h5 a:hover,
.sigma_team.style-4 .sigma_team-categories a:hover,
.sigma_team.style-9 .sigma_team-body h5 a:hover,
.sigma_team.style-9 .sigma_team-categories a:hover,
.sigma_team.style-6 .sigma_team-body h5 a:hover,
.sigma_team.style-6 .sigma_team-categories a:hover {
  color: var(--thm-base-hover);
}

.sigma_team.style-4 .sigma_social-icons,
.sigma_team.style-6 .sigma_social-icons {
  bottom: -5px;
}

.sigma_team.style-4 .sigma_team-thumb:hover .sigma_social-icons,
.sigma_team.style-6 .sigma_team-thumb:hover .sigma_social-icons {
  bottom: 5px;
}

.sigma_team.style-4 .sigma_social-icons li a,
.sigma_team.style-9 .sigma_social-icons li a,
.sigma_team.style-6 .sigma_social-icons li a {
  color: #fff;
  background-color: var(--thm-base);
}

/* style 5 */
.sigma_team.style-5 .sigma_team-thumb img {
  width: auto;
}

.sigma_team.style-5 .sigma_team-thumb:hover img {
  transform: none;
}

/* style 6 */
.sigma_team.style-6 .sigma_team-body {
  background-color: rgba(var(--thm-base-rgb), 0.4);
  justify-content: center;
}

.sigma_team.style-6 .sigma_team-body p {
  color: #fff;
}

/* style 7 */
.sigma_team.style-7,
.sigma_team.style-11,
.sigma_team.style-12,
.sigma_team.style-14,
.sigma_team.style-15,
.sigma_team.style-16,
.sigma_team.style-17 {
  box-shadow: none;
  padding: 0;
  border: 1px solid var(--thm-border);
}

.sigma_team.style-7 .sigma_team-body,
.sigma_team.style-11 .sigma_team-body,
.sigma_team.style-14 .sigma_team-body,
.sigma_team.style-15 .sigma_team-body,
.sigma_team.style-16 .sigma_team-body,
.sigma_team.style-17 .sigma_team-body {
  margin-top: 0;
  padding: 30px;
}

/* style 8 */
.sigma_team.style-8 .sigma_team-body {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 20px;
  background-color: var(--thm-base);
}

.sigma_team.style-8 .sigma_team-body h5 a {
  color: #fff;
}

.sigma_team.style-8 .sigma_team-categories {
  margin-top: 0;
}

.sigma_team.style-8 .sigma_team-categories a {
  color: #fff;
  position: relative;
  padding-left: 22px;
  margin-left: 10px;
}

.sigma_team.style-8 .sigma_team-body h5 a:hover,
.sigma_team.style-8 .sigma_team-categories a:hover {
  color: var(--thm-secondary);
}

.sigma_team.style-8 .sigma_team-categories a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  height: 2px;
  background-color: #fff;
}

/* style 9 */
.sigma_team.style-9 .sigma_team-body {
  opacity: 1;
  visibility: visible;
}

/* style 10 */
.sigma_team.style-10 {
  padding: 0 30px;
  margin-bottom: 0;
}

.sigma_team.style-10 .sigma_team-body {
  margin-top: 0;
}

.nav-tabs-nulled.nav-tabs {
  border: 0;
}

.nav-tabs-nulled .nav-link.active {
  border: 0;
}

.nav-tabs-nulled .nav-link .sigma_team {
  border: 1px solid transparent;
}

.nav-tabs-nulled .nav-link.active .sigma_team {
  border: 1px solid var(--thm-base);
}

.nav-tabs-nulled .nav-link {
  border: 0;
  border-radius: 0;
  padding: 0;
}

.nav-tabs-nulled .nav-item {
  margin: 0;
}

/* style 11 */

.sigma_team.style-11,
.sigma_team.style-15{
  border: 0;
  overflow: visible;
}

.sigma_team.style-11 .sigma_team-body,
.sigma_team.style-15 .sigma_team-body{
    position: relative;
    margin-top: -50px;
    width: calc(100% - 20px);
    background-color: #fff;
    box-shadow: 0 3px 20px rgba(0,0,0,.06);
    text-align: left;
    padding: 20px;
}

.sigma_team-slider .slick-slide{
   margin: 0 15px;
}
.sigma_team-slider .slick-list{
   margin: 0 -15px;
   min-height: 320px;
}

.sigma_team-slider .slick-slide img{
  display: inline-block;
}

/* style 12 */

.sigma_team.style-12,
 .sigma_team.style-13{
  border: 0;
  text-align: left;
}

.sigma_team.style-12 .sigma_team-body,
.sigma_team.style-13 .sigma_team-body {
  margin-top: 0;
  padding: 20px 0 0;
  position: relative;
}
.sigma_team.style-12 .sigma_team-categories{
  margin-bottom: 6px;
  margin-top: 0;
}
.sigma_team.style-12 .sigma_team-categories a,
.sigma_team.style-13 .sigma_team-categories a{
  color: var(--thm-base);
}
.sigma_team.style-12 .sigma_team-categories a:hover,
.sigma_team.style-13 .sigma_team-categories a:hover{
  color: var(--thm-base-hover);
}
.sigma_team.style-12 .sigma_team-body p{
  margin-bottom: 0;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid var(--thm-border);
}

.sigma_team.style-12 .sigma_social-icons,
.sigma_team.style-13 .sigma_social-icons{
  flex-direction: column;
  position: absolute;
  bottom: calc(100% - 22px);
  right: 20px;
  margin-top: 0;
}
.sigma_team.style-12 .sigma_social-icons li+li,
.sigma_team.style-13 .sigma_social-icons li+li{
  margin-left: 0;
  margin-top: 10px;
}

.sigma_team.style-12 .sigma_social-icons li a,
.sigma_team.style-13 .sigma_social-icons li a{
  opacity: 0;
  visibility: hidden;
}

.sigma_team.style-12 .sigma_social-icons.visible li a,
.sigma_team.style-13 .sigma_social-icons.visible li a{
  opacity: 1;
  visibility: visible;
}

.sigma_team.style-12 .sigma_social-icons.visible li:nth-child(2) a,
.sigma_team.style-13 .sigma_social-icons.visible li:nth-child(2) a{
  transition: 2s, background-color .3s, color .3s;
}
.sigma_team.style-12 .sigma_social-icons.visible li:nth-child(3) a,
.sigma_team.style-13 .sigma_social-icons.visible li:nth-child(3) a{
  transition: 3s, background-color .3s, color .3s;
}

.sigma_team.style-12 .sigma_social-icons li a,
.sigma_team.style-13 .sigma_social-icons li a{
  background-color: #fff;
  box-shadow: 0 3px 20px rgba(0,0,0,.06);
  color: var(--thm-secondary);
}
.sigma_team.style-12 .sigma_social-icons li a:hover,
.sigma_team.style-13 .sigma_social-icons li a:hover{
  background-color: var(--thm-base);
  color: #fff;
}

.sigma_team.style-12 .sigma_social-icons li a.trigger-team-socials i,
.sigma_team.style-13 .sigma_social-icons li a.trigger-team-socials i{
  transform: rotate(0deg);
  transition: 0.3s;
}
.sigma_team.style-12 .sigma_social-icons li a.trigger-team-socials:hover i,
.sigma_team.style-13 .sigma_social-icons li a.trigger-team-socials:hover i{
  transform: rotate(45deg);
}
.sigma_team.style-12 .sigma_social-icons li a.trigger-team-socials,
.sigma_team.style-13 .sigma_social-icons li a.trigger-team-socials{
  opacity: 1;
  visibility: visible;
}

/* --- style-13 --- */

.sigma_team.style-13{
  border: 2px solid var(--thm-border);
  padding: 30px;
  background-color: #fff;
  box-shadow: 0 0 40px 5px rgb(0 0 0 / 6%);
}
.sigma_team.style-13:hover{
  box-shadow: none;
}
.sigma_team.style-13 .sigma_team-categories{
  margin-top: 6px;
  margin-bottom: 0;
}
.sigma_team.style-13 .sigma_team-body{
  padding-top: 30px;
}

.sigma_team.style-13 .sigma_social-icons li a{
  border-radius: 0;
}
.sigma_team.style-13 .sigma_social-icons{
  right: 0;
}



.sigma_team.style-13.absolute-icons{
  padding: 50px 50px 35px;
  border: 0;
  text-align: center;
  overflow: visible;
}

.sigma_team.style-13.absolute-icons:hover{
  box-shadow: 0 0 40px 5px rgb(0 0 0 / 6%);
}

.sigma_team.style-13.absolute-icons .sigma_team-thumb:hover img{
  transform: none;
}

.sigma_team.style-13.absolute-icons .sigma_social-icons{
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--thm-base);
  width: 160px;
  height: 40px;
  border-radius: 30px;
  flex-direction: row;
  opacity: 0;
  visibility: hidden;
}

.sigma_team.style-13.absolute-icons:hover .sigma_social-icons{
  opacity: 1;
  visibility: visible;
  bottom: -15px;
}

.sigma_team.style-13.absolute-icons .sigma_social-icons li+li{
  margin-left: 15px;
  margin-top: 0;
}

.sigma_team.style-13.absolute-icons .sigma_social-icons li a{
  width: auto;
  height: auto;
  color: #fff;
  font-size: 14px;
  background-color: transparent;
  opacity: 1;
  visibility: visible;
  box-shadow: none;
}
.sigma_team.style-13.absolute-icons .sigma_social-icons li a:hover{
  color: var(--thm-base-hover);
}

.sigma_about.style-17 .sigma_about-content.with-team p{
  color: var(--thm-b-text);
}
.sigma_about.style-17 .sigma_about-content.with-team .sigma_progress{
  margin-top: 70px;
}

/* --- style-14 --- */

.sigma_team-sec.style-14{
  z-index: 1;
}

.sigma_team-sec.style-14:before{
 content: "";
 position: absolute;
 left: 0;
 top: 0;
 width: 100%;
 height: calc(100% - 270px);
 background: url('../img/home-1/1920x1280.jpg');
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
 z-index: -2;
}
.sigma_team-sec.style-14:after{
 content: "";
 position: absolute;
 left: 0;
 top: 0;
 width: 100%;
 height: calc(100% - 270px);
 background-color: rgba(var(--thm-secondary-rgb), 0.7);
 z-index: -1;
}

.sigma_team.style-14,
.sigma_team.style-16,
.sigma_team.style-17{
  background-color: #fff;
  text-align: left;
  border: 0;
  box-shadow: 0 0 40px 5px rgb(0 0 0 / 3%);
  border-radius: 6px;
}

.sigma_team.style-14 .sigma_team-categories{
  margin: 5px 0;
}
.sigma_team-slider-2 .slick-slide{
   margin-left: 10px;
   margin-right: 10px;
}
.sigma_team-slider-2 .slick-list{
   margin: 0 -10px;
}

/* --- style-15 --- */

.sigma_team.style-15 .sigma_team-body{
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 40px);
  border-radius: 6px;
}

.sigma_team.style-15 .sigma_team-thumb{
  border-radius: 6px;
}

.sigma_team.style-15 .sigma_social-icons li a{
  width: 40px;
  height: 40px;
  color: var(--thm-secondary);
}

.sigma_team.style-15 .sigma_social-icons li a:hover{
  background-color: var(--thm-secondary);
  color: #fff;
}

.sigma_team.style-15:hover .sigma_team-body{
  margin-top: -60px;
}

/* --- style-16 --- */

.sigma_team.style-16,
.sigma_team.style-17{
  background-color: #fff;
  border: 1px solid var(--thm-border);
  box-shadow: none;
  border-top: 0;
}

.sigma_team.style-16 .sigma_team-controls{
  position: absolute;
  top: 15px;
  left: 15px;
}

.sigma_team.style-16 .sigma_team-categories{
  margin-top: 0;
}
.sigma_team.style-16 .sigma_rating{
  margin: 10px 0;
}
.sigma_team.style-16 .sigma_team-info{
  margin-top: 10px;
}

.sigma_team.style-16 .sigma_team-body{
  position: relative;
  background-color: #fff;
}

.sigma_team.style-16 .sigma_team-body .sigma_btn{
  width: calc(100% - 30px);
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
}

.sigma_team.style-16:hover .sigma_team-body .sigma_btn{
  opacity: 1;
  visibility: visible;
  bottom: 30px;
}

.sigma_team.style-16:hover .sigma_team-body{
  padding-bottom: 100px;
  margin-top: -70px;
}


/* --- style-17 --- */

.sigma_team.style-17{
  border-top: 1px solid var(--thm-border);
}

.sigma_team.style-17 .sigma_team-body,
.sigma_team.style-17 .sigma_team-footer,
.sigma_team.style-17 .sigma_team-thumb{
  height: 100%;
}

.sigma_team.style-17 .sigma_team-thumb img{
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

.sigma_team.style-17 .sigma_team-body{
  border-right: 1px solid var(--thm-border);
}

.sigma_team.style-17 .sigma_team-footer{
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sigma_team.style-17 .sigma_rating{
  margin: 80px 0 0;
}
.sigma_team.style-17 .sigma_team-controls a{
  border-radius: 6px;
  border: 2px solid var(--thm-border);
  width: 58px;
  height: 58px;
  font-size: 20px;
}
.sigma_team.style-17 .sigma_team-controls a:hover{
  border-color: var(--thm-base);
}


/*-------------- Testimonial Sections --------------------------- */
/* style 1 */
.sigma_testimonial {
  position: relative;
  border: 1px solid var(--thm-border);
  padding: 30px;
  text-align: center;
  border-radius: 0;
  margin-bottom: 30px;
}

.sigma_testimonial .sigma_testimonial-thumb {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sigma_testimonial .sigma_testimonial-thumb img {
  transition: 0.3s;
  border-radius: 50%;
}

.sigma_testimonial .sigma_testimonial-body .sigma_testimonial-icon {
  display: inline-block;
  color: var(--thm-secondary);
  opacity: .1;
  font-size: 30px;
  margin-top: 30px;
}

.sigma_testimonial .sigma_testimonial-category {
  color: var(--thm-base);
  font-weight: 400;
  text-transform: uppercase;
}

.sigma_testimonial .sigma_author-block h5 {
  margin-bottom: 0;
}

.sigma_testimonial .sigma_testimonial-body p {
  margin: 18px 0 15px;
}

.sigma_testimonial .sigma_testimonial-body .sigma_rating {
  margin: 18px 0 0;
  justify-content: center;
}

/* style 2 */
.sigma_testimonial.style-2,
.sigma_testimonial.style-3,
.sigma_testimonial.style-10,
.sigma_testimonial.style-11 {
  border: 0;
  text-align: left;
  padding: 0;
}

.sigma_testimonial.style-2 .sigma_testimonial-body .sigma_testimonial-icon,
.sigma_testimonial.style-3 .sigma_testimonial-body .sigma_testimonial-icon,
.sigma_testimonial.style-10 .sigma_testimonial-body .sigma_testimonial-icon,
.sigma_testimonial.style-11 .sigma_testimonial-body .sigma_testimonial-icon {
  margin-top: 0;
  font-size: 40px;
}

.sigma_testimonial.style-2 .sigma_testimonial-body p,
.sigma_testimonial.style-10 .sigma_testimonial-body p,
.sigma_testimonial.style-11 .sigma_testimonial-body p {
  margin: 18px 0 20px;
}

.sigma_testimonial.style-2 .sigma_testimonial-thumb,
.sigma_testimonial.style-4 .sigma_testimonial-thumb,
.sigma_testimonial.style-9 .sigma_testimonial-thumb,
.sigma_testimonial.style-12 .sigma_testimonial-thumb {
  margin-right: 10px;
}

/* style 3 */
.sigma_testimonial.style-3 .sigma_testimonial-body {
  padding: 30px;
  border: 1px solid var(--thm-border);
  margin-bottom: 30px;
  position: relative;
}

.sigma_testimonial.style-3 .sigma_testimonial-body:after,
.sigma_testimonial.style-12:after {
  content: "";
  position: absolute;
  width: 20px;
  background-color: #fff;
  height: 20px;
  border-left: 1px solid var(--thm-border);
  border-top: 1px solid var(--thm-border);
  transform: rotate(225deg) skew(10deg, 10deg);
  right: 24px;
  bottom: -11px;
}

.sigma_testimonial.style-3 .sigma_testimonial-body p,
.sigma_testimonial.style-4 .sigma_testimonial-body p {
  margin: 18px 0 0;
}

.sigma_testimonial.style-3 .sigma_testimonial-body .sigma_testimonial-icon {
  font-size: 30px;
}

.sigma_testimonial.style-3 .sigma_testimonial-thumb {
  margin-left: 10px;
}

.sigma_testimonial.style-3 .sigma_author-block {
  text-align: right;
}

/* style 4 */
.sigma_testimonial.style-4 {
  text-align: left;
}

.sigma_testimonial.style-4 .sigma_testimonial-inner {
  display: flex;
  justify-content: space-between;
}

.sigma_testimonial.style-4 .sigma_testimonial-body .sigma_testimonial-icon,
.sigma_testimonial.style-7 .sigma_testimonial-body .sigma_testimonial-icon {
  margin-top: 0;
}

/* style 5 */
.sigma_testimonial.style-5,
.sigma_testimonial.style-8 {
  border: 0;
  padding: 0 30px;
}

.sigma_testimonial.style-5 .sigma_author-block,
.sigma_testimonial.style-8 .sigma_author-block {
  margin-top: 28px;
}

.sigma_testimonial.style-5 .sigma_testimonial-body p,
.sigma_testimonial.style-8 .sigma_testimonial-body p {
  margin-bottom: 0;
}

.sigma_testimonial.style-5 .sigma_testimonial-body .sigma_rating {
  margin-top: 25px;
}

.sigma_testimonial-slider .slick-prev,
.sigma_testimonial-slider .slick-next,
.sigma_testimonial-slider-2 .slick-prev,
.sigma_testimonial-slider-2 .slick-next,
.sigma_testimonial-slider-3 .slick-prev,
.sigma_testimonial-slider-3 .slick-next {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--thm-base);
  color: #fff;
}

.sigma_testimonial-slider .slick-prev:hover,
.sigma_testimonial-slider .slick-next:hover,
.sigma_testimonial-slider-2 .slick-prev:hover,
.sigma_testimonial-slider-2 .slick-next:hover,
.sigma_testimonial-slider-3 .slick-prev:hover,
.sigma_testimonial-slider-3 .slick-next:hover {
  background-color: var(--thm-base-hover);
}

.sigma_testimonial-slider .slick-next,
.sigma_testimonial-slider-2 .slick-next,
.sigma_testimonial-slider-3 .slick-next {
  right: 10px;
  left: auto;
}

.sigma_testimonial-slider:hover .slick-prev,
.sigma_testimonial-slider-2:hover .slick-prev,
.sigma_testimonial-slider-3:hover .slick-prev {
  left: 20px;
  opacity: 1;
  visibility: visible;
}

.sigma_testimonial-slider:hover .slick-next,
.sigma_testimonial-slider-2:hover .slick-next,
.sigma_testimonial-slider-3:hover .slick-next {
  right: 20px;
  opacity: 1;
  visibility: visible;
}

.sigma_testimonial-slider .slick-dots,
.sigma_testimonial-slider-3 .slick-dots {
  margin-top: 20px;
}

.sigma_testimonial-slider .slick-dots li button,
.sigma_testimonial-slider-3 .slick-dots li button {
  background-color: var(--thm-border);
}

.sigma_testimonial-slider .slick-dots .slick-active button,
.sigma_testimonial-slider-3 .slick-dots .slick-active button {
  background-color: var(--thm-base);
}

/* style 6 */
.sigma_testimonial.style-6,
.sigma_testimonial.style-14{
  border: 0;
  padding: 0;
  display: flex;
  align-items: center;
  text-align: left;
}

.sigma_testimonial.style-6 .sigma_testimonial-body,
.sigma_testimonial.style-14 .sigma_testimonial-body {
  flex: 1;
  max-width: 500px;
  margin-left: 80px;
  position: relative;
}

.sigma_testimonial.style-6 .sigma_testimonial-body:before,
.sigma_testimonial.style-9 .sigma_testimonial-body:before,
.sigma_testimonial.style-12 .sigma_testimonial-body:before {
  content: "\f10e";
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  position: absolute;
  right: 20px;
  bottom: 25px;
  font-size: 80px;
  color: var(--thm-secondary);
  opacity: 0.1;
  z-index: -1;
}

.sigma_testimonial.style-6 .sigma_testimonial-thumb,
.sigma_testimonial.style-14 .sigma_testimonial-thumb {
  justify-content: flex-start;
}

.sigma_testimonial.style-6 .sigma_testimonial-body .sigma_rating,
.sigma_testimonial.style-7 .sigma_testimonial-body .sigma_rating,
.sigma_testimonial.style-9 .sigma_testimonial-body .sigma_rating,
.sigma_testimonial.style-12 .sigma_testimonial-body .sigma_rating,
.sigma_testimonial.style-14 .sigma_testimonial-body .sigma_rating {
  margin: 0;
  justify-content: flex-start;
}

.sigma_testimonial.style-6 .sigma_testimonial-body p,
.sigma_testimonial.style-7 .sigma_testimonial-body p,
.sigma_testimonial.style-14 .sigma_testimonial-body p {
  margin: 18px 0 12px;
}

/* style 7 */
.sigma_testimonial.style-7 {
  border: 0;
  padding: 0;
  text-align: left;
  margin-bottom: 0;
}

.sigma_testimonial.style-7 .sigma_testimonial-thumb img {
  border-radius: 0;
  width: 100%;
  height: 100%;
}

.sigma_testimonial.style-7 .sigma_testimonial-body {
  padding: 30px;
}

/* style 8 */
.slider-nav-thumbnails {
  max-width: 370px;
  margin: 20px auto 0;
}

.slider-nav-thumbnails .slick-slide {
  cursor: pointer;
  opacity: 0.4;
  transition: 0.3s;
}

.slider-nav-thumbnails .slick-slide:hover {
  opacity: 0.8;
}

.slider-nav-thumbnails .slick-slide.slick-current {
  opacity: 1;
}

.slider-nav-thumbnails .sigma_testimonial-thumb {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-nav-thumbnails .sigma_testimonial-thumb img {
  border-radius: 50%;
}

/* style 9 */
.sigma_testimonial.style-9,
.sigma_testimonial.style-12 {
  border: 0;
  padding: 0;
  text-align: left;
}

.sigma_testimonial.style-9 .sigma_testimonial-body p {
  margin: 0 0 18px;
}

.sigma_testimonial.style-9 .sigma_testimonial-category,
.sigma_testimonial.style-12 .sigma_testimonial-category {
  font-size: 16px;
  color: var(--thm-b-text);
}

.sigma_testimonial.style-9 .sigma_testimonial-body .sigma_rating {
  margin-top: 8px;
}

/* style 10 */
.sigma_testimonial.style-10 {
  text-align: center;
}

/* style 11 */

.sigma_testimonial.style-11{
  padding: 40px 50px;
  background-color: #fff;
  box-shadow: 0 10px 30px rgba(0,0,0,.05);
}

.sigma_testimonial.style-11 .sigma_testimonial-thumb{
  margin-right: 30px;
  overflow: visible;
}

.sigma_testimonial.style-11 .sigma_testimonial-thumb img {
    border: 5px solid #fff;
    box-shadow: 0 10px 30px rgba(0,0,0,.05);
}

.sigma_testimonial.style-11 .sigma_testimonial-body .sigma_testimonial-icon{
   margin-right: 20px;
   margin-top: 0;
   color: var(--thm-base);
   opacity: 1;
   font-size: 40px;
}

/* style 12 */

.sigma_testimonial.style-12{
  padding: 40px;
  background-color: #fff;
}

.sigma_testimonial.style-12:before{
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 120px;
  height: 120px;
  border-bottom-left-radius: 150px;
  background-color: var(--thm-base);
}
.sigma_testimonial.style-12:after{
  height: 30px;
  border-left: 1px solid #fff;
  border-top: 1px solid #fff;
  transform: rotate(240deg) skew(14deg, 25deg);
  right: auto;
  left: 30px;
  bottom: -15px;
}
.sigma_testimonial.style-12 .sigma_testimonial-body:before{
  color: #fff;
  font-size: 35px;
  bottom: auto;
  top: 20px;
  right: 30px;
  z-index: 1;
  opacity: 1;
}
.sigma_testimonial.style-12 .sigma_testimonial-body p {
  margin: 18px 0 0;
}
.sigma_testimonial.style-12 .sigma_testimonial-category{
  position: relative;
  padding-left: 15px;
  margin-left: 15px;
}
.sigma_testimonial.style-12 .sigma_testimonial-category:before{
  content: "";
  position: absolute;
  left: -6px;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  height: 2px;
  background-color: var(--thm-secondary);
}
.sigma_testimonial.style-12 .sigma_testimonial-body .sigma_rating{
  margin-bottom: 8px;
}
.sigma_testimonial-slider-4 .slick-list{
  margin: 0 -15px;
}
.sigma_testimonial-slider-4 .sigma_testimonial.style-12.slick-slide{
  margin: 0 15px;
}
.sigma_testimonial-slider-4 .slick-list .slick-track{
  padding-bottom: 22px;
}

/* style 13 */

.sigma_testimonial.style-13{
  box-shadow: 0 3px 20px rgba(0,0,0,.03);
   border: 0;
   padding: 50px 30px 42px;
}
.sigma_testimonial.style-13 .sigma_testimonial-icon{
  position: absolute;
  top: 50px;
  right: -8px;
  background-color: var(--thm-base);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sigma_testimonial.style-13 .sigma_testimonial-thumb{
  display: inline-block;
  overflow: visible;
}
.sigma_testimonial.style-13 .sigma_testimonial-body p {
    margin: 30px 0 24px;
    color: var(--thm-base);
}
.sigma_testimonial.style-13 .sigma_testimonial-category{
  color: var(--thm-b-text);
  text-transform: capitalize;
}

.sigma_testimonial.style-13 .sigma_author-info{
  display: flex;
  align-items: center;
  position: relative;
}

.sigma_testimonial.style-13 .sigma_author-info:before{
  content: "";
  position: absolute;
  left: -31px;
  top: 0;
  border-radius: 6px;
  width: 6px;
  height: 100%;
  background-color: var(--thm-base);
}

/* style 14 */

.sigma_testimonial.style-14{
  border: 2px solid var(--thm-border);
  padding: 30px;
  border-radius: 6px;
}

.sigma_testimonial.style-14 .sigma_testimonial-body{
  margin-left: 30px;
  max-width: 100%;
}

.sigma_testimonial.style-14 .sigma_testimonial-date{
  opacity: 0.5;
}

.sigma_testimonial.style-14 .sigma_testimonial-body p{
  margin-bottom: 0;
}


/*-------------- FAQ & Accordions Sections --------------------------- */
/* Accordion style 1 */
.sigma_accordion .card {
  border-left: 0;
  border-right: 0;
  border-color: var(--thm-border);
  overflow: visible;
}

.sigma_accordion .accordion>.card:first-of-type {
  border-top: 0;
}

.sigma_accordion .card .card-header {
  margin: 0;
  background-color: #fff;
  border-bottom: 0;
  padding: 0;
}

.sigma_accordion .card .card-header .btn {
  font-weight: 400;
  text-transform: uppercase;
}

.sigma_accordion .card .card-header .btn {
  position: relative;
  padding: 0;
  color: var(--thm-secondary);
  text-decoration: none;
  border: 0;
  background-color: transparent;
  outline: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  line-height: 1.4;
  transition: 0.3s;
  padding: 25px 0;
  box-shadow: none;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 600;
  overflow: visible;
}

[class*="btn-style-"] .sigma_accordion .card .card-header .btn::before {
  display: none;
}

[class*="btn-style-"] .sigma_accordion .card .card-header .btn i {
  color: var(--thm-secondary);
}

.sigma_accordion .accordion>.card:first-of-type .card-header .btn {
  padding-top: 0;
}

.sigma_accordion .card .card-header .btn i {
  font-size: 16px;
  transition: 0.3s;
  margin-left: 0;
}

.sigma_accordion .card .card-header .btn i.icon-left {
  margin-right: 30px;
}

.sigma_accordion .card .card-header .btn:hover {
  color: var(--thm-base-hover);
}

.sigma_accordion .card-body {
  padding: 0 0 25px;
}

.sigma_accordion .card .btn[aria-expanded="true"] i {
  transform: rotate(180deg);
}

/* Accordion style 2 */
.sigma_accordion.style-2 .card {
  border: 0;
  padding-left: 20px;
}

.sigma_accordion.style-2 .card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background-color: var(--thm-border);
}

.sigma_accordion.style-2 .card .btn {
  padding: 0;
  border: 0;
}

.sigma_accordion.style-2 .card .card-body {
  padding: 10px 0 0;
}

.sigma_accordion.style-2 .card+.card {
  margin-top: 20px;
}

.sigma_accordion.style-2 .card .btn[aria-expanded="true"] {
  color: var(--thm-base);
}

.sigma_accordion.style-2 .card .card-header .btn {
  position: relative;
}

.sigma_accordion.style-2 .card .card-header .btn::after {
  content: '';
  position: absolute;
  top: 0;
  left: -20px;
  width: 3px;
  height: 0;
  transition: .3s;
  background-color: var(--thm-base);
}

.sigma_accordion.style-2 .card .btn[aria-expanded="true"]::after {
  height: 100%;
}

/* Accordion style 3 */
.sigma_accordion.style-3 .card,
.sigma_accordion.style-3 .accordion>.card:not(:first-of-type):not(:last-of-type),
.sigma_accordion.style-3 .accordion>.card:first-of-type,
.sigma_accordion.style-4 .card,
.sigma_accordion.style-4 .accordion>.card:not(:first-of-type):not(:last-of-type),
.sigma_accordion.style-4 .accordion>.card:first-of-type,
.sigma_accordion.style-6 .card,
.sigma_accordion.style-6 .accordion>.card:not(:first-of-type):not(:last-of-type),
.sigma_accordion.style-6 .accordion>.card:first-of-type {
  border: 0;
  box-shadow: 0 0 40px 3px rgba(0, 0, 0, .04);
  border-radius: 6px;
  overflow: hidden;
}

.sigma_accordion.style-3 .accordion .card+.card,
.sigma_accordion.style-5 .accordion .card+.card,
.sigma_accordion.style-6 .accordion .card+.card,
.sigma_accordion.style-12 .accordion .card+.card {
  margin-top: 30px;
}

.sigma_accordion.style-3 .card .card-header .btn,
.sigma_accordion.style-3 .accordion>.card:first-of-type .card-header .btn,
.sigma_accordion.style-4 .card .card-header .btn,
.sigma_accordion.style-4 .accordion>.card:first-of-type .card-header .btn {
  padding: 25px;
}

.sigma_accordion.style-3 .card .card-header .btn {
  justify-content: flex-start;
}

.sigma_accordion.style-3 .card .btn[aria-expanded="true"] i,
.sigma_accordion.style-4 .card .btn[aria-expanded="true"] i,
.sigma_accordion.style-6 .card .btn[aria-expanded="true"] i {
  transform: rotate(90deg);
}

.sigma_accordion.style-3 .card .btn[aria-expanded="true"],
.sigma_accordion.style-4 .card .btn[aria-expanded="true"] {
  background-color: var(--thm-base);
  color: #fff;
}

.sigma_accordion.style-3 .card .btn[aria-expanded="true"] i,
.sigma_accordion.style-4 .card .btn[aria-expanded="true"] i {
  color: #fff;
}

.sigma_accordion.style-4 a.btn-link {
  display: block;
  margin-top: 15px;
}

/* Accordion style 4 */
.sigma_accordion.style-4 .card-body {
  padding: 25px;
}

.sigma_accordion.style-4 .accordion .card {
  margin-bottom: 30px;
}

/* Accordion style 5 */
.sigma_accordion.style-5 .card,
.sigma_accordion.style-12 .card {
  border: 0;
}

.sigma_accordion.style-5.has-border {
  border-top: 1px solid var(--thm-border);
  padding-top: 30px;
  margin-top: 30px;
}

.sigma_accordion.style-5 .card .card-header .btn:before,
.sigma_accordion.style-6 .card .card-header .btn:before {
  content: "\f059";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: var(--thm-b-text);
  font-size: 20px;
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
}

.sigma_accordion.style-5 .card-body{
  padding: 30px 0 0;
}

.sigma_accordion.style-5 .card .card-header .btn {
  padding: 0;
}

.sigma_accordion.style-5 .card .btn[aria-expanded="true"] i {
  transform: rotate(45deg);
}

/* Accordion style 6 */
.sigma_accordion.style-6+.sigma_accordion.style-6 {
  margin-top: 40px;
}

.sigma_accordion.style-6 .accordion {
  margin-top: 25px;
}

.sigma_accordion.style-6 .card .card-header .btn,
.sigma_accordion.style-6 .accordion>.card:first-of-type .card-header .btn {
  padding: 25px;
}

.sigma_accordion.style-6 .card .card-header .btn:before {
  left: 25px;
}

.sigma_accordion.style-6 .card-body {
  padding: 0 25px 25px;
}

/* Faq style 7 */
.sigma_faq {
  border-bottom: 1px solid var(--thm-border);
  padding: 30px 0;
}

.sigma_faq:first-of-type {
  padding-top: 0;
}

.sigma_faq p {
  margin-bottom: 0;
}

/* Faq style 8 */
.sigma_faq.style-8,
.sigma_faq.style-9,
.sigma_faq.style-10 {
  border: 0;
  padding: 0;
  display: flex;
  margin-bottom: 30px;
}

.sigma_faq.style-8 i,
.sigma_faq.style-9 i {
  line-height: 1.8;
  width: 40px;
  height: 40px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--thm-base);
  border-radius: 50%;
  margin-right: 20px;
  color: #fff;
}

.sigma_faq.style-8 .sigma_faq-body,
.sigma_faq.style-9 .sigma_faq-body {
  flex: 1;
}

.sigma_faq.style-8 h5,
.sigma_faq.style-9 h5,
.sigma_faq.style-10 h5 {
  margin-bottom: 15px;
  position: relative;
}

/* Faq style 9 */
.sigma_faq.style-9 {
  padding: 25px;
  border: 1px solid var(--thm-border);
  border-radius: 6px;
  transition: .3s;
}

.sigma_faq.style-9:hover {
  border-color: transparent;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, .03);
}

/* Faq style 10 */
.sigma_faq.style-10 .sigma_faq-flex-box {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.sigma_faq.style-10 .sigma_faq-flex-box h5,
.sigma_faq.style-10 .sigma_faq-flex-box p {
  flex: 1;
}

.sigma_faq.style-10 .icon-box {
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--thm-secondary);
  color: #fff;
  border-radius: 6px;
  font-size: 28px;
  font-weight: 600;
  margin-right: 25px;
}

.sigma_faq.style-10 .icon-box.has-shadow {
  background-color: #fff;
  color: var(--thm-b-text);
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.04);
}

/* Faq style 11 */
.sigma_faq.style-11 {
  border: 1px solid var(--thm-border);
  padding: 30px;
  margin: 70px 0 0;
}

.sigma_faq.style-11 .sigma_faq-body p {
  margin: 0;
}

.sigma_faq.style-11 .sigma_faq-number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border: 1px solid var(--thm-border);
  border-radius: 50%;
  text-align: center;
  color: var(--thm-base);
  font-size: 14px;
  font-weight: 700;
  margin: -60px 0 20px;
  background: #fff;
  transition: .3s;
}

.sigma_faq.style-11:hover .sigma_faq-number {
  background-color: var(--thm-base);
  border-color: var(--thm-base);
  color: #fff;
}

/* Faq style 12 */
.sigma_accordion.style-12 .accordion>.card:first-of-type .card-header .btn{
  padding-top: 20px;
}
.sigma_accordion.style-12 .card .card-header .btn {
  padding: 20px 30px;
}

.sigma_accordion.style-12 .card .card-header .btn i,
.sigma_accordion.style-13 .card .card-header .btn:after{
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--thm-base);
  color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 18px;
}

.sigma_accordion.style-12 .card.has-secondary .card-header .btn i{
  background-color: var(--thm-secondary);
}

.sigma_accordion.style-12 .card .btn[aria-expanded="true"]{
  box-shadow: 0 10px 180px rgb(0 0 0 / 4%);
}

.sigma_accordion.style-12 .card .collapse.show{
  box-shadow: 0 10px 24px rgb(0 0 0 / 4%);
}
.sigma_accordion.style-12 .card .btn[aria-expanded="true"] i{
  transform: none;
}

.sigma_accordion.style-12 .card-body {
  padding: 0 30px 20px;
}

/* Faq style 13 */

.sigma_accordion.style-13 .accordion{
  border: 2px solid var(--thm-border);
  border-radius: 6px;
}

.sigma_accordion.style-13 .card .card-header .btn{
  position: relative;
}

.sigma_accordion.style-13 .card .card-header .btn:after{
  content: "\f067";
  font-family: "Font Awesome 5 Pro";
  background-color: rgba(var(--thm-base-rgb), 0.1);
  color: var(--thm-base);
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 6px;
}
.sigma_accordion.style-13 .card .btn[aria-expanded="true"]:after{
  content: "\f068";
}

.sigma_accordion.style-13 .card{
  border: 0;
}
.sigma_accordion.style-13 .card + .card{
  border-top: 2px solid var(--thm-border);
}

.sigma_accordion.style-13 .card-body{
  padding: 20px 30px 20px;
  border-top: 2px solid var(--thm-border);
}

.sigma_accordion.style-13 .accordion>.card:first-of-type .card-header .btn{
  padding-top: 12px;
}
.sigma_accordion.style-13 .card .card-header .btn{
  padding: 12px 30px;
}

.sigma_accordion.style-13 .sigma_tab-item.style-4 .nav-tabs .nav-link{
  padding: 10px 20px;
  color: var(--thm-secondary);
  background-color: transparent;
  justify-content: space-between;
}

.sigma_accordion.style-13 .sigma_tab-item.style-4 .nav-tabs .nav-link i{
  color: var(--thm-secondary);
  font-size: 14px;
}

.sigma_accordion.style-13 .sigma_tab-item.style-4 .nav-tabs .nav-link.active,
.sigma_accordion.style-13 .sigma_tab-item.style-4 .nav-tabs .nav-link:hover{
  background-color: var(--thm-base);
  box-shadow: none;
  color: #fff;
}

.sigma_accordion.style-13 .sigma_tab-item.style-4 .nav-tabs .nav-link.active i,
.sigma_accordion.style-13 .sigma_tab-item.style-4 .nav-tabs .nav-link:hover i{
  color: #fff;
}

.sigma_accordion.style-13 .sigma_tab-item.style-4 .nav-tabs .nav-item{
  margin-bottom: 0;
}
.sigma_accordion.style-13 .sigma_tab-item.style-4 .nav-tabs .nav-item + .nav-item{
  margin-top: 18px;
}


/*-------------- Tab Sections --------------------------- */
/* Tab items style 1 */
.sigma_tab-item .nav-tabs {
  border: none;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;
}

.sigma_tab-item .nav-tabs .nav-item {
  margin-bottom: 30px;
  position: relative;
}

.sigma_tab-item .nav-tabs li+li {
  margin-left: 30px;
}

.sigma_tab-item .nav-tabs .nav-link {
  color: var(--thm-b-text);
  border: 1px solid var(--thm-border);
  font-weight: 500;
  border-radius: 6px;
  padding: 10px 35px;
  display: flex;
  align-items: center;
  transition: 0.3s;
  position: relative;
}

.sigma_tab-item .nav-tabs .nav-link i {
  font-size: 22px;
  margin-right: 12px;
  transition: 0.3s;
}

.sigma_tab-item .nav-tabs .nav-link.active,
.sigma_tab-item .nav-tabs .nav-link:hover,
.sigma_tab-item.style-10 .nav-tabs .nav-link.active,
.sigma_tab-item.style-10 .nav-tabs .nav-link:hover,
.sigma_tab-item.style-12 .nav-tabs .nav-link.active,
.sigma_tab-item.style-12 .nav-tabs .nav-link:hover {
  color: #fff;
  background-color: var(--thm-base);
  border-color: var(--thm-base);
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.04);
}

/* Tab items style 2 */
.sigma_tab-item.style-2 .nav-tabs .nav-link,
.sigma_tab-item.style-8 .nav-tabs .nav-link {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  border-color: transparent;
}

.sigma_tab-item.style-2 .nav-tabs .nav-link.active,
.sigma_tab-item.style-2 .nav-tabs .nav-link:hover,
.sigma_tab-item.style-8 .nav-tabs .nav-link.active,
.sigma_tab-item.style-8 .nav-tabs .nav-link:hover {
  color: var(--thm-base);
  background-color: transparent;
  box-shadow: none;
  border-color: var(--thm-base);
  opacity: 1;
}

/* Tab items style 3 */
.sigma_tab-item.style-3 .nav-tabs .nav-link,
.sigma_tab-item.style-4 .nav-tabs .nav-link,
.sigma_tab-item.style-6 .nav-tabs .nav-link {
  background-color: #FCF7FF;
}

.sigma_tab-item.style-3 .nav-tabs .nav-item:first-of-type .nav-link {
  border-radius: 6px 0 0 6px;
}

.sigma_tab-item.style-3 .nav-tabs .nav-item+.nav-item .nav-link,
.sigma_tab-item.style-6 .nav-tabs .nav-item+.nav-item .nav-link {
  border-radius: 0;
}

.sigma_tab-item.style-3 .nav-tabs .nav-item:last-of-type .nav-link {
  border-radius: 0 6px 6px 0;
}

.sigma_tab-item.style-3 .nav-tabs li+li,
.sigma_tab-item.style-6 .nav-tabs li+li {
  margin-left: 2px;
}

.sigma_tab-item.style-3 .nav-tabs .nav-link.active,
.sigma_tab-item.style-3 .nav-tabs .nav-link:hover,
.sigma_tab-item.style-4 .nav-tabs .nav-link.active,
.sigma_tab-item.style-4 .nav-tabs .nav-link:hover,
.sigma_tab-item.style-13 .nav-tabs .nav-link.active,
.sigma_tab-item.style-13 .nav-tabs .nav-link:hover {
  color: #fff;
  background-color: var(--thm-base);
  box-shadow: none;
}

/* Tab items style 4 */
.sigma_tab-item.style-4 .nav-tabs {
  flex-direction: column;
}

.sigma_tab-item.style-4 .nav-tabs li+li {
  margin-left: 0;
}

.sigma_tab-item.style-4 .nav-tabs .nav-link.active,
.sigma_tab-item.style-4 .nav-tabs .nav-link:hover {
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.04);
}

/* Tab items style 5 */
.sigma_tab-item.style-5 .nav-tabs,
.sigma_tab-item.style-6 .nav-tabs {
  justify-content: flex-start;
}

.sigma_tab-item.style-5 .nav-tabs .nav-link {
  border-color: transparent;
}

/* Tab items style 6 */
.sigma_tab-item.style-6 .nav-tabs .nav-link {
  border-width: 5px 0 0 0;
}

.sigma_tab-item.style-6 .nav-tabs .nav-item:first-of-type .nav-link {
  border-radius: 6px 0 0 0;
}

.sigma_tab-item.style-6 .nav-tabs .nav-item:last-of-type .nav-link {
  border-radius: 0;
}

.sigma_tab-item.style-6 .nav-tabs .nav-link.active,
.sigma_tab-item.style-6 .nav-tabs .nav-item:first-of-type .nav-link.active,
.sigma_tab-item.style-6 .nav-tabs .nav-item:last-of-type .nav-link.active,
.sigma_tab-item.style-6 .nav-tabs .nav-link:hover,
.sigma_tab-item.style-6 .nav-tabs .nav-item:first-of-type .nav-link:hover,
.sigma_tab-item.style-6 .nav-tabs .nav-item:last-of-type .nav-link:hover {
  box-shadow: none;
  background-color: #fff;
  color: var(--thm-base);
  border-radius: 0 0 0 0;
}

/* Tab items style 7 */
.sigma_tab-item.style-7 .nav-tabs .nav-link,
.sigma_tab-item.style-13 .nav-tabs .nav-link {
  width: 180px;
  flex-direction: column;
  padding: 35px;
}

.sigma_tab-item.style-7 .nav-tabs .nav-link:before,
.sigma_tab-item.style-13 .nav-tabs .nav-link:before {
  content: "";
  position: absolute;
  width: 10px;
  background-color: transparent;
  height: 10px;
  border-top: solid 15px transparent;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
  left: 50%;
  transform: translateX(-50%);
  bottom: -16px;
  transition: 0.3s;
}

.sigma_tab-item.style-7 .nav-tabs .nav-link i,
.sigma_tab-item.style-13 .nav-tabs .nav-link i {
  font-size: 26px;
  margin-bottom: 0px;
  margin-right: 0;
}

.sigma_tab-item.style-7 .nav-tabs .nav-link i {
  margin-bottom: 15px;
}

.sigma_tab-item.style-7 .nav-tabs .nav-link.active:before,
.sigma_tab-item.style-7 .nav-tabs .nav-link:hover:before,
.sigma_tab-item.style-13 .nav-tabs .nav-link.active:before,
.sigma_tab-item.style-13 .nav-tabs .nav-link:hover:before {
  border-top-color: var(--thm-base);
}

/* Tab items style 8 */
.sigma_tab-item.style-8 .nav-tabs,
.sigma_tab-item.style-9 .nav-tabs {
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.04);
  margin-bottom: 50px;
}

.sigma_tab-item.style-8 .nav-tabs .nav-item {
  margin-bottom: 0;
}

.sigma_tab-item.style-8 .nav-tabs .nav-link {
  padding: 28px 35px;
  border-bottom-width: 2px;
}

/* Tab items style 9 */
.sigma_tab-item.style-9 .nav-tabs {
  padding: 30px 30px 0;
}

.sigma_tab-item.style-9 .nav-tabs .nav-link {
  border: 0;
  min-width: 120px;
  justify-content: center;
}

.sigma_tab-item.style-9 .nav-tabs .nav-link i {
  color: var(--thm-b-text);
  margin-right: 15px;
}

.sigma_tab-item.style-9 .nav-tabs .nav-link.active i,
.sigma_tab-item.style-9 .nav-tabs .nav-link:hover i {
  color: #fff;
}

/* Tab items style 10 */
.sigma_tab-item.style-10 .nav-tabs {
  margin-bottom: 50px;
  justify-content: space-evenly;
}

.sigma_tab-item.style-10 .nav-tabs:before {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  background-color: var(--thm-border);
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: -1;
}

.sigma_tab-item.style-10 .nav-tabs .nav-item {
  margin-bottom: 0;
}

.sigma_tab-item.style-10 .nav-tabs .nav-link {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  padding: 0;
  margin: 0 auto;
  outline: none;
  background-color: #FCF7FF;
  justify-content: center;
}

/* Tab items style 11 */
.sigma_tab-item.style-11 .nav-tabs {
  justify-content: start;
}

.sigma_tab-item.style-11 .nav-tabs .nav-link {
  flex-direction: column;
  border: 0;
  padding: 0;
  box-shadow: none;
}

.sigma_tab-item.style-11 .nav-tabs .nav-link i {
  margin-right: 0;
  margin-bottom: 10px;
  font-size: 45px;
}

.sigma_tab-item.style-11 .nav-tabs .nav-link.active,
.sigma_tab-item.style-11 .nav-tabs .nav-link:hover {
  color: var(--thm-secondary);
  background-color: transparent;
}

.sigma_tab-item.style-11 .nav-tabs .nav-link:hover i,
.sigma_tab-item.style-11 .nav-tabs .nav-link.active i {
  color: var(--thm-base);
}

.sigma_tab-item.style-11 .nav-tabs li+li {
  margin-left: 60px;
}

/* Tab items style 12 */
.sigma_tab-item.style-12 .nav-tabs {
  flex-direction: column;
}

.sigma_tab-item.style-12 .nav-tabs li {
  margin-bottom: 0;
}

.sigma_tab-item.style-12 .nav-tabs li+li {
  margin-left: 0;
  margin-top: 30px;
}

.sigma_tab-item.style-12 .nav-tabs .nav-link {
  flex-direction: column;
  padding: 0;
  border-radius: 0;
  width: 100%;
  height: 146px;
  border: 0;
  line-height: 1;
  justify-content: center;
  text-transform: uppercase;
  background-color: #FCF7FF;
}

.sigma_tab-item.style-12 .nav-tabs .nav-link i {
  margin-right: 0;
  margin-bottom: 20px;
  font-size: 34px;
}

.sigma_tab-item.style-12 .nav-tabs .nav-link.active,
.sigma_tab-item.style-12 .nav-tabs .nav-link:hover {
  box-shadow: none;
}

/* Tab items style 13 */
.sigma_tab-item.style-13 .nav-tabs .nav-item {
  line-height: 1.4;
}

.sigma_tab-item.style-13 .nav-tabs .nav-link {
  width: 160px;
  padding: 34px;
  text-align: center;
  border-radius: 0;
  border: 0;
  background-color: var(--thm-base-hue);
}

.sigma_tab-item.style-13 .nav-tabs .nav-link i {
  color: var(--thm-base);
  font-size: 36px;
  margin-bottom: 15px;
}

.sigma_tab-item.style-13 .nav-tabs .nav-link.active i,
.sigma_tab-item.style-13 .nav-tabs .nav-link:hover i {
  color: #fff;
}

.sigma_tab-item.style-13 .nav-tabs .nav-link:before {
  bottom: -15px;
  border-left: solid 18px transparent;
  border-right: solid 18px transparent;
}

.sigma_tab-content .connect-us {
  display: flex;
  align-items: center;
  border-top: 1px solid var(--thm-border);
  margin-top: 40px;
  padding-top: 40px
}

.sigma_tab-content .connect-us i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  background-color: var(--thm-base);
  color: #fff;
  font-size: 32px;
  margin-right: 20px;
}

.sigma_tab-content .connect-us span {
  display: block;
  color: var(--thm-base);
  font-weight: 600;
  font-size: 28px;
  line-height: 1.2;
}

/*-------------- Contact Form --------------------------- */
/* style 1 */
.sigma_form.has-shadow {
  position: relative;
  padding: 50px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
}

.sigma_form .sigma_form-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sigma_form button.btn-block+button.btn-block {
  margin-top: 15px;
}

.sigma_form-seperator {
  margin: 20px 0;
  text-align: center;
  width: 100%;
}

.sigma_form-seperator span {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.sigma_form-seperator span:before {
  content: '';
  flex: 1;
  background-color: var(--thm-border);
  height: 1px;
  margin-right: 10px;
}

.sigma_form-seperator span:after {
  content: '';
  flex: 1;
  background-color: var(--thm-border);
  height: 1px;
  margin-left: 10px;
}

.sigma_contact-map {
  width: 100%;
  height: 600px;
  position: relative;
}
.widget .sigma_contact-map{
  height: 300px;
}

.sigma_contact-map iframe {
  border: 0;
  width: 100%;
  height: 100%;
}

.sigma_contact-wrapper .sigma_contact-blocks{
  padding: 50px 30px;
  border: 2px solid var(--thm-border);
  border-top: 0;
  border-radius: 6px;
}

.sigma_contact-wrapper .sigma_contact-map{
  height: 350px;
}

/* contact block */

.sigma_contact-block+.sigma_contact-block {
  margin-top: 20px;
}

.sigma_contact-block p {
  margin-bottom: 5px;
}

/* contact block style 2 */

.sigma_contact-block.style-2{
  display: flex;
  align-items: center;
}

.sigma_contact-block.style-2 .icon,
.sigma_contact-block.style-3 .icon{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: var(--thm-base);
  margin-right: 15px;
  font-size: 18px;
}

.sigma_contact-block.style-2 p {
  margin-bottom: 0;
}

/* contact block style 3 */

.sigma_contact-block.style-3{
  display: flex;
  align-items: flex-start;
}

.sigma_contact-block.style-3 .icon{
  color: var(--thm-base);
  background-color: transparent;
  font-size: 30px;
  margin-right: 10px;
}

/* style 2 */
.sigma_form.style-2 .sigma_buttons button+button {
  margin-left: 15px;
}

/* style 3 */
.sigma_form.style-3 {
  max-width: 400px;
  margin: 0 auto;
}

.sigma_form-image {
  height: 100vh;
}

.sigma_form-image img {
  width: 100%;
  height: 100%;
}

.sigma_contact-map.has-full-height {
  height: 100%;
}

/* style 4 */
.sigma_form.style-4 {
  position: absolute;
  left: 50px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.sigma_contact-with-bg{
  position: relative;
}
.sigma_contact-with-bg:after{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("http://via.placeholder.com/634x785");
  background-position: center;
  background-size: cover;
  width: 33%;
  height: 100%;
  z-index: -1;
}

.sigma_contact.extra-padding{
  padding-bottom: 150px;
}
.sigma_contact-map.is-absolute{
  position: relative;
  margin-top: -150px;
  z-index: 1;
}

.sigma_contact-map.is-absolute iframe{
  background-color: #fff;
  padding: 10px;
  height: 400px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.03);
}

/* style 5 */
.sigma_form.style-5{
  padding: 60px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.03);
  position: relative;
}

.sigma_form.style-5 .sigma_contact-map{
  height: 100%;
}


/* style 6 */

.sigma_contact-image.style-6{
  position: absolute;
  left: 15px;
  top: -70px;
  width: calc(100% - 660px);
  z-index: 1;
  border-radius: 6px;
  overflow: hidden;
}
.sigma_contact-image.style-6 img{
  width: 100%;
}

.sigma_form.style-6{
  background-color: var(--thm-base);
  padding: 60px 60px 60px 160px;
  border-radius: 6px;
}

.sigma_form.style-6 .form-group i{
  color: #fff;
}

.sigma_form.style-6 input,
.sigma_form.style-6 textarea{
  background-color: transparent;
  border: 2px solid rgba(255,255,255,.4);
  border-radius: 8px;
  color: #fff;
}
.sigma_form.style-6 input::placeholder,
.sigma_form.style-6 textarea::placeholder{
  color: #fff;
}


/* style 7 */

.sigma_form.style-7{
  padding: 50px;
  background-color: #fff;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.03);
}

.sigma_form.style-7 input,
.sigma_form.style-7 textarea,
.sigma_form.style-7 select{
  background-color: transparent;
}

.sigma_form.style-7 .form-block{
  margin-bottom: 30px;
}

.sigma_form.style-7 form .form-block:nth-child(2){
  margin-bottom: 50px;
}

.sigma_form.style-7 .payment-card-wrapper .card-image{
  margin-left: 30px;
}

.sigma_form.style-7 .payment-card-wrapper input{
  max-width: 300px;
}

/*-------------- Clients --------------------------- */

/* style 1 */

.sigma_clients{
  position: relative;
  margin-bottom: 30px;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sigma_clients img{
  width: 100%;
  transition: 0.3s;
}
.sigma_clients-bg.dark-overlay:before{
  background-color: rgba(0, 0, 0, .8);
}

/* style 2 */

.sigma_clients-wrapper.style-2{
  border-bottom: 1px solid var(--thm-border);
}
.sigma_clients.style-2{
  padding: 50px;
  border-right: 1px solid var(--thm-border);
  margin-bottom: 0;
}

/* style 3 */

.sigma_clients-wrapper.style-3{
  padding: 50px 50px 20px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.03);
  background-color: #fff;
}

.sigma_clients.style-3{
  border: 1px solid var(--thm-border);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
.sigma_clients.style-3 img{
  width: auto;
}

/*-------------- CTA --------------------------- */
/* style 1 */
.sigma_cta {
  position: relative;
  text-align: center;
  overflow: hidden;
}

.sigma_cta-buttons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.sigma_cta-buttons a+a,
.sigma_cta-buttons button+button {
  margin-left: 20px;
}

.sigma_cta-buttons .play-btn {
  display: flex;
  align-items: center;
  padding: 10px 25px;
  color: #fff;
  background-color: var(--thm-secondary);
  font-weight: 700;
  transition: 0.3s;
}

.sigma_cta-buttons .play-btn p,
.sigma_cta.style-3 .sigma_cta-title p {
  margin: 0;
}

.sigma_cta-buttons .play-btn:hover {
  background-color: var(--thm-secondary-hover);
}

.sigma_cta-buttons .play-btn i {
  display: inline-block;
  font-size: 30px;
  margin-right: 10px;
}

.sigma_cta-buttons .play-btn span {
  font-weight: 400;
  font-size: 14px;
  display: block;
  line-height: 1;
}

.sigma_cta .sigma_cta-icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 6px;
  background-color: var(--thm-base-hue);
  color: var(--thm-base);
  font-size: 30px;
  margin: 0 auto 30px;
}

/* style 2 */
.sigma_cta.style-2 {
  background-color: var(--thm-base);
  padding: 50px 50px 55px;
  z-index: 1;
}

.sigma_cta.style-2 .shape-2,
.sigma_cta.style-2 .shape-1 {
  content: '';
  position: absolute;
  top: -150px;
  left: -150px;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background-color: #fff;
  opacity: .1;
  z-index: -1;
}

.sigma_cta.style-2 .shape-2 {
  top: 200px;
  left: -100px;
  width: 200px;
  height: 200px;
  opacity: .2;
}

/* style 3 */
.sigma_cta.style-3 {
  padding: 0 30px;
  border-right: 1px solid var(--thm-border);
  margin-bottom: 30px;
}

.sigma_cta.style-3 .sigma_cta-icon-box,
.sigma_cta.style-4 .sigma_cta-icon-box {
  font-size: 26px;
}

.sigma_cta.style-3 .sigma_cta-title,
.sigma_cta.style-4 .sigma_cta-title {
  margin-bottom: 40px;
}

.sigma_cta.style-3 .sigma_cta-title h5,
.sigma_cta.style-4 .sigma_cta-title h5 {
  margin-bottom: 15px;
}

/* style 4 */
.sigma_cta.style-4 {
  text-align: left;
  padding: 30px;
  border: 1px solid var(--thm-border);
  margin-bottom: 30px;
  border-radius: 6px;
  z-index: 1;
  transition: .3s;
}

.sigma_cta.style-4 .sigma_cta-icon-box {
  background-color: var(--thm-secondary);
  color: #fff;
  margin: 0 0 30px;
}

.sigma_cta.style-4 .shape-1 {
  content: '';
  position: absolute;
  top: -150px;
  right: -150px;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background-color: #fff;
  opacity: .1;
  z-index: -1;
}

.sigma_cta.style-4:not(.bg-primary-1) .shape-1 {
  background-color: var(--thm-base);
}

.sigma_cta.style-4:hover {
  border-color: transparent;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
}

/* style 5 */
.sigma_cta.style-5 {
  border-radius: 6px;
}

.sigma_cta.style-5 .sigma_cta-image,
.sigma_cta.style-10 .sigma_cta-image {
  width: 100%;
  height: 100%;
}

.sigma_cta.style-5 .sigma_cta-content {
  padding: 60px 60px 70px;
}

/* style 6 */
.sigma_cta.style-7,
.sigma_cta.style-6,
.sigma_cta.style-9,
.sigma_cta.style-14 {
  text-align: left;
  padding: 50px;
  border-radius: 25px;
  background-color: #fff;
  box-shadow: 0px 3px 24px rgba(0, 0, 0, .04);
  overflow: visible;
}

/* style 7 */
.sigma_cta.style-7{
  padding: 30px;
}

.sigma_cta.style-7 h5,
.sigma_cta.style-9 h5 {
  margin: 0;
  font-size: 28px;
  padding-left: 170px;
}

.sigma_cta.style-7 img {
  position: absolute;
  bottom: 0;
  left: 40px;
}

/* Style 8 */
.sigma_cta.style-8{
  position: relative;
    margin-bottom: 30px;
    z-index: 1;
    border-radius: 8px;
    box-shadow: 0 3px 24px rgb(0 0 0 / 4%);
    overflow: hidden;
}
.sigma_cta.style-8::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
    border-radius: 8px;
    transition: .3s;
}
.sigma_cta.style-8 img {
    border-radius: 8px;
    width: 100%;
}
.sigma_cta.style-8 .sigma_cta-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    justify-content: flex-end;
    padding: 20px;
}
.sigma_cta.style-8 .sigma_cta-content h4{
  margin-bottom: 10px;
}
.sigma_cta.style-8 .sigma_cta-content p{
  max-width: 350px;
  text-align: left;
  margin-bottom: 30px;
}

/* Style 9 */

.sigma_cta.style-9,
.sigma_cta.style-14{
  background-color: var(--thm-base);
  border-radius: 6px;
  box-shadow: 0px 3px 24px rgb(0 0 0 / 4%);
  padding: 40px;
}

.sigma_cta.style-9 .sigma_cta-content i,
.sigma_cta.style-14 .sigma_cta-content i{
  color: #fff;
  font-size: 50px;
  display: inline-block;
  line-height: 1;
  margin-right: 30px;
}

.sigma_cta.style-9 .sigma_cta-content h5,
.sigma_cta.style-14 .sigma_cta-content h4{
  padding-left: 0;
  color: #fff;
  flex: 1;
  margin-bottom: 0;
}

.sigma_cta.style-9 form .input-group.has-border,
.sigma_cta.style-14 form .input-group.has-border{
  border: 2px solid #fff;
  border-radius: 6px;
}
.sigma_cta.style-9 form .input-group.has-border input,
.sigma_cta.style-14 form .input-group.has-border input{
  border: 0;
}
.sigma_cta.style-9 form input,
.sigma_cta.style-14 form input{
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 6px;
}

.sigma_cta.style-9 form .input-group.has-border input,
.sigma_cta.style-14 form .input-group.has-border input{
  height: 70px;
}

.sigma_cta.style-9 form input::placeholder,
.sigma_cta.style-14 form input::placeholder{
  color: #fff;
}

.sigma_cta.style-9 form .input-group.has-border .input-group-append,
.sigma_cta.style-14 form .input-group.has-border .input-group-append{
  padding: 6px;
}

.sigma_cta.style-9 form .input-group.has-border .input-group-append button,
.sigma_cta.style-14 form .input-group.has-border .input-group-append button{
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}


/* Style 10 */

.sigma_cta.style-10{
  text-align: left;
}
.sigma_cta.style-10 .sigma_cta-content{
  padding: 70px;
  position: relative;
}

.sigma_cta.style-10 .input-group input{
  background-color: #3c3c3c;
  border-color: #3c3c3c;
  color: #fff;
}

.sigma_cta.style-10 .sigma_cta-content .absolute-icon{
  position: absolute;
  top: 15px;
  right: 32px;
  font-size: 200px;
  color: #fff;
  opacity: 0.1;
}

/* Style 11 */

.sigma_cta.style-11{
  text-align: left;
  z-index: 1;
  overflow: visible;
}

.sigma_cta.style-11 .shape-1,
.sigma_cta-image .shape-2{
  position: absolute;
  right: 0;
  top: 20px;
  width: 250px;
  height: 250px;
  background-color: #fff;
  border-radius: 50%;
  z-index: -1;
}

.sigma_cta-image .shape-2{
  left: -100px;
  right: auto;
  top: 50px;
  width: 180px;
  height: 180px;
}

.sigma_cta.style-11 .sigma_cta-buttons .play-btn{
  color: var(--thm-secondary);
  background-color: #fff;
  text-align: left;
}
.sigma_cta-image.style-11{
  position: absolute;
  right: calc(100% - 43%);
  top: 50%;
  transform: translateY(-50%);
}

/* Style 12 */

.sigma_cta-sec.style-12{
  padding: 20px 0 50px;
}

.sigma_cta-sec.style-12.dark-overlay::before{
  background-color: rgba(0, 0, 0, .8);
}

.sigma_cta.style-12{
  text-align: left;
}
.sigma_cta.style-12 form .form-group,
.sigma_cta.style-12 form button{
  margin-bottom: 0;
  margin-top: 20px;
}

.sigma_cta.style-12 form input,
.sigma_cta.style-12 form select{
  background-color: #3c3c3c;
  border: 2px solid #5d5d5d;
  color: #fff;
}

.sigma_cta.style-12 form select{
  color: #a5a5a5;
}

/* Style 13 */

.sigma_cta.style-13{
  border-top: 10px solid var(--thm-base);
  padding: 30px 40px 20px;
  box-shadow: 0px 10px 24px rgb(0 0 0 / 4%);
  text-align: left;
  background-color: #fff;
}

.sigma_cta.style-13 form input{
  background-color: transparent;
  border: 1px solid var(--thm-border);
}

.sigma_cta.style-13 form .input-group > i{
  left: calc(100% - 180px);
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
}

/* Style 14 */

.sigma_cta.style-14{
  padding: 40px 40px 50px;
}
.sigma_cta.style-14 .sigma_cta-content h4{
  max-width: 300px;
}
.sigma_cta.style-14 p{
  margin: 20px 0 35px;
}


/*-------------- Banner (Hero Sections) --------------------------- */
.sigma_banner {
  position: relative;
  z-index: 1;
}

.sigma_banner .banner-slider-inner {
  padding: 200px 0;
  position: relative;
}

.sigma_banner .title {
  font-size: 78px;
  line-height: 1;
}

.sigma_banner h5 {
  font-weight: 500;
  position: relative;
}

.sigma_header-absolute .sigma_header{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background-color: transparent;
}

.sigma_header-absolute .sigma_header.style-5 .sigma_header-middle{
  background-color: rgb( var(--thm-secondary-rgb), .5 );
  box-shadow: none;
}
.sigma_header-absolute .sigma_header.style-5:not(.other) .sigma_header-top{
  background-color: transparent;
}
.sigma_header-absolute .sigma_header.style-5:not(.other) .sigma_header-middle{
  background-color: #fff;
  box-shadow: 0 0 40px 5px rgb(0 0 0 / 3%);
}
.sigma_header.style-5:not(.other) .navbar-nav li a{
  color: var(--thm-secondary);
}

.sigma_header-absolute .sigma_banner.style-8 .banner-slider-inner,
.sigma_header-absolute .sigma_banner .banner-slider-inner {
  padding: 260px 0 230px;
}

.sigma_banner .banner-links {
  margin-top: 50px;
}

.sigma_banner .sigma_banner-social {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 40px;
}

.sigma_banner .sigma_banner-image {
  position: relative;
}

.sigma_banner .sigma_video-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.sigma_banner-slider .slick-prev,
.sigma_banner-slider .slick-next,
.sigma_banner-slider-2 .slick-prev,
.sigma_banner-slider-2 .slick-next,
.sigma_banner-slider-4 .slick-prev,
.sigma_banner-slider-4 .slick-next {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--thm-base);
  color: #fff;
}

.sigma_banner-slider .slick-prev:hover,
.sigma_banner-slider .slick-next:hover,
.sigma_banner-slider-2 .slick-prev:hover,
.sigma_banner-slider-2 .slick-next:hover,
.sigma_banner-slider-4 .slick-prev:hover,
.sigma_banner-slider-4 .slick-next:hover {
  background-color: var(--thm-base-hover);
}

.sigma_banner-slider .slick-next,
.sigma_banner-slider-2 .slick-next {
  right: 10px;
  left: auto;
}

.sigma_banner-slider:hover .slick-prev,
.sigma_banner-slider-2:hover .slick-prev {
  left: 20px;
  opacity: 1;
  visibility: visible;
}

.sigma_banner-slider:hover .slick-next,
.sigma_banner-slider-2:hover .slick-next {
  right: 20px;
  opacity: 1;
  visibility: visible;
}

.sigma_banner-slider .slick-dots,
.sigma_banner-slider-2 .slick-dots {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.sigma_banner-slider .slick-dots li button,
.sigma_banner-slider-2 .slick-dots li button {
  background-color: var(--thm-border);
}

.sigma_banner-slider .slick-dots .slick-active button,
.sigma_banner-slider-2 .slick-dots .slick-active button {
  background-color: var(--thm-base);
}

.sigma_banner .sigma_banner-tags{
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.sigma_banner .sigma_banner-tags li{
  position: relative;
}

.sigma_banner .sigma_banner-tags li + li{
  margin-left: 15px;
  padding-left: 15px;
  margin-top: 0;
}

.sigma_banner .sigma_banner-tags li + li:before{
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 12px;
    background-color: var(--thm-b-text);
}

.sigma_banner .sigma_banner-tags li a{
  color: var(--thm-b-text);
  font-weight: 600;
}

.sigma_banner .sigma_banner-tags li a:hover{
  color: var(--thm-base-hover);
}

/* style 2 */
.sigma_banner.style-2 .sigma_banner-slider-2 .slick-dots {
  bottom: 140px;
  left: 0;
  transform: none;
}

.sigma_banner.style-2 .sigma_banner-slider-2 .slick-prev {
  left: -85px;
}

.sigma_banner.style-2 .sigma_banner-slider-2 .slick-next {
  right: -85px
}

.sigma_banner.style-2 .sigma_banner-slider-2:hover .slick-prev {
  left: -80px;
}

.sigma_banner.style-2 .sigma_banner-slider-2:hover .slick-next {
  right: -80px;
}

/* style 3 */
.sigma_banner.style-3.light-overlay:before,
.sigma_banner.style-4.light-overlay:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255);
  opacity: 0;
}

.sigma_banner.style-3 .sigma_banner-image {
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  height: 100%;
  z-index: -1;
}

/* style 4 */
.sigma_banner.style-4 .sigma_banner-image {
  left: 0;
  right: auto;
}

.sigma_banner.style-4 .sigma_clients {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid var(--thm-border);
}

.banner-image-slider {
  bottom: -132px;
}

.sigma_banner-slider-4 .slick-prev,
.sigma_banner-slider-4 .slick-next {
  bottom: 15%;
  top: auto;
  transform: none;
  opacity: 1;
  visibility: visible;
}

.sigma_banner-slider-4 .slick-next {
  left: 80px;
}

.sigma_banner-slider-5.slick-slider {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  z-index: -2;
}

.sigma_banner-slider-5 div {
  height: 100%;
}

.sigma_banner-thumbnails .slick-slide {
  cursor: pointer;
  opacity: 0.4;
  transition: 0.3s;
}

.sigma_banner-thumbnails .slick-slide:hover {
  opacity: 0.8;
}

.sigma_banner-thumbnails .slick-slide.slick-current {
  opacity: 1;
}

/* style 5 */
.sigma_banner.style-5 .banner-links span {
  font-weight: 500;
}

.sigma_banner.style-5 .banner-slider-inner {
  padding: 140px 0;
}

.sigma_header-absolute+.sigma_banner.style-5 .banner-slider-inner {
  padding: 240px 0 130px;
}

.sigma_banner.style-5 .sigma_video-btn {
  width: 60px;
  height: 60px;
  background-color: var(--thm-base);
  color: #fff;
  margin-right: 15px;
  position: relative;
  left: 0;
  top: 0;
  transform: none;
}

.sigma_banner.style-5 .sigma_video-btn:hover {
  background-color: var(--thm-base-hover);
}

/* style 6 */
.sigma_banner.style-6 {
  overflow: hidden;
}

.sigma_banner-slider-3 {
  position: absolute;
  top: -150px;
}

.sigma_banner-slider-3.slick-vertical .slick-slide {
  padding: 15px 0;
}

.sigma_banner-slider-3 .slick-dots {
  position: absolute;
  top: 15px;
  right: 0;
  flex-direction: column;
}

.sigma_banner-slider-3 .slick-dots li {
  line-height: 1;
}

.sigma_banner-slider-3 .slick-dots li+li {
  margin-left: 0;
  margin-top: 10px;
}

/* Style 7 */
.sigma_banner.style-7 .title {
  margin: 0 0 60px;
  font-size: 128px;
}

.sigma_banner.style-7 p {
  text-transform: uppercase;
  font-weight: 500;
}

.sigma_banner.style-7 .banner-slider-inner {
  padding: 130px 0 110px;
}

/* Style 8 */

.sigma_banner.style-8 .title{
  font-size: 72px;
}

.sigma_banner-info{
    position: relative;
    margin-top: -100px;
    z-index: 1;
}

.sigma_banner-info.style-2{
    margin-top: -85px;
}

.sigma_banner.style-8 .banner-slider-inner{
  padding: 150px 0 250px;
}

/* Style 9 */

.sigma_banner.style-9 .banner-slider-inner{
  padding: 120px 0;
}
.sigma_banner.style-9 .sigma_banner-image:before{
  content: "";
  position: absolute;
  right: -56px;
  top: -70px;
  height: 100%;
  border-top: 619px solid transparent;
  border-right: 450px solid #FCF7FF;
  z-index: -1;
}
.sigma_banner.style-9 .sigma_banner-image{
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1
}
.sigma_banner.style-9 form .input-group-append{
 padding: 6px;
}

.sigma_banner.style-9 form .input-group{
  box-shadow: 0px 3px 24px rgb(0 0 0 / 4%);
  background-color: #fff;
}

.sigma_banner.style-9 form input{
  background-color: #fff;
  border: 0;
  height: 70px;
}


/* Style 10 */

.sigma_banner.style-10 .sigma_banner-text h5{
  display: inline-block;
}
.sigma_banner.style-10 .sigma_banner-text h5:after{
  content: "";
  position: absolute;
  right: -85px;
  top: 50%;
  transform: translateY(-50%);
  width: 70px;
  height: 1px;
  background-color: var(--thm-base);
}
.sigma_banner.style-10 .title{
  line-height: 1.2;
  font-size: 58px;
}

.sigma_banner.style-10 .banner-links{
  margin-top: 35px;
}

.sigma_banner.style-10 .sigma_banner-image img{
  border-radius: 50%;
}
/*-------------- Footer Sections --------------------------- */
.sigma_footer {
  background-color: #FCF7FF;
  padding: 80px 0;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}

/* style 1 */
.sigma_footer .sigma_footer-links {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 0;
}

.sigma_footer .sigma_footer-links li{
  position: relative;
}

.sigma_footer .sigma_footer-links li+li {
  margin-left: 20px;
}

.sigma_footer .sigma_footer-links li a {
  position: relative;
  color: var(--thm-b-text);
  font-weight: 400;
}

.sigma_footer .sigma_footer-links li a:hover,
.sigma_footer .sigma_footer-copyright p a:hover {
  color: var(--thm-base);
}

.sigma_footer .sigma_footer-copyright {
  margin-top: 25px;
}

.sigma_footer .sigma_footer-copyright p a {
  color: var(--thm-secondary);
  font-weight: 600;
}

.sigma_footer .sigma_footer-bottom {
  border-top: 1px solid var(--thm-border);
  padding: 30px 0;
  margin-top: 30px;
  text-align: center;
}

.sigma_footer form span {
  opacity: 0.5;
  font-size: 14px;
  display: block;
  margin-top: 8px;
}

.sigma_footer .sigma_footer-widget .widget-title {
  margin-bottom: 20px;
}

.sigma_footer .sigma_footer-widget {
  padding-bottom: 50px;
  height: 100%;
}

.sigma_footer .sigma_footer-contact li,
.sigma-call{
  display: flex;
  align-items: center;
}

.sigma_footer .sigma_footer-contact li + li{
  margin-top: 20px;
}

.sigma_footer .sigma_footer-contact li i{
  color: var(--thm-secondary);
  font-size: 22px;
  margin-right: 20px;
}
.sigma_footer .sigma_footer-contact li p,
.sigma-call p{
  margin-bottom: 0;
}

.sigma-call i{
  color: var(--thm-base);
  font-size: 32px;
  margin-right: 18px;
}
 .sigma-call h5{
  color: var(--thm-secondary);
  margin-bottom: 0;
  line-height: 1;
}

/* style 2 */
.sigma_footer .sigma_footer-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sigma_footer.style-2 .sigma_footer-links,
.sigma_footer.style-2 .sigma_footer-copyright {
  margin: 0;
}

.sigma_footer.style-2 select {
  width: 180px;
}

/* style 3 */
.sigma_footer.style-3 .sigma_footer-links,
.sigma_footer.style-5 .sigma_footer-links,
.sigma_footer.style-6 .sigma_footer-links,
.sigma_footer.style-7 .sigma_footer-links {
  flex-direction: column;
  align-items: start;
  margin: 0;
}

.sigma_footer.style-3 .sigma_footer-links li+li,
.sigma_footer.style-5 .sigma_footer-links li+li,
.sigma_footer.style-6 .sigma_footer-links li+li,
.sigma_footer.style-7 .sigma_footer-links li+li {
  margin-left: 0;
  margin-top: 14px;
}

/* style 4 */
.sigma_footer.style-4 .sigma_footer-top {
  border-bottom: 1px solid var(--thm-border);
  padding-bottom: 50px;
  margin-bottom: 50px;
}

/* style 5 */

.sigma_footer.style-5 .sigma_footer-bottom{
  margin-top: 50px;
}

.sigma_footer.style-5.has-newsletter{
  padding-top: 160px;
}

.sigma_footer.style-5 .sigma_footer-bottom .sigma_footer-links,
.sigma_footer.style-6 .sigma_footer-bottom .sigma_footer-links{
  flex-direction: row;
}

.sigma_footer.style-5 .sigma_footer-bottom .sigma_footer-links li + li,
.sigma_footer.style-6 .sigma_footer-bottom .sigma_footer-links li + li{
  margin-left: 15px;
  padding-left: 15px;
  margin-top: 0;
}

.sigma_footer.style-5 .sigma_footer-bottom .sigma_footer-links li + li:before,
.sigma_footer.style-6 .sigma_footer-bottom .sigma_footer-links li + li:before{
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 16px;
  background-color: #999b9f;
}

.sigma_footer.style-5 .sigma_info-wrapper.style-18{
  background-color: #1c1e22;
}

.sigma_footer.style-5 .sigma_footer-image{
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

/* style 6 */

.sigma_footer.style-6,
.sigma_footer.style-7{
  background-image: url("http://via.placeholder.com/1920x600");
  padding-bottom: 0;
}

.sigma_footer.style-6:before,
.sigma_footer.style-7:before{
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(34, 36, 41, 0.9);
  z-index: -1;
}

.sigma_footer.style-6 .sigma_footer-bottom{
  background-color: #222429;
}

/* style 7 */

.sigma_footer.style-7.has-no-bg{
  background-image: none;
}

.sigma_footer.style-7.has-no-bg:before{
  content:  none;
}

.sigma_footer.style-7 .social-connect{
  margin-top: 20px;
}

.sigma_footer.style-7:before{
  background-color: rgba(34, 36, 41, 0.8);
}

.sigma_footer.style-7 .social-connect h6{
  color: var(--thm-secondary);
}

.sigma_footer.style-7 .social-connect ul li a{
  width: auto;
  height: auto;
  font-size: 18px;
  background-color: transparent;
}

.sigma_footer.style-7 .social-connect ul li a:hover{
  color: var(--thm-base-hover);
  background-color: transparent;
}

.sigma_footer.style-7 .social-connect .sigma_social-icons li+li {
    margin-left: 18px;
}

.sigma_footer.style-7 .sigma-call{
  background-color: #fff;
  padding: 16px 20px;
  display: block;
  width: 250px;
  position: relative;
}

.sigma_footer.style-7 .sigma-call p{
  color: var(--thm-secondary);
}

.sigma_footer.style-7 .sigma-call h5{
  color: var(--thm-base);
}

.sigma_footer.style-7 .sigma-call i{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  color: #999b9f;
  opacity: 0.1;
  font-size: 60px;
}

.sigma_footer.style-7 form span{
  margin-top: 12px;
}


/* footer newsletter */

.sigma_footer-newsletter{
  position: relative;
  margin-bottom: -80px;
  z-index: 2;
}

.sigma_footer-newsletter:before{
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background-color: var(--thm-base);
}

.sigma_footer-newsletter .sigma_cta.style-9{
  box-shadow: none;
  padding-left: 0;
}

/* Dark Footer */
.sigma_footer.sigma_footer-dark {
  background-color: #222429;
}

.sigma_footer.sigma_footer-dark .sigma_social-icons li a {
  background-color: #1c1e22;
  color: #999b9f;
}

.sigma_footer.sigma_footer-dark .sigma_social-icons li a:hover {
  background-color: var(--thm-base);
  color: #fff;
}

.sigma_footer.sigma_footer-dark,
.sigma_footer.sigma_footer-dark p,
.sigma_footer.sigma_footer-dark .sigma_footer-links li a,
.sigma_footer.sigma_footer-dark .sigma_footer-copyright p a,
.sigma_footer.sigma_footer-dark .widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-descr h6 a,
.sigma_footer.sigma_footer-dark .widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-descr .date {
  color: #999b9f;
}

.sigma_footer.sigma_footer-dark .sigma_footer-widget .widget-title,
.sigma_footer.sigma_footer-dark .sigma_footer-contact li i,
.sigma_footer.sigma_footer-dark .sigma-call h5,
.sigma_footer.sigma_footer-dark.style-7 .sigma-call p,
.sigma_footer.sigma_footer-dark.style-7 .sigma-call h5,
.sigma_footer.sigma_footer-dark .sigma_footer-links li a:hover,
.sigma_footer.sigma_footer-dark .sigma_footer-copyright p a:hover,
.sigma_footer.sigma_footer-dark.style-7 .social-connect ul li a:hover,
.sigma_footer.sigma_footer-dark.style-7 .social-connect h6,
.sigma_footer.sigma_footer-dark .widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-descr h6 a:hover,
.sigma_footer.sigma_footer-dark .widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-descr .date:hover {
  color: #fff;
}

.sigma_footer.sigma_footer-dark.style-4 .sigma_footer-top,
.sigma_footer.sigma_footer-dark .sigma_footer-bottom {
  border-color: rgba(255, 255, 255, 0.1);
}

.sigma_footer.sigma_footer-dark .form-control,
.sigma_footer.sigma_footer-dark select,
.sigma_footer.sigma_footer-dark input[type="email"],
.sigma_footer.sigma_footer-dark input[type="text"] {
  border-color: rgba(255, 255, 255, 0.1);
  background-color: #1c1e22;
}

.sigma_footer.sigma_footer-dark.style-7 .sigma-call{
  background-color: var(--thm-base);
}

/*-------------- About Sections --------------------------- */
/* style 1 */
.sigma_about {
  position: relative;
}

.sigma_about .sigma_about-content .important-text {
  padding: 30px;
  background-color: #fff;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, .03);
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
}

.sigma_about .sigma_about-content .important-text::before {
  content: "\f12a";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  position: absolute;
  font-size: 80px;
  top: -5px;
  right: -5px;
  opacity: .2;
  color: var(--thm-base);
}

.sigma_about .sigma_general-list {
  padding: 0;
  background-color: transparent;
  margin: 20px 0;
}

.sigma_about .sigma_about-image-1,
.sigma_about .sigma_about-image-2 {
  border: 8px solid var(--thm-base);
  background-color: #fff;
  padding: 5px;
  border-radius: 50%;
  position: relative;
}

.sigma_about .sigma_about-image-1 img,
.sigma_about .sigma_about-image-2 img {
  border-radius: 50%;
  width: 100%;
}

.sigma_about .sigma_about-image-2 {
  position: absolute;
  bottom: 30px;
  left: 5px;
}

.sigma_about .sigma_progress{
  margin-top: 60px;
}
.sigma_about .sigma_progress .progress{
  overflow: visible;
}
.sigma_about .sigma_progress .progress-bar{
  position: relative;
}
.sigma_about .sigma_progress .progress-bar span{
  position: absolute;
  top: 50%;
  right: 0;
  width: 4px;
  height: 20px;
  background-color: var(--thm-base);
  transform: translateY(-50%);
}

.sigma_about .sigma_progress .progress-bar span:last-child{
  right: auto;
  left: 0;
}

.sigma_about .sigma_progress .sigma_progress-count{
  padding: 0;
  background-color: transparent;
  color: var(--thm-base);
  right: 0;
}
.sigma_about .sigma_progress .sigma_progress-count::before{
  content: none;
}

.sigma_about .sigma_progress.with-secondary .progress-bar,
.sigma_about .sigma_progress.with-secondary .progress-bar span{
  background-color: var(--thm-secondary);
}

.sigma_about .sigma_progress.with-secondary .sigma_progress-count{
  color: var(--thm-secondary);
}

/* style 2 */
.sigma_about.style-2 .sigma_about-image-1,
.sigma_about.style-3 .sigma_about-image-1,
.sigma_about.style-3 .sigma_about-image-2,
.sigma_about.style-4 .sigma_about-image-1,
.sigma_about.style-17 .sigma_about-image-1 {
  border: 0;
  padding: 0;
  border-radius: 0;
  overflow: hidden;
}

.sigma_about.style-2 .sigma_about-image-1 img,
.sigma_about.style-3 .sigma_about-image-1 img,
.sigma_about.style-3 .sigma_about-image-2 img,
.sigma_about.style-4 .sigma_about-image-1 img,
.sigma_about.style-17 .sigma_about-image-1 img {
  border-radius: 0;
}

.sigma_about.style-2 .sigma_about-image-1 .sigma_video-btn,
.sigma_about.style-17 .sigma_about-image-1 .sigma_video-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* style 3 */
.sigma_about.style-3 .sigma_about-image-1,
.sigma_about.style-3 .sigma_about-image-2 {
  border-radius: 0;
}

.sigma_about.style-3 .sigma_about-image-2 {
  right: 0;
  left: auto;
  bottom: -125px;
  z-index: 1;
}

.sigma_about.style-3 .sigma_about-image-1 img,
.sigma_about.style-3 .sigma_about-image-2 img,
.sigma_about.style-4 .sigma_about-image-1 img {
  width: auto;
}

.sigma_about.style-3 .sigma_about-exp {
  color: #000;
  display: block;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.4;
  position: absolute;
  top: 75px;
  right: -36px;
  transform: rotate(270deg);
  background-color: #fff;
  padding: 10px;
}

.sigma_about.style-3 .sigma_video-btn {
  position: absolute;
  left: 10px;
  bottom: -104px;
  width: 80px;
  height: 80px;
  background-color: var(--thm-base);
  color: #fff;
  z-index: 1;
}

.sigma_about.style-3 .sigma_video-btn:hover,
.sigma_about.style-4 .sigma_video-btn:hover {
  background-color: var(--thm-base-hover);
}

.sigma_about.style-3 .sigma_general-list ul,
.sigma_about.style-4 .sigma_about-list {
  column-count: 2;
}

.sigma_about.style-3 .sigma_general-list ul li i {
  width: 30px;
  height: 30px;
  font-size: 14px;
}

/* style 4 */
.sigma_about.style-4 .sigma_about-image-1 img {
  border: 20px solid var(--thm-base);
  border-radius: 0;
}

.sigma_about.style-4 .sigma_video-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -20px;
  background-color: var(--thm-base);
  color: #fff;
}

.sigma_about.style-4 .sigma_about-list {
  margin: 20px 0;
}

.sigma_about.style-4 .sigma_about-list li span {
  color: var(--thm-secondary);
  font-family: var(--thm-font);
  font-weight: 400;
  text-transform: uppercase;
  font-size: 22px;
  display: block;
  margin-bottom: 5px;
}

.sigma_about.style-4 .sigma_about-list li+li {
  margin-top: 20px;
}

/* Style 5 */
.sigma_about.style-8 .sigma_about-image-1,
.sigma_about.style-8 .sigma_about-image-2,
.sigma_about.style-7 .sigma_about-image-1,
.sigma_about.style-7 .sigma_about-image-2,
.sigma_about.style-9 .sigma_about-image-1,
.sigma_about.style-12 .sigma_about-image-1,
.sigma_about.style-13 .sigma_about-image-1,
.sigma_about.style-14 .sigma_about-image-1,
.sigma_about.style-15 .sigma_about-image-1,
.sigma_about.style-16 .sigma_about-image-1,
.sigma_about.style-5 .sigma_about-image-1,
.sigma_about.style-11 .sigma_about-image-1,
.sigma_about.style-5 .sigma_about-image-2,
.sigma_about.style-10 .sigma_about-image-1,
.sigma_about.style-10 .sigma_about-image-2,
.sigma_about.style-18 .sigma_about-image-1,
.sigma_about.style-20 .sigma_about-image-1,
.sigma_about.style-21 .sigma_about-image-1,
.sigma_about.style-21 .sigma_about-image-2 {
  border-radius: 0;
  border: 0;
  padding: 0;
  background-color: transparent;
}

.sigma_about.style-8 .sigma_about-image-1 img,
.sigma_about.style-8 .sigma_about-image-2 img,
.sigma_about.style-7 .sigma_about-image-1 img,
.sigma_about.style-7 .sigma_about-image-2 img,
.sigma_about.style-9 .sigma_about-image-1 img,
.sigma_about.style-12 .sigma_about-image-1 img,
.sigma_about.style-13 .sigma_about-image-1 img,
.sigma_about.style-14 .sigma_about-image-1 img,
.sigma_about.style-15 .sigma_about-image-1 img,
.sigma_about.style-16 .sigma_about-image-1 img,
.sigma_about.style-5 .sigma_about-image-1 img,
.sigma_about.style-11 .sigma_about-image-1 img,
.sigma_about.style-5 .sigma_about-image-2 img,
.sigma_about.style-10 .sigma_about-image-1 img,
.sigma_about.style-18 .sigma_about-image-1 img,
.sigma_about.style-10 .sigma_about-image-2 img,
.sigma_about.style-20 .sigma_about-image-1 img,
.sigma_about.style-21 .sigma_about-image-1 img,
.sigma_about.style-21 .sigma_about-image-2 img {
  border-radius: 0;
  width: auto;
}

.sigma_about.style-5 [class*="sigma_about-image-"],
.sigma_about.style-10 [class*="sigma_about-image-"] {
  position: absolute;
}

.sigma_about.style-5 .sigma_about-image-1{
  top: 0;
  left: 0;
  max-width: 400px;
  max-height: 450px;
  z-index: 1;
}

.sigma_about.style-5 .sigma_about-image-2 {
  right: 20px;
  bottom: 0;
  left: auto;
  max-width: 400px;
  max-height: 260px;
  z-index: 2;
}

.sigma_about.style-5 .sigma_about-image-3 {
  right: 20px;
  top: 20px;
  max-width: 160px;
  z-index: 2;
}

.sigma_about.style-5 .sigma_about-image-4 {
  top: 140px;
  right: 95px;
  z-index: 3;
}

.sigma_about.style-5 .sigma_about-image-5 {
  z-index: 4;
  left: -45px;
  bottom: 20px;
}

/* Style 7 */
.sigma_about.style-7 .sigma_about-image-1 img,
.sigma_about.style-9 .sigma_about-image-1 img,
.sigma_about.style-12 .sigma_about-image-1 img,
.sigma_about.style-13 .sigma_about-image-1 img,
.sigma_about.style-14 .sigma_about-image-1 img,
.sigma_about.style-15 .sigma_about-image-1 img,
.sigma_about.style-16 .sigma_about-image-1 img,
.sigma_about.style-11 .sigma_about-image-1 img,
.sigma_about.style-18 .sigma_about-image-1 img,
.sigma_about.style-20 .sigma_about-image-1 img {
  width: 100%;
}

.sigma_about.style-7 .sigma_about-image-2 {
  position: absolute;
  left: -20px;
  bottom: 0;
  border-right: 20px solid #fff;
  border-top: 20px solid #fff;
  z-index: 1;
}

.sigma_about.style-7 .sigma_media,
.sigma_about.style-9 .sigma_media,
.sigma_about.style-14 .sigma_media,
.sigma_about.style-13 .sigma_about-author,
.sigma_about.style-20 .sigma_about-author {
  position: absolute;
  right: 0;
  top: 0;
  font-family: var(--thm-font);
  background-color: var(--thm-base);
  padding: 20px;
  font-size: 38px;
  line-height: 1;
  color: #fff;
  min-width: 300px;
  margin-top: 0;
}

.sigma_about.style-7 .sigma_media span,
.sigma_about.style-9 .sigma_media span{
  display: flex;
  align-items: center;
}

.sigma_about.style-7 .sigma_media span b,
.sigma_about.style-9 .sigma_media span b,
.sigma_about.style-14 .sigma_media .about-counter b {
  font-size: 78px;
  font-weight: 700;
  min-width: 100px;
}

.sigma_about.style-7 .sigma_media-content img {
  position: absolute;
}

/* Style 8 */
.sigma_about.style-8 {
  position: relative;
}

.sigma_about.style-8 .sigma_about-image-1 {
  position: absolute;
  left: 0;
  bottom: 0;
  max-width: 575px;
}

.sigma_about.style-8 .shape {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 100px;
  background-color: var(--thm-base);
  height: 465px;
  width: 465px;
  border-radius: 50%;
  z-index: -1;
}


/* Style 9 */

.sigma_about.style-9 .sigma_about-image-1:before,
.sigma_about.style-14 .sigma_about-image-1:before{
  content: "";
  position: absolute;
  bottom: -15px;
  left: -15px;
  border-left: 120px solid var(--thm-base);
  border-top: 120px solid transparent;
  border-right: 100px solid transparent;
  z-index: -1;
}

.sigma_about.style-9 .sigma_about-image-1.has-no-content:before{
  content: none;
}

.sigma_about.style-9 .sigma_media{
  top: 60px;
  right: -80px;
}

.sigma_about.style-9 .sigma_info.style-15,
.sigma_about.style-11 .sigma_info.style-15,
.sigma_about.style-14 .sigma_info.style-15,
.sigma_about.style-15 .sigma_info.style-15,
.sigma_about.style-21 .sigma_info.style-15{
  padding: 0;
}

.sigma_about.style-9 .video-box,
.sigma_about.style-12 .video-box{
  display: flex;
  align-items: center;
  margin-left: 50px;
}

.sigma_about.style-9 .video-box .sigma_video-btn,
.sigma_about.style-12 .video-box .sigma_video-btn{
  background-color: var(--thm-secondary);
  color: #fff;
  width: 60px;
  height: 60px;
}

.sigma_about.style-9 .video-box .sigma_video-btn:before,
.sigma_about.style-9 .video-box .sigma_video-btn:after,
.sigma_about.style-12 .video-box .sigma_video-btn:before,
.sigma_about.style-12 .video-box .sigma_video-btn:after{
  content: none;
}

.sigma_about.style-9 .video-box span,
.sigma_about.style-12 .video-box span{
    color: var(--thm-secondary);
    font-weight: 500;
    line-height: 1.5;
    margin-left: 15px;
}

/* Style 10 */

.sigma_about.style-10 .sigma_about-image-1{
  top: 0;
  left: 0;
  position: relative;
  max-width: 300px;
}

.sigma_about.style-10 .sigma_about-image-1:before{
  content: "";
  position: absolute;
  right: -10px;
  bottom: -26px;
  background-color: var(--thm-base);
  width: 258px;
  height: 200px;
  z-index: -1;
}

.sigma_about.style-10 .sigma_about-image-2{
  top: 50%;
  transform: translateY(-50%);
  bottom: auto;
  right: 0;
  left: auto;
  z-index: -2;
}

.sigma_about.style-10 .sigma_about-image-3{
  left: 50px;
  bottom: 0;
  z-index: -1;
  max-width: 300px;
  max-height: 260px;
  z-index: -3;
}

.sigma_about.style-10 .sigma_media,
.sigma_about.style-18 .sigma_media{
  flex-direction: column;
  align-items: flex-start;
  margin: 16px 0 30px;
}

.sigma_about.style-10 .sigma_media span{
  display: inline-block;
  font-size: 36px;
  font-weight: 600;
  color: var(--thm-base);
}

.sigma_about.style-10 .sigma_media-content,
.sigma_about.style-18 .sigma_media-content{
  margin-left: 0;
}

/* Style 11 */

.sigma_about.style-11 .sigma_info.style-15 .sigma_info-icon,
.sigma_about.style-21 .sigma_info.style-15 .sigma_info-icon{
 font-size: 38px;
 position: relative;
}

.sigma_about.style-11 .sigma_info.style-15 .sigma_info-icon:after{
  content: "";
  position: absolute;
  top: -10px;
  right: -10px;
  width: 40px;
  height: 40px;
  background-color: rgba(var(--thm-base-rgb), 0.1);
  border-radius: 50%;
  z-index: -1;
}

.sigma_about.style-11 .sigma_info.style-15 .sigma_info-icon.secondary-color:after{
  background-color: rgba(var(--thm-secondary-rgb), 0.1);
}

.sigma_about.style-11 .sigma_about-content > p,
.sigma_about.style-21 .sigma_about-content > p{
  margin-bottom: 30px;
}

/* Style 12 */

.sigma_about.style-12 .video-box{
  margin-left: 0;
  justify-content: space-between;
  padding: 20px 40px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(var(--thm-secondary-rgb), 0.5);
}

.sigma_about.style-12 .video-box .sigma_video-btn{
  background-color: var(--thm-base);
}

.sigma_about.style-12 .video-box span{
  color: #fff;
  margin-left: 0;
  font-size: 20px;
}

.sigma_about.style-12 .sigma_about-image-1{
  margin-bottom: -100px;
  margin-top: 80px;
}

.sigma_about.style-12 .sigma_about-content p{
  color: #999b9f;
}
.sigma_about.style-12 .sigma_about-content .sigma_about-contact{
  margin-top: 30px;
}
.sigma_about.style-12 .sigma_about-content .sigma_about-contact span{
  color: #fff;
  font-size: 32px;
  display: block;
  font-weight: 600;
  line-height: 1;
}

/* Style 13 */

.sigma_about.style-13 .sigma_about-author{
  background-color: #fff;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.03);
}

.sigma_about.style-13 .sigma_about-author{
  top: 25px;
  right: -80px;
}

.sigma_about.style-13 .sigma_about-author img,
.sigma_about.style-20 .sigma_about-author img{
  border-radius: 50%;
  margin-right: 20px;
}
.sigma_about.style-13 .sigma_about-author,
.sigma_about.style-20 .sigma_about-author{
  font-size: 24px;
  display: flex;
  align-items: center;
}

.sigma_about.style-13 .sigma_tab-item .nav-tabs{
  margin-bottom: 12px;
}
.sigma_about.style-13 .sigma_tab-item .nav-tabs .nav-item{
  margin-bottom: 0;
}
.sigma_about.style-13 .sigma_tab-item .nav-tabs .nav-link{
  padding: 0;
  color: var(--thm-secondary);
  opacity: 0.2;
  font-size: 16px;
}
.sigma_about.style-13 .sigma_tab-item .nav-tabs .nav-link:hover,
.sigma_about.style-13 .sigma_tab-item .nav-tabs .nav-link.active{
  color: var(--thm-secondary);
  opacity: 1;
  background-color: transparent;
  box-shadow: none;
}

/* Style 14 */

.sigma_about.style-14 .sigma_about-image-1{
  margin-bottom: 55px;
  right: -60px;
}

.sigma_about.style-14 .sigma_media{
  bottom: -55px;
  top: auto;
  right: 16px;
  flex-direction: column;
  align-items: flex-start;
  font-size: 22px;
  min-width: 250px;
}

.sigma_about.style-14 .sigma_media .about-counter b{
  font-weight: 600;
}

.sigma_about.style-14 .sigma_about-image-1:before{
  border: 0;
  bottom: -55px;
  left: -60px;
  width: 180px;
  height: 520px;
  background-color: var(--thm-base);
}

.sigma_about.style-14 .sigma_info.style-15{
  margin-bottom: 10px;
  align-items: center;
}

.sigma_about.style-14 .sigma_info.style-15 .sigma_info-title .sigma_info-icon i{
  font-size: 18px;
}
.sigma_about.style-14 .sigma_info.style-15 .sigma_info-title .sigma_info-icon {
    width: 50px;
    height: 50px;
    background-color: var(--thm-base-hue);
    color: var(--thm-base);
}

.sigma_about.style-14 .sigma_about-content .sigma_btn{
  margin-top: 28px;
}

/* Style 15 */

.sigma_about-sec.style-15:before{
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 58%;
  height: 100%;
  background-color: #FCF7FF;
}

.sigma_about.style-15 .sigma_info.style-15 .sigma_info-title .sigma_info-icon{
   width: 90px;
   height: 90px;
   background-color: #fff;
   border: 7px solid var(--thm-border);
   color: var(--thm-base);
   margin-right: 28px;
}

.sigma_about.style-15 .sigma_info.style-15 .sigma_info-description h5{
  font-weight: 400;
}
.sigma_about.style-15 .sigma_info.style-15 .sigma_info-description h5 span{
  font-weight: 600;
}

.sigma_about.style-15 .sigma_info.style-15 + .sigma_info.style-15{
  margin-top: 40px;
  margin-bottom: 0;
}

/* Style 16 */

 .sigma_about-image-1.style-16 {
    position: absolute;
    right: 0;
    object-fit: cover;
    width: 48%;
    height: calc(100% - 200px);
}

.sigma_about.style-16 .sigma_info.style-15{
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.03);
  border-radius: 0;
  padding-left: 50px;
}

.sigma_about.style-16 .sigma_about-content > p{
  margin-bottom: 40px;
}

.sigma_about.style-16 .sigma_info.style-15 .number{
  position: absolute;
  left: -18px;
  top: 25px;
  padding: 5px 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sigma_about.style-16 .sigma_info.style-15 .sigma_info-title .sigma_info-icon{
  width: 50px;
  height: 50px;
}
.sigma_about.style-16 .sigma_info.style-15 .sigma_info-title .sigma_info-icon i{
 font-size: 50px;
}

/* Style 17 */
.sigma_about-skill.style-17:before{
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: calc(100% - 64%);
  height: 100%;
  background-color: var(--thm-secondary);
}
.sigma_about.style-17 .sigma_general-list{
  margin: 30px 0 0;
}

.sigma_about.style-17 .sigma_general-list.style-3{
  margin: 25px 0 35px;
}

.sigma_about.style-17 .sigma_about-content p,
.sigma_about.style-17 .sigma_general-list.style-3 li span{
  color: rgb(255 255 255 / 72%);
}

.sigma_about.style-17 .sigma_general-list.style-3 li span{
  font-weight: 400;
}

.sigma_about.style-17 .sigma_general-list.style-3 li i{
  color: rgb(116 116 116 / 72%);
}

.sigma_about-sec.style-17.extra-padding{
  padding-bottom: 380px;
}

/* Style 18 */

.sigma_about.style-18 .sigma_media{
  margin: 0;
  align-items: center;
}

.sigma_about.style-18 .sigma_media + .sigma_media{
  margin-left: 65px;
}
.sigma_about.style-18 .sigma_progress{
  margin: 38px 0 20px;
}

.sigma_about.style-18 .sigma_progress.round.sm svg{
  width: 140px;
  height: 140px;
}
.sigma_about.style-18 .sigma_progress.round .sigma_progress-stroke{
  stroke-width: 10px;
}

.sigma_about.style-18 .sigma_progress.round .sigma_progress-round{
  stroke-width: 10px;
  stroke-linecap: square;
}

.sigma_about.style-18 .sigma_progress.round>i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 38px;
    color: var(--thm-base);
}

.sigma_about.style-18 .sigma_progress.has-secondary-color .sigma_progress-round{
 stroke: var(--thm-secondary);
}
.sigma_about.style-18 .sigma_progress.has-secondary-color>i{
 color: var(--thm-secondary);
}

/* Style 19 */

.sigma_about-image-1.style-19{
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 44%;
}

.sigma_about.style-19 .sigma_about-content .important-text{
  margin-top: 58px;
  margin-bottom: 0;
  padding: 25px 30px 25px 140px;
  overflow: visible;
}

.sigma_about.style-19 .sigma_about-content .important-text::before{
   content: "\f086";
   top: 50%;
   transform: translateY(-50%);
   right: 20px;
   color: var(--thm-secondary);
   opacity: 0.1;
}

.sigma_about.style-19 .sigma_about-content .important-text p{
  color: var(--thm-secondary);
  font-weight: 500;
  font-size: 18px;
}
.sigma_about.style-19 .sigma_about-content .important-text span{
  color: var(--thm-base);
  font-weight: 600;
  display: block;
  margin-top: 5px;
}

.sigma_about.style-19 .sigma_about-content .important-text .author{
  position: absolute;
  left: 25px;
  top: -20px;
}

/* Style 20 */

.sigma_about-sec.style-20{
  margin-top: 30px;
}
.sigma_about-sec.style-20:before{
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  background-color: #FCF7FF;
  width: 100%;
  width: calc(100% - 420px);
  height: 100%;
}

.sigma_about-sec.style-20 .sigma_about-image-1{
  position: absolute;
  top: -130px;
  left: -50px;
}

.sigma_about.style-20 .sigma_about-author{
   position: relative;
   right: 0;
   top: 0;
   background-color: transparent;
   padding: 0;
   min-width: 100%;
   margin-top: 30px;
}


/* Style 21 */

.sigma_about.style-21 p{
   color: #fff;
   opacity: 0.8;
}

.sigma_about.style-21 .sigma_info.style-15 .sigma_info-description h5,
.sigma_about.style-21 .sigma_info.style-15 .sigma_info-title i{
  color: #fff;
}

.sigma_about.style-21 .sigma_info.style-15 .sigma_info-icon{
  width: auto;
  height: auto;
  font-size: 44px;
}

.sigma_about.style-21 .sigma_about-image-1{
  max-width: 400px;
  border-radius: 6px;
  overflow: hidden;
}
.sigma_about.style-21 .sigma_about-image-2{
  right: 0;
  left: auto;
  bottom: 18px;
  max-width: 370px;
  border-radius: 6px;
  overflow: hidden;
}


/*-------------- Progress & Media Sections --------------------------- */
.sigma_media {
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.sigma_media-content {
  flex: 1;
  margin-left: 30px;
}

.sigma_media-content h5 {
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 0;
}

.sigma_media-content p {
  margin: 0;
}

.sigma_progress {
  position: relative;
}

.sigma_progress .progress {
  background-color: #FCF7FF;
  height: 5px;
  border-radius: 0;
}

.sigma_progress .progress-bar {
  background-color: var(--thm-base);
}

.sigma_progress .sigma_progress-count {
  position: absolute;
  top: -35px;
  font-size: 16px;
  font-family: var(--thm-font);
  left: 0;
  opacity: 0;
  color: #fff;
  background-color: var(--thm-base);
  padding: 3px 4px 2px 4px;
  line-height: 1;
}

.sigma_progress .sigma_progress-count::before {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 0;
  height: 0;
  border-top: 5px solid var(--thm-base);
  border-right: 5px solid transparent;
}

.sigma_progress h6 {
  position: absolute;
  bottom: 100%;
  left: 0;
  margin: 0 0 5px;
  font-weight: 400;
}

.sigma_progress.round {
  display: inline-block;
}

.sigma_progress.round>span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  font-size: 28px;
  line-height: 1;
  color: var(--thm-secondary);
}

.sigma_progress.round.sm>span {
  font-size: 22px;
}

.sigma_progress.round.sm>span span {
  font-size: 12px;
}

.sigma_progress.round>svg {
  width: 120px;
  height: 120px;
  overflow: visible;
}

.sigma_progress.round.sm svg {
  width: 100px;
  height: 100px;
}

.sigma_progress.round .sigma_progress-stroke {
  fill: none;
  stroke: var(--thm-border);
  stroke-width: 10;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  position: relative;
  z-index: -1;
  color: var(--thm-border);
}

.sigma_progress.round .sigma_progress-round {
  fill: none;
  stroke: var(--thm-base);
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  stroke-dashoffset: 700;
  stroke-dasharray: 700;
  stroke-width: 20px;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke-linecap: butt;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  transition: stroke-dashoffset 1s;
  color: var(--thm-base);
  stroke-linecap: round;
}

/*-------------- Pricing Sections --------------------------- */
.sigma_pricing {
  padding: 30px;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
  margin-bottom: 30px;
  text-align: center;
  border-radius: 0;
  transition: 0.3s;
}

.sigma_pricing:hover {
  transform: translateY(-3px);
}

.sigma_pricing .sigma_pricing-top span {
  margin-bottom: 10px;
}

.sigma_pricing .sigma_pricing-icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--thm-base);
  background-color: var(--thm-secondary);
  font-size: 32px;
  margin: 0 auto;
}

.sigma_pricing .sigma_pricing-title {
  display: block;
  color: var(--thm-base);
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 500;
}

.sigma_pricing .sigma_pricing-rate {
  display: block;
  color: var(--thm-secondary);
  font-weight: 700;
  font-size: 30px;
  line-height: 1.2;
}

.sigma_pricing .sigma_general-list {
  background-color: transparent;
  padding: 0;
  margin: 20px 0 25px;
}

.sigma_pricing .sigma_general-list ul li span {
  flex: none;
  padding-left: 10px;
}

.sigma_pricing .sigma_general-list ul li {
  justify-content: center;
}

.sigma_pricing .sigma_general-list ul li+li {
  margin-top: 10px;
}

/* Style 2 */
.sigma_pricing.style-2 {
  padding: 0 0 30px;
}

.sigma_pricing.style-2 .sigma_general-list {
  margin: 0;
}

.sigma_pricing.style-2 .sigma_general-list ul {
  padding: 80px 40px 30px;
}

.sigma_pricing.style-2 .sigma_general-list ul li {
  justify-content: space-between;
  padding: 0;
}

.sigma_pricing.style-2 .sigma_general-list ul li span {
  padding: 0;
  font-weight: 400;
}

.sigma_pricing.style-2 .sigma_pricing-thumb {
  position: relative;
}

.sigma_pricing.style-2 .sigma_pricing-thumb img {
  width: 100%;
}

.sigma_pricing.style-2 .sigma_pricing-top {
  position: absolute;
  bottom: -60px;
  left: 40px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 80px);
}

.sigma_pricing.style-2 .sigma_pricing-price {
  background-color: #fff;
  border-radius: 25px;
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, .03);
}

.sigma_pricing.style-2 .sigma_pricing-price p {
  margin: 0;
}

.sigma_pricing.style-2 .sigma_pricing-price .sigma_pricing-rate {
  margin: 0;
  line-height: 1;
  display: flex;
  align-items: center;
}

.sigma_pricing.style-2 .sigma_pricing-price .sigma_pricing-rate .sigma_pricing-rate-currency {
  margin: 0 3px 0 0;
  color: var(--thm-base);
  font-size: 18px;
}

.sigma_pricing.style-2 .sigma_pricing-price .sigma_pricing-rate .sigma_pricing-rate-price {
  margin: 0;
  font-size: 38px;
}

.sigma_pricing.style-2 .sigma_pricing-title {
  background-color: var(--thm-base);
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 20px;
  border-radius: 35px;
}

/* Style 3 */
.sigma_pricing.style-3,
.sigma_pricing.style-4 {
  box-shadow: none;
  border: 1px solid var(--thm-border);
  border-radius: 10px;
  overflow: hidden;
  border-top: 0;
  position: relative;
}

.sigma_pricing.style-3:hover,
.sigma_pricing.style-4:hover {
  border-color: transparent;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, .03);
}

.sigma_pricing.style-3::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 7px;
  background-color: var(--thm-base);
}

.sigma_pricing.style-3 .sigma_general-list ul li {
  justify-content: flex-start;
}

/* Style 4 */

.sigma_pricing.style-4{
  border-top: 1px solid var(--thm-border);
  padding: 0;
}
.sigma_pricing.style-4 .sigma_pricing-top{
  padding: 30px 30px 80px;
  position: relative;
}

.sigma_pricing.style-4 .sigma_pricing-top .top-wave{
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
}
.sigma_pricing.style-4 .sigma_pricing-top .bottom-wave{
  position: absolute;
  left: 50px;
  bottom: 9px;
  width: 100%;
}

.sigma_pricing.style-4 .sigma_pricing-bottom{
  padding: 0 30px 40px;
}

.sigma_pricing.style-4 .sigma_pricing-title{
  background-color: rgba(255, 255, 255, 0.2);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px 32px;
  color: #fff;
  border-radius: 30px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 18px;
}
.sigma_pricing.style-4 .sigma_pricing-rate{
  font-size: 38px;
}
.sigma_pricing.style-4 .sigma_pricing-rate,
.sigma_pricing.style-4 .sigma_pricing-top p{
  color: #fff;
}
.sigma_pricing.style-4 .sigma_general-list ul{
  display: inline-block;
}
.sigma_pricing.style-4 .sigma_general-list ul li{
  justify-content: flex-start;
}



/*-------------- Counter Sections --------------------------- */

.sigma_counter {
  margin-bottom: 30px;
  position: relative;
}

.sigma_counter span {
  position: relative;
  display: flex;
  color: var(--thm-base);
  font-size: 40px;
  margin-bottom: 10px;
  line-height: 1;
}

.sigma_counter span.plus {
  font-size: 22px;
  margin: 0;
}

.sigma_counter p {
  color: var(--thm-secondary);
  font-weight: 500;
  line-height: 1.3;
}

.sigma_counter i{
  font-size: 40px;
  color: var(--thm-secondary);
  display: block;
  margin-bottom: 20px;
}

/* Style 2 */

.sigma_counter.style-2,
.sigma_counter.style-3{
  padding: 30px;
  margin-bottom: 0;
  background-color: var(--thm-base);
  border-left: 2px solid rgba(255, 255, 255, 0.4);
}

/* Style 3 */

.sigma_counter-wrapper{
  background-color: var(--thm-base);
  padding: 40px;
}

.sigma_counter.style-3{
  border-left: 0;
  border-top: 2px solid rgba(255, 255, 255, 0.4);
  margin-top: 20px;
  padding: 20px 0 0;
  display: flex;
  align-items: center;
}

.sigma_counter.style-3 i{
  margin-bottom: 0;
}

/* Style 4 */

.sigma_counter.style-4{
  text-align: center;
  display: flex;
}

.sigma_counter.style-4 .sigma_counter-inner{
  min-width: 120px;
}

.sigma_counter.style-4 span{
    justify-content: center;
    color: #fff;
    align-items: baseline;
}

.sigma_counter.style-4 p{
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
  margin-bottom: 0;
}

.sigma_counter.style-4 span.plus{
  font-size: 16px;
}

.sigma_counter.style-4 .sigma_counter-dots{
  display: grid;
  grid-template-columns: 7px 7px;
  grid-template-rows: 7px 7px;
  column-gap: 7px;
  grid-row-gap: 8px;
}

.sigma_counter.style-4 .sigma_counter-dots span{
  width: 7px;
  height: 7px;
  background-color: var(--thm-base);
  line-height: 1;
  margin-bottom: 0;
}

.sigma_counter.style-4 .sigma_counter-dots span:first-child{
  grid-column: 2;
}

/* Style 5 */

.sigma_counter-wrapper.style-5{
  padding: 70px 70px 40px;
}

.margin-negative{
  z-index: 2;
  position: relative;
  margin: 70px 0 -220px;
}
.margin-negative.sm{
  margin: 70px 0 -160px;
}
.sigma_counter-wrapper.style-5.margin-negative{
  z-index: 2;
  position: relative;
  margin: 80px 0 -200px;
}
.sigma_counter.style-5{
  text-align: center;
}
.sigma_counter.style-5 span{
 color: #fff;
 justify-content: center;
 font-weight: 800;
 font-size: 48px;
 margin-bottom: 15px;
}
.sigma_counter.style-5 span.plus{
  font-size: 48px;
  margin-bottom: 0;
}

/*-------------- To Top Sections --------------------------- */
.sigma_top {
  position: fixed;
  bottom: 40px;
  right: 30px;
  background-color: #fff;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, .03);
  border-radius: 50%;
  cursor: pointer;
  transition: .3s;
  opacity: 0;
  visibility: hidden;
}

.sigma_top i {
  transition: .3s;
}

.sigma_top:hover {
  background-color: var(--thm-base);
  color: #fff;
}

.sigma_top:hover i {
  transform: translateY(-3px);
}

.sigma_top.active {
  bottom: 30px;
  opacity: 1;
  visibility: visible;
}

/* Style 2 */
.sigma_top.style-2 {
  border-radius: 0;
}

/* Style 3 */
.sigma_top.style-3 {
  background-color: var(--thm-base);
  color: #fff;
}

.sigma_top.style-3:hover {
  box-shadow: 0 0 40px 5px rgba(var(--thm-base-rgb), .3);
}

/*-------------- Instagram Sections --------------------------- */
.sigma_instagram.style-1 .instagram_gallery a {
  flex: 0 0 33.33%;
  overflow: hidden;
  max-height: 300px;
}

.sigma_instagram.style-5 .instagram_gallery a img,
.sigma_instagram.style-2 .instagram_gallery a img,
.sigma_instagram.style-3 .instagram_gallery a img,
.sigma_instagram.style-1 .instagram_gallery a img{
  transition: .3s;
}
.sigma_instagram.style-1 .instagram_gallery a:hover img{
  transform: scale(1.1);
}

.sigma_instagram.style-1 .instagram_gallery {
  position: relative;
}

.sigma_instagram.style-5 a:nth-child(1)::before,
.sigma_instagram.style-5 a:nth-child(2)::before,
.sigma_instagram.style-1 .instagram_gallery::before {
  content: "\f16d";
  font-family: "Font Awesome 5 Brands";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-size: 20px;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  background-color: #fff;
  color: var(--thm-secondary);
  box-shadow: 0 0 40px 5px rgb(0 0 0 / 3%);
  transition: .3s;
  z-index: 2;
  border-radius: 50%;
}

.sigma_instagram-content{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.sigma_instagram-slider .slick-list{
  margin: 0 -5px;
}
.sigma_instagram-slider .slick-slide{
  padding: 0 5px;
}
.sigma_instagram-slider .slick-slide img{
  width: 100%;
}

/* Style 2 */
.sigma_instagram.style-4 a,
.sigma_instagram.style-2 a{
  flex: 0 0 16.667%;
}

.sigma_instagram.style-4 .instagram_gallery,
.sigma_instagram.style-2 .instagram_gallery{
  margin: 0 -10px;
}

.sigma_instagram.style-4 a img,
.sigma_instagram.style-2 a img{
  padding: 10px;
}
.sigma_instagram.style-2 .instagram_gallery a:hover img{
  transform: scale(1.1) rotate(5deg);
}

/* Style 3 */
.sigma_instagram.style-3 a{
  flex: 0 0 25%;
}
.sigma_instagram.style-3 .instagram_gallery a:hover img{
  transform: scale(1.05);
}

.sigma_instagram.style-3 + a{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

/* Style 4 */
.sigma_instagram.style-4 .instagram_gallery{
  border: 2px solid var(--thm-border);
}

/* Style 5 */
.sigma_instagram.style-5 a{
  position: relative;
  flex: 0 0 25%;
  overflow: hidden;
}

.sigma_instagram.style-5 a:nth-child(1),
.sigma_instagram.style-5 a:nth-child(2){
  flex: 0 0 50%;
}

.sigma_instagram.style-5 a:nth-child(1)::before,
.sigma_instagram.style-5 a:nth-child(2)::before{
  opacity: 0;
  visibility: hidden;
  top: 53%;
}

.sigma_instagram.style-5 a:nth-child(1):hover::before,
.sigma_instagram.style-5 a:nth-child(2):hover::before{
  opacity: 1;
  visibility: visible;
  top: 50%;
}

.sigma_instagram.style-5 a:nth-child(n+3):hover img{
  transform: scale(1.1);
}


/*-------------- NEwsletter popup & modals Sections --------------------------- */
.modal-header,
.modal-content {
  border: 0;
  border-radius: 0;
}

.modal-body{
  padding: 40px;
}

.modal-lg {
    max-width: 1000px;
}

.sigma_newsletter-popup-modal.modal-dialog {
  max-width: 600px;
  margin: .5rem auto;
}

.sigma_newsletter-popup-modal .modal-header {
  padding: 0;
  height: 300px;
  overflow: hidden;
}

.sigma_newsletter-popup-modal .sigma_close {
  position: absolute;
  top: 20px;
  right: 20px;
}

.sigma_newsletter-popup-modal .modal-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 3rem;
  background-size: cover;
}

.sigma_newsletter-popup-modal .modal-body h3 {
  font-size: 30px;
  margin: 0 0 10px;
}

.sigma_newsletter-popup-text-wrapper {
  text-align: center;
}

.sigma_newsletter-popup-modal .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.sigma_newsletter-popup-modal .form-control {
  width: 300px;
  margin-bottom: 10px;
}

.sigma_newsletter-popup-modal .mc4wp-form-fields .btn-custom {
  width: 100%;
}

.sigma_newsletter-popup-modal .sigma_newsletter-popup-dismiss {
  display: block;
  cursor: pointer;
  margin-top: 15px;
}

.sigma_newsletter-popup-modal .sigma_newsletter-popup-dismiss:hover {
  text-decoration: underline;
}

/* Style 3 */
.sigma_newsletter-popup-modal.style-3 form {
  display: flex;
  align-items: center;
  margin: 0;
}

.sigma_newsletter-popup-modal.style-3 form .form-group {
  margin: 0 15px 0 0;
}

.sigma_newsletter-popup-modal.style-3 form .form-control {
  margin: 0;
}

/* Style 4 */
.sigma_newsletter-popup-modal.style-4 .sigma_newsletter-popup-dismiss,
.sigma_newsletter-popup-modal.style-4 .sigma_newsletter-popup-text-wrapper p {
  color: #999b9f;
}

/* Style 5 */
.sigma_popup {
  padding: 30px;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, .03);
  position: fixed;
  bottom: 20px;
  left: -600px;
  background-color: #fff;
  max-width: 450px;
  text-align: center;
  transition: 1s cubic-bezier(0, 0, 0.2, 1);
  z-index: 994;
}

.sigma_popup.show {
  left: 20px;
}

.sigma_popup .sigma_close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.sigma_popup .sigma_popup-dismiss {
  display: block;
  cursor: pointer;
  margin-top: 15px;
}

.sigma_popup .sigma_popup-dismiss:hover {
  text-decoration: underline;
}

.sigma_popup .sigma_popup-text {
  font-size: 14px;
}

.sigma_popup .sigma_popup-text h3 {
  margin-bottom: 15px;
}

.sigma_popup .sigma_close span {
  width: 14px;
}

/*-------------- Preloader Sections --------------------------- */
.sigma_preloader {
  background-color: var(--thm-base);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s;
}

.sigma_preloader.hidden {
  opacity: 0;
  visibility: hidden;
}

/* Preloader: Dual */
.preloader-dual-inner div {
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  top: 60px;
  left: 20px;
  background: #fff;
}

.preloader-dual-inner div:nth-child(1) {
  animation: preloader-dual-inner 1s linear infinite;
  animation-delay: -0.5s;
}

.preloader-dual-inner div:nth-child(2) {
  animation: preloader-dual-inner 1s linear infinite;
  animation-delay: 0s;
}

.preloader-dual-inner div:nth-child(3) {
  animation: preloader-dual-inner-o 1s linear infinite;
  animation-delay: -0.5s;
}

.preloader-dual {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}

.preloader-dual-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}

/* Preloader: Default */
.sigma_preloader-default .sigma_preloader-inner {
  display: block;
  width: 97px;
}

.sigma_preloader-default .sigma_preloader-inner i {
  width: 19px;
  height: 19px;
  display: inline-block;
  border-radius: 50%;
  background: #fff;
}

.sigma_preloader-default .sigma_preloader-inner i:first-child {
  opacity: 0;
  -webkit-animation: cssload-loading-ani2 0.58s linear infinite;
  animation: cssload-loading-ani2 0.58s linear infinite;
  -webkit-transform: translate(-19px);
  transform: translate(-19px);
}

.sigma_preloader-default .sigma_preloader-inner i:nth-child(2),
.sigma_preloader-default .sigma_preloader-inner i:nth-child(3) {
  -webkit-animation: cssload-loading-ani3 0.58s linear infinite;
  animation: cssload-loading-ani3 0.58s linear infinite;
}

.sigma_preloader-default .sigma_preloader-inner i:last-child {
  -webkit-animation: cssload-loading-ani1 0.58s linear infinite;
  animation: cssload-loading-ani1 0.58s linear infinite;
}

/* Preloader: Eclipse */
.preloader-eclipse-inner div {
  position: absolute;
  animation: preloader-eclipse 1s linear infinite;
  width: 160px;
  height: 160px;
  top: 20px;
  left: 20px;
  border-radius: 50%;
  box-shadow: 0 4px 0 0 #fff;
  transform-origin: 80px 82px;
}

.preloader-eclipse {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}

.preloader-eclipse-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

/* Preloader: Spinner */
.preloader-spinner-inner div {
  left: 94px;
  top: 48px;
  position: absolute;
  animation: preloader-spinner-inner linear 1s infinite;
  background: #fff;
  width: 12px;
  height: 24px;
  border-radius: 6px / 12px;
  transform-origin: 6px 52px;
}

.preloader-spinner-inner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9166666666666666s;
}

.preloader-spinner-inner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -0.8333333333333334s;
}

.preloader-spinner-inner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.75s;
}

.preloader-spinner-inner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.6666666666666666s;
}

.preloader-spinner-inner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.5833333333333334s;
}

.preloader-spinner-inner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.5s;
}

.preloader-spinner-inner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.4166666666666667s;
}

.preloader-spinner-inner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.3333333333333333s;
}

.preloader-spinner-inner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.25s;
}

.preloader-spinner-inner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.16666666666666666s;
}

.preloader-spinner-inner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.08333333333333333s;
}

.preloader-spinner-inner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

.preloader-spinner {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}

.preloader-spinner-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}

/* Preloader: Ripple */
.preloader-ripple-inner div {
  position: absolute;
  border-width: 4px;
  border-style: solid;
  opacity: 1;
  border-radius: 50%;
  animation: preloader-ripple-inner 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  border-color: #fff;
}

.preloader-ripple-inner div:nth-child(2) {
  animation-delay: -0.5s;
}

.preloader-ripple {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}

.preloader-ripple-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}

/* Preloader: Gear */
.preloader-gear-inner>div {
  transform-origin: 100px 100px;
  animation: preloader-gear-inner 0.2s infinite linear;
}

.preloader-gear-inner>div div {
  position: absolute;
  width: 22px;
  height: 152px;
  background: #fff;
  left: 100px;
  top: 100px;
  transform: translate(-50%, -50%);
}

.preloader-gear-inner>div div:nth-child(1) {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.preloader-gear-inner>div div:nth-child(6) {
  width: 80px;
  height: 80px;
  background: var(--thm-base);
  border-radius: 50%;
}

.preloader-gear-inner>div div:nth-child(3) {
  transform: translate(-50%, -50%) rotate(45deg)
}

.preloader-gear-inner>div div:nth-child(4) {
  transform: translate(-50%, -50%) rotate(90deg)
}

.preloader-gear-inner>div div:nth-child(5) {
  transform: translate(-50%, -50%) rotate(135deg)
}

.preloader-gear {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}

.preloader-gear-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}

/* Preloader: Pulse */
.preloader-pulse-inner div {
  position: absolute;
  width: 30px;
  background-color: #fff;
}

.preloader-pulse-inner div:nth-child(1) {
  left: 35px;
  animation: preloader-pulse-inner-1 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  animation-delay: -0.2s
}

.preloader-pulse-inner div:nth-child(2) {
  left: 85px;
  animation: preloader-pulse-inner-2 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  animation-delay: -0.1s
}

.preloader-pulse-inner div:nth-child(3) {
  left: 135px;
  animation: preloader-pulse-inner-3 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  animation-delay: undefineds
}

.preloader-pulse {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}

.preloader-pulse-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}

/* Preloader: Squares */
.preloader-squares-inner div {
  position: absolute;
  width: 80px;
  height: 80px;
  top: 13.333333333333336px;
  left: 13.333333333333336px;
  background: #fff;
  animation: preloader-squares-inner 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  animation-delay: -0.3s;
}

.preloader-squares-inner div:nth-child(2) {
  top: 13.333333333333336px;
  left: 106.66666666666666px;
  animation-delay: -0.2s;
}

.preloader-squares-inner div:nth-child(3) {
  top: 106.66666666666666px;
  left: 13.333333333333336px;
  animation-delay: 0s;
}

.preloader-squares-inner div:nth-child(4) {
  top: 106.66666666666666px;
  left: 106.66666666666666px;
  animation-delay: -0.1s;
}

.preloader-squares {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}

.preloader-squares-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}

/* Responsive */
@media (max-width: 1199px) and (min-width: 991px) {

   /* about style 17 */
  .sigma_about-skill.style-17:before{
    width: calc(100% - 74%);
  }

  /* about style 21 */
  .sigma_about.style-21 .sigma_about-image-2{
  bottom: 88px;
  }

  .sigma_portfolio.style-3 .sigma_portfolio-content {
    max-width: 350px;
  }

  .col-lg-5 .sigma_post.sigma_post-list h5 {
    font-size: 20px;
  }

  .col-lg-5 .sigma_post.sigma_post-list .sigma_post-thumb {
    margin-right: 20px;
    width: 180px;
  }

  /* infobox style 22 */
  .sigma_info-dark-space.style-22{
    height: 449px;
  }

  /* infobox style 26 */

  .sigma_info.style-26 + .sigma_info.style-26{
    padding-left: 30px;
  }

  /* post details */

  .sigma_post-details .detail-menu-list .menu a{
    font-size: 16px;
  }

  /* contact style 6 */
  .sigma_contact-image.style-6{
    width: calc(100% - 540px);
  }

  .sigma_contact-block.style-3{
    flex-wrap: wrap;
  }
}

@media (max-width: 1199px) {
  .widget.widget-follow .sigma_btn{
    padding: 17px 20px;
  }

  .sigma_about-sec.style-20:before{
    width: 100%;
  }
     /* portfolio style 15 */
  .sigma_portfolio-wrapper.style-15 .sigma_custom-arrows{
  display: none;
}

/* info box style 24 */

.sigma_info.style-24{
  display: block;
}
.sigma_info.style-24 .sigma_info-title .sigma_info-icon{
  margin: 0 0 20px;
}

}


@media (max-width: 991px) {

  /* utility classes */
  h1 {
    font-size: 40px;
  }

  .mb-lg-30 {
    margin-bottom: 30px;
  }

  .col-lg-reverse {
    flex-direction: column-reverse;
  }

  body {
    font-size: 14px;
  }

  .section-title {
    margin-bottom: 30px;
  }

  .section-title.centered {
    margin: 0 auto 30px;
  }

  /* header */
  .sigma_header .container-fluid{
    padding-left: 15px;
    padding-right: 15px;
  }

  .sigma_header .aside-toggle {
    display: flex;
  }

  .sigma_header .navbar-nav,
  .sigma_header-bottom,
  .sigma_header .aside-toggle.desktop-toggler {
    display: none;
  }

  .sigma_aside-overlay.aside-trigger,
  .sigma_aside:not(.sigma_aside-desktop) {
    display: block;
  }

  .sigma_aside-overlay.aside-trigger-right,
  .sigma_aside.sigma_aside-desktop {
    display: none;
  }

  .aside-open .sigma_aside-desktop:not(.sigma_aside)+.sigma_aside-overlay,
  .aside-right-open .sigma_aside-desktop+.sigma_aside-overlay {
    opacity: 0;
    visibility: hidden;
  }

  .aside-open .sigma_aside-desktop:not(.sigma_aside)+.sigma_aside-overlay.style-7,
  .aside-right-open .sigma_aside-desktop+.sigma_aside-overlay.style-7 {
    opacity: 1;
    visibility: visible;
  }

  .sigma_banner .title,
  .sigma_banner.style-8 .title {
    font-size: 42px;
  }

  /* banner style 3 */
  .sigma_banner.style-3 .sigma_banner-image,
  .sigma_banner-slider-5.slick-slider {
    width: 100%;
  }

  .sigma_banner.style-3.light-overlay:before,
  .sigma_banner.style-4.light-overlay:before {
    opacity: 0.4;
  }

  /* banner style 4 */
  .sigma_banner.style-4 .sigma_clients {
    border-color: #fff;
  }

  .banner-image-slider {
    bottom: 0;
  }

  .sigma_banner-slider-4 .slick-prev,
  .sigma_banner-slider-4 .slick-next {
    bottom: 6%;
  }

  /* banner style 6 */
  .sigma_banner-slider-3 {
    position: relative;
    top: 0;
    margin-top: 50px;
  }

  /* About style 3 */
  .sigma_about.style-3 .sigma_about-image-2 {
    position: relative;
    bottom: 0;
    text-align: right;
    margin-top: -125px;
  }

  .sigma_about.style-3 .sigma_video-btn {
    left: 60px;
    bottom: 60px;
  }

  /* About style 3 */
  .sigma_about.style-4 .sigma_about-image-1 img {
    width: 100%;
  }

  /* About style 12 */

  .sigma_about.style-12 .sigma_about-image-1{
    margin: 40px 0 50px;
  }

  /* About style 14 */
  .sigma_about.style-14 .sigma_about-image-1:before{
    content: none;
  }
  .sigma_about.style-14 .sigma_about-image-1{
    right: 0;
    margin-bottom: 50px;
  }

  /* About style 15 */
  .sigma_about-sec.style-15:before{
    content: none;
  }

  /* about style 17 */
  .sigma_about-skill.style-17:before{
    content: none;
  }

  /* about style 21 */
  .sigma_about.style-21{
    margin-top: 190px;
  }
  .sigma_about.style-21 .sigma_about-image-2{
    bottom: -145px;
  }

  /* Portfolio Style 2 */
  .sigma_portfolio.style-2 .sigma_portfolio-thumb.has-max-width {
    max-width: 100%;
    margin: 0;
  }

  /* Portfolio Style 3 */
  .sigma_portfolio-slider .slick-dots {
    position: relative;
    left: 0;
    top: 0;
    transform: none;
    flex-direction: row;
    margin-top: 20px;
  }

  .sigma_portfolio-slider .slick-dots li+li {
    margin-left: 10px;
  }

  /* Team Style 10 */
  .sigma_team.style-10 {
    margin-bottom: 50px;
  }

  /* Accordion Style 1 */
  .sigma_accordion.has-margin {
    margin-top: 25px;
  }

  /* Accordion Style 5 */
  .sigma_accordion.style-5 .accordion {
    margin-top: 30px;
  }

  /* info box style 13 */
  .sigma_info-image.has-width {
    width: auto;
  }

  .sigma_info-image.style-16{

    margin: 0 0 50px;

  }

  /* infobox style 22 */
  .sigma_info-dark-space.style-22{
    height: 456px;
  }

  /* infobox style 25 */
  .sigma_info-wrapper.style-25:after{
    content: none;
  }

  /* sigma_form */
  .sigma_form.style-3 {
    padding: 0 30px;
  }

  /* sigma_contact-map*/
  .sigma_contact-map.has-full-height iframe {
    height: 600px;
  }

  /* Cta style 3 */
  .sigma_cta.style-3 {
    border: none;
  }

  /* Cta style 5 */
  .sigma_cta.style-5 .sigma_cta-image,
  .sigma_cta.style-10 .sigma_cta-image {
    height: 400px;
  }

  .sigma_about.style-4 .sigma_video-btn {
    transform: translate(50%, -50%);
    right: 50%;
  }

  /* infobox style 18 */
  .sigma_info.style-18,
  .sigma_info.style-26{
  flex: 0 0 100%;
}

.sigma_info.style-18 + .sigma_info.style-18,
.sigma_info.style-26 + .sigma_info.style-26{
  padding-left: 0;
  border-left: 0;
  border-top: 2px solid rgba(255, 255, 255, 0.3);
  padding-top: 20px;
  margin-top: 20px;
}

/* infobox style 26 */

.sigma_info.style-26 + .sigma_info.style-26{
  border-top-color: var(--thm-border);
}
.sigma_info-wrapper.style-26{
  padding-left: 30px;
  padding-right: 30px;
}


  .pagination {
    margin-bottom: 40px;
  }

  /* blog style 13 */
  .sigma_post.style-13 .sigma_post-date{
    top: 10px;
  }

  /* Instagram */
  .sigma_instagram-content{
    padding: 40px 0;
  }

  .sigma_instagram.style-4 a,
  .sigma_instagram.style-2 a{
    flex: 0 0 33.33%;
  }

  /* contact */
  .sigma_contact-with-bg:after{
  content: none;
}
/* contact style 5 */
.sigma_form.style-5 .sigma_contact-map{
  margin-top: 40px;
  height: 400px;
}

/* contact style 6 */

.sigma_form.style-6{
   padding: 60px;
}

}

@media (min-width: 991px) {
  .sigma_about.style-2 .sigma_about-image-1 {
    transform: translateX(-120px);
  }
  .sigma_cta.style-13 form input.topic-field{
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.sigma_cta.style-13 form input.location-field{
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
}

@media (max-width: 767px) {

  /* utility classes */
  h4 {
    font-size: 22px;
  }

  .section-title .title{
    font-size: 40px;
  }

  .col-md-reverse {
    flex-direction: column-reverse;
  }

  .sigma_responsive-table thead {
      display: none;
  }

  .sigma_responsive-table td.remove,
  .sigma_responsive-table td {
      position: relative;
      display: block;
      width: 100%;
      text-align: right;
      padding: 10px;
      border-bottom: 0;
  }

  .sigma_responsive-table tr.total td::before {
      display: none;
  }

  .sigma_responsive-table tr.total td:first-child {
      border-top: 0;
      border-right: 0;
  }

  .sigma_responsive-table tr.total td:last-child {
      border-left: 0;
      border-top: 0;
  }

  .sigma_responsive-table tr.total td:nth-child(2) {
      display: none;
  }

  .sigma_responsive-table tr.total td {
      width: auto;
      text-align: left;
  }

  .sigma_responsive-table tr.total {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
  }

  .sigma_responsive-table td::before {
      content: attr(data-title) ": ";
      font-weight: 700;
      float: left;
  }

  .sigma_responsive-table td.remove::before {
      display: none;
  }

  .sigma_responsive-table tr {
      border-bottom: 1px solid #F0F2F3;
  }

  .sigma_responsive-table .cart-product-wrapper {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
      padding: 0;
  }

  .sigma_responsive-table .cart-product-wrapper img {
      margin-right: 0;
      margin-left: 10px;
  }

  /* Subheader */
  .sigma_subheader {
    padding: 100px 0;
  }

  .sigma_subheader .sigma_subheader-inner h1 {
    font-size: 48px;
    margin-bottom: 10px;
  }

  .sigma_subheader .breadcrumb li {
    font-size: 14px;
  }

  .sigma_subheader.style-4 .sigma_subheader-shape svg,
  .sigma_subheader.style-4 .sigma_subheader-shape {
    height: 85px;
  }

  .sigma_subheader.style-6 .container {
    flex-direction: column;
    align-items: flex-start;
  }

  /* Banners */
  .sigma_banner .banner-slider-inner,
  .sigma_banner.style-5 .banner-slider-inner {
    padding: 100px 0;
  }

  .sigma_header-absolute .sigma_banner.style-10 .banner-slider-inner,
  .sigma_header-absolute .sigma_banner.style-8 .banner-slider-inner{
    padding: 150px 0 170px;
  }
  .sigma_banner.style-8 .banner-slider-inner{
    padding: 100px 0 200px;
  }

  .sigma_header-absolute+.sigma_banner .banner-slider-inner {
    padding: 200px 0 130px;
  }

  .sigma_banner.style-2 .sigma_banner-slider-2 .slick-dots {
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  .sigma_header-absolute+.sigma_banner.style-5 .banner-slider-inner {
    padding: 200px 0 100px;
  }

  .sigma_banner.style-7 .title {
    margin: 0;
    font-size: 58px;
  }

  .sigma_banner.style-7 .banner-slider-inner {
    padding: 80px 0 90px;
  }

  .sigma_banner.style-10 .title{
    font-size: 38px;
  }

  /* About style 3 */
  .sigma_about.style-3 .sigma_about-exp {
    top: 34px;
    right: -36px;
  }

  /* About style 9 */

  .sigma_about.style-9 .sigma_about-image-1:before,
  .sigma_about.style-9 .sigma_media{
    display: none;
  }

  /* Blog Post */
  .sigma_post h5 {
    font-size: 22px;
  }

  .sigma_post.post-format-video .sigma_video-btn {
    width: 80px;
    height: 80px;
  }

  .sigma_post.sigma_post-list {
    display: block;
  }

  .sigma_post.sigma_post-list .sigma_post-thumb {
    margin-right: 0;
    margin-bottom: 30px;
  }

  .sigma_post-details.style-4 .widget-sigma-portfolio-details{
    position: relative;
    top: auto;
    bottom: 0;
    transform: none;
    padding: 30px;
  }
  .sigma_post-details.style-5 .comments-list .comment-item:nth-child(2),
  .sigma_post-details.style-6 .comments-list .comment-item:last-child{
    margin-left: 0;
  }
  .sigma_post-details.style-5 .comment-form{
    padding: 20px;
  }

  /* post details */

  .sigma_post-details .detail-menu-list .menu{
    border-right: 0;
    border-bottom: 1px solid var(--thm-border);
  }



  /* Portfolio Style 3 */
  .sigma_portfolio.style-3 {
    display: block;
  }

  .sigma_portfolio.style-3 .sigma_portfolio-content {
    margin-left: 0;
    margin-top: 40px;
    max-width: 100%;
  }

  /* Team Style 3 */
  .sigma_team.style-3 {
    display: block;
  }

  .sigma_team.style-3 .sigma_team-thumb {
    margin-right: 0;
  }

   /* Service Style 10 */
  .sigma_service-has-image:before{
    content: none;
  }

  .sigma_team.style-3 .sigma_team-body {
    padding: 30px;
  }

  /* Testimonial Style 6 */
  .sigma_testimonial.style-6 {
    display: block;
  }

  .sigma_testimonial.style-6 .sigma_testimonial-body {
    margin-left: 0;
    margin-top: 50px;
  }

  /* Testimonial Style 7 */
  .sigma_testimonial.style-7 .sigma_testimonial-body {
    padding: 30px 0;
  }

  /* Testimonial Style 12 */
  .sigma_testimonial.style-12 .sigma_testimonial-category:before{
   content: none;
  }
  .sigma_testimonial.style-12 .sigma_testimonial-category{
    display: block;
    padding-left: 0;
    margin-left: 0;
  }

  /* sigma_form */
  .sigma_form.style-4 {
    margin: 0 20px;
    left: 0;
  }
  /* contact style 5 */

  .sigma_form.style-5{
    padding: 30px;
  }

  /* Clients style 2 */
  .sigma_clients.style-2{
    border-right: 0;
    border-bottom: 1px solid var(--thm-border);
    padding: 20px 0;
  }

  /* footer style 2 */
  .sigma_footer .sigma_footer-top {
    flex-direction: column;
  }

  .sigma_footer .sigma_footer-top>div:not(:first-of-type):not(:last-of-type) {
    margin: 25px 0;
  }
}

@media (max-width: 576px) {

  /* utility classes */
  h1 {
    font-size: 30px;
  }
  h2{
    font-size: 28px;
  }

  .section-title.flex-title{
    display: block;
  }

  .search-form-wrapper form {
    width: 100%;
  }

  /* Buttons & inputs */
  button,
  input[type=reset],
  input[type="button"],
  input[type="submit"],
  .sigma_btn {
    padding: 12px 18px;
  }

  input[type="number"],
  input[type="date"],
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="tel"],
  input[type="url"],
  input[type="search"],
  select,
  textarea,
  .form-control {
    height: 50px;
    padding: 14px 20px;
  }

  /* Subheader */
  .sigma_subheader.style-10 .sigma_subheader-inner h1 {
    font-size: 32px;
  }

  .sigma_subheader.style-10 .sigma_subheader-inner h1::before,
  .sigma_subheader.style-10 .sigma_subheader-inner h1::after {
    height: 10px;
  }

  /* banner style 6 */
  .sigma_banner-slider-3 .slick-dots {
    position: relative;
    flex-direction: row;
    left: 50%;
    transform: translateX(-50%);
  }

  .sigma_banner-slider-3 .slick-dots li+li {
    margin-top: 0;
    margin-left: 10px;
  }

  /* About style 3 */
  .sigma_about.style-3 .sigma_general-list ul,
  .sigma_about.style-4 .sigma_about-list {
    column-count: 1;
  }

  /* About style 9 */
  .sigma_about.style-9 .video-box{
    margin-left: 0;
    margin-top: 20px;
  }

  /* About style 18 */
  .sigma_about.style-18 .sigma_media{
    align-items: flex-start;
  }
  .sigma_about.style-18 .sigma_media + .sigma_media{
    margin-left: 0;
  }

  /* About style 19 */
  .sigma_about.style-19 .sigma_about-content .important-text{
    padding: 80px 25px 25px;
  }

  /* about style 21 */
  .sigma_about.style-21{
    margin-top: 50px;
  }


  /* Blog Post */
  .sigma_post.post-format-quote blockquote {
    padding: 60px 20px;
  }

  .sigma_post.post-format-video .sigma_video-btn {
    width: 60px;
    height: 60px;
  }

  /* Isotope Filter */
  .sigma_isotope-filter-items {
    display: block;
  }

  .sigma_isotope-filter-items .sigma_isotope-trigger+.sigma_isotope-trigger {
    margin-left: 0;
    margin-top: 20px;
  }

  /* Team Style 5 */
  .sigma_team.style-5 {
    display: block;
  }

  .sigma_team.style-5 .sigma_team-thumb {
    margin-right: 0;
    margin-bottom: 30px;
  }

  .sigma_team-slider .slick-list{
    overflow: visible;
  }

   /* Team Style 17 */

   .sigma_team.style-17 .sigma_team-body {
    border-bottom: 1px solid var(--thm-border);
    border-right: 0;
  }

  /* Tab items style */
  .sigma_tab-item .nav-tabs {
    flex-direction: column;
  }

  .sigma_tab-item .nav-tabs li+li {
    margin-left: 0;
  }

  /* Tab items style 3 */
  .sigma_tab-item.style-3 .nav-tabs .nav-item:first-of-type .nav-link,
  .sigma_tab-item.style-3 .nav-tabs .nav-item:nth-child(2) .nav-link,
  .sigma_tab-item.style-3 .nav-tabs .nav-item:last-of-type .nav-link {
    border-radius: 6px;
  }

  /* Tab items style 7 & 13 */
  .sigma_tab-item.style-7 .nav-tabs .nav-link,
  .sigma_tab-item.style-13 .nav-tabs .nav-link {
    width: 100%;
  }

  /* Tab items style 10 */
  .sigma_tab-item.style-10 .nav-tabs:before {
    transform: translateY(-50%) rotate(90deg);
  }

  .sigma_tab-item.style-10 .nav-tabs .nav-item {
    margin-bottom: 30px;
  }

  /* sigma_form */
  .sigma_form.has-shadow {
    padding: 25px;
  }

  .sigma_form.style-2 .sigma_buttons button {
    display: block;
    width: 100%;
  }

  .sigma_form.style-2 .sigma_buttons button+button {
    margin-left: 0;
    margin-top: 15px;
  }

  .sigma_form.style-7{
  padding: 30px;
}
.sigma_form.style-7 .payment-card-wrapper .card-image{
  margin-left: 0;
  margin-top: 30px;
}

  /* Cta */
  .sigma_cta-buttons {
    flex-direction: column;
    align-items: flex-start;
  }

  .sigma_cta-buttons a+a,
  .sigma_cta-buttons button+button {
    margin-left: 0;
    margin-top: 20px;
  }

  /* Cta style 2 */
  .sigma_cta.style-2 {
    padding: 50px 30px 60px;
  }

  /* Cta style 5 */
  .sigma_cta.style-5 .sigma_cta-content {
    padding: 60px 30px 70px
  }

   /* Cta style 9 */
  .sigma_cta.style-9{
    padding: 20px;
  }

  /* Cta style 10 */

  .sigma_cta.style-10 .sigma_cta-content{
    padding: 70px 30px;
  }
  .sigma_cta.style-10 .sigma_cta-image{
    display: none;
  }

  /* Cta style 13, 14 */
  .sigma_cta.style-13,
  .sigma_cta.style-14{
    padding-left: 20px;
    padding-right: 20px;
  }

   /* Service Style 10 */

   .sigma_service-wrapper.style-10{
    padding: 70px 40px 20px;
   }
   .sigma_service.style-10{
    display: block;
   }
   .sigma_service.style-10 .sigma_service-thumb{
    text-align: left;
    margin-bottom: 20px;
    margin-right: 0;
   }

   /* Service Style 12 */
   .sigma_service.style-12{
    padding: 50px 20px;

   }

   /* Service Style 18 */
   .sigma_service-wrapper.style-18{
    padding: 30px 30px 0;
   }

  /* infobox style 17  & 18*/

  .sigma_info.style-17{
    display: block;
  }
  .sigma_info.style-17 .sigma_info-title .sigma_info-icon,
  .sigma_info.style-18 .sigma_info-title .sigma_info-icon{
    margin-right: 0;
    margin-bottom: 20px;
  }
  .sigma_info.style-18{
  flex: 0 0 100%;
  display: block;
}

  /* Footer style 3 */
  .sigma_footer.style-3 .sigma_footer-widget {
    text-align: center;
  }

  .sigma_footer.style-3 .sigma_footer-links {
    align-items: center;
  }

  /* Pricing */
  .sigma_pricing.style-2 .sigma_general-list ul {
    padding: 80px 20px 20px;
  }

  .sigma_pricing.style-2 .sigma_pricing-top {
    left: 20px;
    width: calc(100% - 40px);
  }

  /* Counter */

  .sigma_counter.style-2{
    border-left: 0;
    border-top: 2px solid rgba(255, 255, 255, 0.4);
  }

  /* portfolio */
  .sigma_portfolio.style-9 .sigma_portfolio-content{
    width: calc(100% - 20px);
  }

  /* Testimonial */
  .sigma_testimonial.style-11{
  padding: 30px 20px;
}

/* Testimonial Style 14 */
  .sigma_testimonial.style-14 {
    display: block;
  }

  .sigma_testimonial.style-14 .sigma_testimonial-body {
    margin-left: 0;
    margin-top: 30px;
  }

  /* Popup */
  .sigma_newsletter-popup-modal.style-3 form {
    display: block;
  }

  .sigma_newsletter-popup-modal.style-3 form .form-group {
    margin: 0;
  }

  .sigma_newsletter-popup-modal.style-3 form button {
    display: block;
    width: 100%;
    margin-top: 15px;
  }

  .sigma_popup {
    max-width: 100%;
    width: calc(100% - 40px);
  }

  /* Post details */
  .sigma_post-details-meta{
    display: block;
  }
  .sigma_post-details-meta span{
    display: block;
    margin: 0 0 10px;
  }
  .sigma_post-details-meta-item + .sigma_post-details-meta-item{
    margin-top: 20px;
  }
  .sigma_author-about{
    display: block;
    text-align: center;
  }
  .sigma_author-about img{
    margin: 0 auto;
  }
  .sigma_author-about-content{
    margin: 20px 0 0;
  }
  .comments-list .comment-item img{
    width: 50px;
  }
  .comments-list .comment-item .btn-link{
    position: relative;
    top: auto;
    margin-top: 15px;
  }
  .sigma_post-details.style-2 .sigma_post-details-inner{
    border: 0;
    padding: 0;
  }

  .sigma_post-details.style-6 .comments-list .comment-item .date{
    position: relative;
  }
  .comments-list .comment-item{
    display: block;
  }
  .comments-list .comment-item img{
    margin-right: 0;
    margin-bottom: 20px;
  }

  /* contact style 6 */

.sigma_form.style-6{
   padding: 30px;
}

  /* Instagram */
  .sigma_instagram.style-3 a{
    flex: 0 0 33.33%;
  }

}
.sigma_product {
  position: relative;
  margin-bottom: 30px;
}

.sigma_product .sigma_product-thumb {
  position: relative;
  overflow: hidden;
}

.sigma_product .sigma_product-thumb.thumb-sm {
  max-width: 200px;
  margin: 0 auto 20px;
}

.sigma_product .sigma_product-thumb img {
  transition: .3s;
  width: 100%;
}

.sigma_product .sigma_product-thumb:hover img {
  transform: scale(1.1) rotate(3deg);
}

.sigma_product .sigma_product-body {
  position: relative;
  padding: 15px 0 0;
}

.sigma_product .sigma_product-body h5 {
  margin: 0 0 10px;
  font-weight: 400;
  word-break: break-word;
}

.sigma_product .sigma_product-body .sigma_product-title a {
  color: var(--thm-secondary);
  font-weight: 500;
}

.sigma_product .sigma_product-body .sigma_product-title a:hover {
  color: var(--thm-base-hover);
}

.sigma_product .sigma_badge-featured {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 3;
  background-color: #ffa80a;
  font-weight: 600;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.7;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.sigma_product .sigma_badge-featured i {
  display: inline-block;
}

.sigma_product .sigma_badge-sale {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 3;
  background-color: var(--thm-secondary);
  font-weight: 600;
  color: #fff;
  font-weight: 700;
  font-size: 11px;
  line-height: 1.7;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.sigma_product .sigma_rating-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.sigma_product .sigma_rating-wrapper span {
  font-weight: 600;
}

.sigma_product-price {
  display: flex;
  align-items: center;
}

.sigma_product-price span {
  display: block;
}

.sigma_product-price span:first-child {
  line-height: 1.2;
  color: var(--thm-base);
  font-weight: 700;
}

.sigma_product-price span+span {
  color: var(--thm-b-text);
  font-weight: 400;
  line-height: 1;
  margin-left: 10px;
  text-decoration: line-through;
  color: #d2d2d2;
}

.sigma_product-stock {
  font-weight: 600;
}

.sigma_product-stock.instock {
  color: #58da58;
}

.sigma_product-stock.outofstock {
  color: #f54978;
}

.sigma_product .sigma_rating {
  margin-bottom: 0;
}

.sigma_product-controls {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 20px;
  right: 20px;
  opacity: 0;
  transition: .3s;
  visibility: hidden;
}

.sigma_product:hover .sigma_product-controls {
  opacity: 1;
  visibility: visible;
}

.sigma_product-controls a {
  width: 40px;
  height: 40px;
  background-color: #fff;
  color: var(--thm-b-text);
  border-radius: 50%;
}

.sigma_product-controls a+a {
  margin-top: 10px;
}

.sigma_product-controls a i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.sigma_product-controls a:hover {
  background-color: var(--thm-base);
  color: #fff;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, .03);
}

.sigma_product-footer {
  display: flex;
  align-items: center;
}

/* Style 2 */
.sigma_product.style-2 .sigma_product-body p {
  margin-bottom: 15px;
}

.sigma_product.style-2 .sigma_product-body .sigma_product-price {
  position: absolute;
  top: 17px;
  right: 0;
}

.sigma_product.style-2 .sigma_product-body h5 {
  padding-right: 70px;
}

/* Style 3 */
.sigma_product.style-3 {
  position: relative;
  padding: 20px;
  background-color: #fff;
  border: 1px solid var(--thm-border);
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, .03);
  border-radius: 8px;
  margin-bottom: 30px;
  transition: .3s;
}

.sigma_product.style-3 .sigma_rating {
  margin: 15px 0;
}

.sigma_product.style-3 .sigma_product-controls a {
  border-radius: 8px;
}

.sigma_product.style-4 .sigma_product-thumb:hover img,
.sigma_product.style-3 .sigma_product-thumb:hover img {
  transform: scale(1) rotate(0);
}

.sigma_product.style-3 .sigma_product-gallery {
  display: flex;
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 8px 5px;
  border-radius: 8px;
  width: 148px;
  transition: .3s;
  opacity: 0;
  visibility: hidden;
}

.sigma_product.style-3:hover .sigma_product-gallery {
  bottom: 20px;
  opacity: 1;
  visibility: visible;
}

.sigma_product.style-3 .sigma_product-gallery img {
  margin: 0 3px;
}

/* Style 4 */
.sigma_product.style-5,
.sigma_product.style-4 {
  text-align: center;
}

.sigma_product.style-5 .sigma_product-price,
.sigma_product.style-5 .sigma_rating,
.sigma_product.style-4 .sigma_product-price,
.sigma_product.style-4 .sigma_rating {
  justify-content: center;
}

.sigma_product.style-5 .sigma_rating,
.sigma_product.style-4 .sigma_rating {
  margin: 15px 0;
}

.sigma_product.style-4 .sigma_product-body {
  background-color: #FCF7FF;
  border-radius: 8px;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, .03);
  border: 1px solid var(--thm-border);
  padding: 80px 20px 20px;
  text-align: center;
  position: relative;
}

.sigma_product.style-4 .sigma_product-thumb {
  display: block;
  text-align: center;
  margin-bottom: -60px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  z-index: 3;
}

.sigma_product.style-4 .sigma_product-thumb:hover {
  transform: translateY(-5px);
}

.sigma_product.style-4 .sigma_product-thumb img {
  border-radius: 50%;
}

/* Style 5 */
.sigma_product.style-5 .sigma_product-thumb a{
  position: absolute;
  bottom: 10px;
  left: 50%;
  display: inline-block;
  margin: 10px 0;
  transform: translateX(-50%);
  text-align: center;
  background-color: rgba(0,0,0,.1);
  padding: 10px 20px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  transition: .3s;
  opacity: 0;
  visibility: hidden;
  min-width: 150px;
}
.sigma_product.style-5 .sigma_product-thumb a:hover{
  background-color: var(--thm-base);
}
.sigma_product.style-5:hover  .sigma_product-thumb a{
  opacity: 1;
  visibility: visible;
}
.sigma_product.style-5 .sigma_product-body p{
  font-size: 14px;
}
.sigma_product.style-5 .sigma_product-footer .sigma_btn{
  font-size: 12px;
}
.sigma_product.style-5 .sigma_product-footer{
  justify-content: center;
}
.sigma_product.style-5 .sigma_product-footer .sigma_btn:not(.light){
  margin: 0 5px;
}
.sigma_product.style-5 .sigma_product-footer .sigma_btn.light{
  padding: 0;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* style 6 */
.sigma_product.style-6{
  text-align: center;
  background-color: #fff;
  box-shadow: 0 0 40px 5px rgba(0,0,0,.03);
  border: 1px solid var(--thm-border);
}
.sigma_product.style-6 .sigma_product-price{
  justify-content: center;
  margin: 20px 0;
  font-size: 13px;
}
.sigma_product.style-6 .sigma_rating{
  justify-content: center;
  margin: 15px 0;
}
.sigma_product.style-6 .sigma_product-body .sigma_btn{
  width: 100%;
  margin: 0;
  border-radius: 0;
}
.sigma_product.style-6 .sigma_product-body .sigma_btn::before{
  border-radius: 0;
}

/* Style 7 */
.sigma_product.style-7{
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 40px 5px rgba(0,0,0,.03);
  border: 1px solid var(--thm-border);
}

.sigma_product.style-7 .sigma_product-thumb{
  width: 125px;
  margin-right: 15px;
}
.sigma_product.style-7 .sigma_product-body{
  padding: 0;
  flex: 1;
}
.sigma_product.style-7 .sigma_product-body p{
  font-size: 14px;
}
.sigma_product.style-7 .sigma_rating{
  margin: 0 0 15px;
}
.sigma_product.style-7 .sigma_product-footer{
  justify-content: space-between;
}
.sigma_product.style-7 .sigma_product-footer .sigma_product-price{
  flex-direction: column;
}
.sigma_product.style-7 .sigma_product-footer .sigma_product-price span + span{
  margin-left: 0;
  margin-top: 5px;
}

/* Other */
.sigma_shop-global {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--thm-border);
}

.sigma_shop-global p {
  color: var(--thm-secondary);
  font-weight: 500;
  margin-bottom: 0;
}

.sigma_shop-global a {
  color: var(--thm-secondary);
}

.sigma_shop-global a:hover {
  color: var(--thm-base-hover);
}

.sigma_category-strip {
  background-color: #fff;
  padding: 20px 0 0;
  border-bottom: 1px solid var(--thm-border);
}

.sigma_category-strip-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.sigma_category-strip .sigma_category-item {
  text-align: center;
  cursor: pointer;
  flex: 0 calc(12.5% - 10px);
  margin: 0 5px 20px;
}

.sigma_category-strip .sigma_category-item.active .sigma_category-thumb {
  opacity: 1;
}

.sigma_category-strip .sigma_category-item:hover .sigma_category-thumb,
.sigma_category-strip .sigma_category-item.active .sigma_category-thumb {
  background-color: var(--thm-base);
}

.sigma_category-strip .sigma_category-item .sigma_category-thumb {
  width: 100px;
  border-radius: 50%;
  margin: 0 auto;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  margin-bottom: 10px;
  border: 7px solid rgba(var(--thm-base-rgb), .2);
  opacity: .4;
}

.sigma_category-strip .sigma_category-item .sigma_category-thumb img {
  border-radius: 50%;
}

.sigma_category-strip .sigma_category-item.active h6 {
  color: var(--thm-base);
}

.sigma_category-strip .sigma_category-item h6 {
  font-weight: 500;
  margin: 0 auto;
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single,
.irs--flat .irs-bar,
.irs--flat .irs-handle>i:first-child {
  background-color: var(--thm-base);
}

.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
  border-top-color: var(--thm-base);
}

.irs--flat .irs-handle.state_hover>i:first-child,
.irs--flat .irs-handle:hover>i:first-child {
  background-color: var(--thm-base);
}

/* 6.3.3 Products Quick View & Details */
.sigma_post-share .sigma_sm,
.sigma_product-single-content .sigma_sm {
  display: flex;
  align-items: center;
}

.sigma_post-share .sigma_sm li+li,
.sigma_product-single-content .sigma_sm li+li {
  margin-left: 15px;
}

.sigma_post-share .sigma_sm li a,
.sigma_product-single-content .sigma_sm li a {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #FCF7FF;
  color: var(--thm-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
}

.sigma_post-share .sigma_sm li a:hover,
.sigma_product-single-content .sigma_sm li a:hover {
  background-color: var(--thm-base);
  color: #fff;
}

.sigma_quick-view-modal .sigma_product-single-content {
  padding: 0 20px;
}

.sigma_quick-view-modal .sigma_close {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 3;
}

.sigma_product-single-content hr {
  margin: 15px 0;
}

.sigma_product-single-content .sigma_rating-wrapper {
  margin-bottom: 0;
}

.sigma_product-single-content .sigma_rating {
  margin: 0;
}

.sigma_product-single-content .sigma_product-excerpt {
  margin-bottom: 20px;
}

.sigma_product-variation-wrapper {
  display: flex;
  align-items: flex-start;
}

.sigma_product-variation-wrapper .form-group+.form-group {
  margin-left: 10px;
}

.sigma_product-single-content .sigma_product-price {
  margin-bottom: 20px;
  justify-content: flex-start;
}

.sigma_product-single-content .sigma_product-price span:first-child {
  font-size: 32px;
}

.sigma_product-single-content .sigma_product-price span {
  display: inline-block;
}

.sigma_product-single-content .sigma_product-price span+span {
  margin-left: 10px;
}

.sigma_product-single-content .sigma_post-single-meta {
  border: 0;
  padding: 0;
  margin: 20px 0 0;
}

.sigma_product-single-content .sigma_post-single-meta+h6 {
  margin: 5px;
}

.sigma_product-buttons {
  display: flex;
  align-items: center;
}

.sigma_product-buttons a+a {
  margin-left: 10px;
}

.sigma_product-atc-form .qty-outter {
  display: flex;
  align-items: center;
}

.sigma_product-atc-form .qty-outter .sigma_btn-custom+.qty {
  margin-left: 20px;
}

.sigma_product-atc-form .qty-inner {
  margin-left: 10px;
}

.sigma_product-atc-form .qty-inner h6 {
  margin: 0 10px 0 0;
  font-size: 13px
}

.qty {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  height: 60px;
  padding: 8px 15px;
  border-radius: 0;
  border: 0;
  border-radius: 0;
  width: 100%;
  color: var(--thm-b-text);
  background-color: #fff;
  border: 1px solid var(--thm-border);
  letter-spacing: 0.1px;
  border-radius: 55px;
}

.qty input {
  border: 0;
  outline: none;
  width: 30px;
  text-align: center;
  font-weight: 600;
  border: 0;
  margin: 0 5px;
}

.qty span.qty-subtract {
  border-right: 0;
  cursor: pointer;
}

.qty span.qty-add {
  border-left: 0;
  cursor: pointer;
}

.qty span:hover {
  background-color: var(--thm-base);
  border-color: var(--thm-base);
  color: #fff;
}

.qty span {
  font-size: 13px;
  transition: .3s;
  color: var(--thm-secondary);
  line-height: 1;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--thm-border);
  border-radius: 50%;
}

.sigma_product-radio label {
  cursor: pointer;
  font-weight: 700;
  color: var(--thm-secondary);
  font-style: normal;
}

.sigma_product-radio label input {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}

.sigma_product-radio label span {
  position: relative;
  z-index: 1;
  font-size: 13px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sigma_product-radio label span::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: .3s;
  opacity: 0;
  visibility: hidden;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  z-index: -1;
  background-color: var(--thm-base);
}

.sigma_product-radio label input:checked+span::before {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  visibility: visible;
}

.sigma_product-radio label input:checked+span {
  color: #fff;
}

.sigma_product-additional-info .nav {
  border-bottom: 0;
  justify-content: center;
}

.sigma_product-additional-info .nav-link {
  margin: 0;
  border-radius: 0;
  font-weight: 600;
  color: var(--thm-b-text);
  border-bottom: 2px solid transparent;
}

.sigma_product-additional-info .nav-link.active {
  color: var(--thm-base);
}

.sigma_product-additional-info .nav-item+.nav-item .nav-link {
  margin-left: 10px;
}

.sigma_product-additional-info .nav-item+.nav-item .nav-link:hover {
  color: var(--thm-base);
}

.sigma_product-additional-info .tab-content {
  z-index: 1;
  margin-top: 0;
  padding: 40px;
  background-color: #fff;
  border-radius: 35px;
}

.sigma_product-additional-info .tab-content table {
  margin: 0;
}

.sigma_product-additional-info .sigma_rating-wrapper,
.sigma_product-additional-info .comment-body .sigma_rating {
  margin-bottom: 20px;
}

.sigma_product-additional-info .tab-content .comment-form,
.sigma_product-additional-info .tab-content .comments-list {
  padding: 0;
  border: 0;
  background-color: transparent;
  box-shadow: none;
}

.sigma_product-additional-info .tab-content .comments-list .comment-item {
  padding: 0;
  border: 0;
}

.sigma_product-additional-info .comment-form {
  margin-bottom: 50px;
}

.sigma_product-single-controls {
  margin-bottom: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sigma_product-single-controls .sigma_add-to-favorite {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--thm-border);
  margin: 0 10px 0 0;
}

.sigma_product-single-controls .sigma_add-to-favorite:hover {
  background-color: #FCF7FF;
}

.sigma_product-single-controls .sigma_add-to-favorite i {
  font-size: 21px;
  color: var(--thm-b-text);
}

.sigma_product-single-content .sigma_product-meta p {
  margin: 0;
}

.sigma_product-single-content .sigma_product-meta p+p {
  margin-top: 10px;
}

.sigma_product-single-content .sigma_product-meta p strong {
  color: var(--thm-secondary);
}

.sigma_product-single-content .sigma_product-meta p strong span {
  color: var(--thm-b-text);
  font-weight: 400;
  font-size: 14px;
}

.sigma_product-single-content .sigma_product-meta p a {
  font-size: 14px;
  text-decoration: underline;
  color: var(--thm-b-text);
}

.sigma_product-single-content .sigma_product-meta p a:hover {
  color: var(--thm-base);
}

.sigma_product-meta {
  margin: 20px 0 0;
}

.sigma_product-single-2 .sigma_product-meta {
  margin: 20px 0 30px;
}

.sigma_product-meta li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}

.sigma_product-meta li>span {
  font-weight: 600;
  color: var(--thm-secondary);
  width: 120px;
}

.sigma_product-meta li .sigma_product-meta-item span,
.sigma_product-meta li a {
  color: var(--thm-b-text);
}

.sigma_product-meta li a:hover {
  color: var(--thm-base);
}

.sigma_product-single-thumb,
.sigma_product-single-zoom>img {
  background-color: #fff;
}

.sigma_product-single-thumb .slider {
  margin-bottom: 30px;
}

/* 6.3.4. Cart & Wishlist */
td.remove {
  width: 30px;
}

td.remove button::after,
td.remove button::before {
  display: none;
}

td.remove button {
  padding: 0;
  width: 30px;
  height: 30px;
  background-color: transparent;
  box-shadow: none;
}

td.remove button span {
  background-color: red;
  width: 15px;
}

.sigma_cart-product-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.sigma_cart-product-wrapper img {
  width: 50px;
  margin-right: 15px;
}

.sigma_cart-product-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.sigma_cart-product-wrapper h6 {
  margin-bottom: 0;
  font-size: 18px;
}

.sigma_cart-product-wrapper h6 a {
  color: var(--thm-secondary);
}

.sigma_cart-product-wrapper h6 a:hover {
  color: var(--thm-base);
}

.sigma_cart-product-wrapper p {
  margin-bottom: 0;
  font-size: 13px;
  line-height: 21px;
  color: #848486;
}

/* 6.3.5. Checkout */
.sigma_notice {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 30px;
  border: 2px solid var(--thm-border);
}

.sigma_notice p {
  margin: 0;
  font-weight: 500;
}

.sigma_notice p a:hover {
  text-decoration: underline;
}

.sigma_notice-content {
  margin-bottom: 30px;
  display: none;
}

/* 6.3.6 Products (Recent View) */
.sigma_product-recent {
  position: relative;
  background-color: #fff;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, .03);
  border-radius: 8px;
  transition: .3s;
}

.sigma_product-recent:hover {
  background-color: #FCF7FF;
}

.sigma_product-recent .sigma_product-recent-thumb {
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
}

.sigma_product-recent+.sigma_product-recent {
  margin-top: 10px;
}

.sigma_product-recent img {
  width: 75px;
  margin-right: 10px;
}

.sigma_product-recent-content .sigma_product-price span {
  display: inline-block;
  font-weight: 400;
}

.sigma_product-recent-content .sigma_product-price span+span {
  margin-left: 5px;
}

.sigma_product-recent-content h6 {
  margin-bottom: 5px;
  font-weight: 600;
}

.sigma_product-recent button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
  margin-left: auto;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid var(--thm-border);
  outline: none;
  cursor: pointer;
  font-size: 18px;
  width: 40px;
  height: 40px;
}

@media (max-width: 991px) {
  .sigma_category-strip .sigma_category-item {
    flex: 0 calc(20% - 10px);
  }
}

@media(max-width: 767px) {
  .sigma_quick-view-modal .sigma_product-single-content {
    padding: 20px 0 0;
  }

  .sigma_category-strip .sigma_category-item {
    flex: 0 calc(20% - 10px);
  }
}
@media(max-width: 575px) {
  .sigma_product.style-7{
    display: block;
  }
  .sigma_product.style-7 .sigma_product-thumb{
    width: auto;
    margin-right: 0;
    margin-bottom: 20px;
  }
}
